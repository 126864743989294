import React, { PureComponent } from 'react';
import styled from 'styled-components';
import SummaryRecordsContainer from '../../components/SymptomTracker/SummaryRecordsContainer';
import { SymptomTrackerProvider } from '../../contexts';

const Container = styled.div`
  display: block;
  padding: 17px 0;
`;

class SummaryRecords extends PureComponent {
  render() {
    return (
      <Container>
        <SymptomTrackerProvider>
          <SummaryRecordsContainer />
        </SymptomTrackerProvider>
      </Container>
    );
  }
}

export default SummaryRecords;
