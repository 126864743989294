import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import LoginContainer from '../../components/CMS/LoginContainer';
import { CMSProvider } from '../../contexts';

class Login extends PureComponent {
  componentDidMount() {
    if (Cookies.get('accountId')) {
      this.props.history.push('/cms');
    }
  }

  render() {
    return (
      <Container>
        <BoxHeader>
          <ImageLogoThoracic
            src="/images/TSTRP_logo.png"
            alt="image logo thoracic"
          />
          <ImageLogoIldAssembly
            src="/images/ILD_LOGO.png"
            alt="image logo ild assembly"
          />
        </BoxHeader>
        <CMSProvider>
          <LoginContainer />
        </CMSProvider>
        <BoxFooter>
          Interstitial and occupational lung diseases assembly
          ในนามสมาคมอุรเวชช์แห่งประเทศไทยในพระบรมราชูปถัมภ์
        </BoxFooter>
      </Container>
    );
  }
}

const Container = styled.div`
  max-width: 1280px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 32px 0;
`;
const BoxHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 15px;
`;
const ImageLogoThoracic = styled.img`
  width: 65px;
  margin-right: 20px;
`;
const ImageLogoIldAssembly = styled.img`
  width: 57px;
`;
const BoxFooter = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #39a1ac;
  margin-top: 38px;
`;

export default withRouter(Login);
