import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import styled from 'styled-components';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import th from 'date-fns/locale/th'; // the locale you want
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from '../../Form/Dropdown';
import { TimeJson } from '../mocks/TimeJson';
import { useAppointmentContext } from '../../../contexts';
import AppointmentCompletedModal from './AppointmentCompletedModal';
import FullpageLoading from '../../Modals/FullpageLoading';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(buddhistEra);

registerLocale('th', th);

export default function CreateAppointmentForm() {
  const router = useHistory();
  const [startDate, setStartDate] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { isAppointmentCompleted, AppointmentAction } = useAppointmentContext();
  const [loaded, setLoaded] = useState(true);

  const { watch, setValue, handleSubmit } = useForm({
    mode: 'onChanges',
    reValidateMode: 'onChange',
  });
  const TimeAppointment = watch('timeAppointment') || '';

  const handleChangeOpenDate = e => {
    setIsOpen(!isOpen);
    setStartDate(e);

    const date = dayjs(e).tz('Asia/Bangkok').format('YYYY-MM-DD');
    setValue('dateAppointment', date);
  };
  const handleClickOpenDate = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleClickOptionTime = ({ value }) => {
    setValue('timeAppointment', value);
  };

  const handleKeyUpCharacter = ({ e, maxLength }) => {
    const characterCount = e?.target?.value?.length;
    if (characterCount > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength);
    }

    setValue('appointmentNote', e.target.value?.trim());
  };

  const handleClickSubmitAppointment = data => {
    setLoaded(false);
    AppointmentAction.CreateAppointment(data);
  };

  const handleClickGotoAppointmentList = () => {
    setLoaded(true);
    router.goBack();
  };

  const validateDisabled =
    watch('dateAppointment') === undefined ||
    watch('timeAppointment') === undefined ||
    watch('appointmentNote') === undefined ||
    watch('appointmentNote') === '';

  return (
    <Container>
      <FormWrapper onSubmit={handleSubmit(handleClickSubmitAppointment)}>
        <DatePickerContainer>
          <InputContainer>
            <InputDate
              className="example-custom-input"
              onClick={handleClickOpenDate}
            >
              <Topic isOnTop={startDate !== ''}>วันนัดแพทย์</Topic>
              {watch('dateAppointment')
                ? dayjs(startDate).tz('Asia/Bangkok').format('DD/MM/BBBB')
                : ''}
              <IconDate src="/icons/icon-calendar.svg" />
            </InputDate>
            {isOpen && (
              <ReactDatePicker
                selected={startDate}
                onChange={handleChangeOpenDate}
                inline
                minDate={new Date()}
                locale={'th'}
              />
            )}
          </InputContainer>
          <Dropdown
            classNameTextLabel="time-label"
            label="เวลานัดแพทย์"
            defaultValue={TimeAppointment}
            handleClick={handleClickOptionTime}
            options={TimeJson()}
          />
          <TextareaContainer>
            <span>หมายเหตุ</span>
            <Textarea
              name="otherSymptomsTextarea"
              id="other-symptoms-textarea"
              placeholder="ระบุโรคที่คุณมีนัดหมายกับแพทย์"
              defaultValue={''}
              onKeyUp={e => handleKeyUpCharacter({ e, maxLength: 200 })}
            ></Textarea>
          </TextareaContainer>
        </DatePickerContainer>
        <FooterContainer>
          <ButtonSubmit disabled={validateDisabled}>เสร็จสิ้น</ButtonSubmit>
        </FooterContainer>
      </FormWrapper>
      <AppointmentCompletedModal
        isOpen={isAppointmentCompleted}
        onClick={handleClickGotoAppointmentList}
      />
      <FullpageLoading loaded={loaded} />
    </Container>
  );
}

const Container = styled.div`
  padding: 0 24px;
  width: 100%;
  height: 90%;
  position: fixed;

  @media only screen and (min-width: 540px) {
    display: flex;
    justify-content: center;
  }
`;
const FormWrapper = styled.form`
  display: block;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 34px;
  margin-bottom: 36px;
`;
const InputDate = styled.button`
  padding: 10px 40px 10px 22px;
  background: transparent;
  border: 1px solid #a9b7da;
  border-radius: 12px;
  width: 100%;
  height: 44px;
  max-width: 375px;
  outline: none;
  font-weight: normal;
  font-size: 16px;
  background-color: #ffffff;
  display: flex;

  @media only screen and (min-width: 540px) {
    min-width: 375px;
  }

  :focus {
    border: 1px solid #335ecc;
  }
`;
const IconDate = styled.img`
  position: absolute;
  top: 9px;
  right: 15px;
`;
const Topic = styled.label`
  position: absolute;
  color: #475985;

  ${props =>
    props?.isOnTop &&
    `
      top: -10px;
      left: 19px;
      color: #7183B2;
      font-weight: 300;
      font-size: 12px;
      background: #ffffff;
      z-index: 1;
      width: 65px;
      text-align: center;
    `}
`;
const TextareaContainer = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #7183b2;
    margin-bottom: 4px;
  }
`;
const Textarea = styled.textarea`
  width: 100%;
  min-height: 150px;
  resize: none;
  border-radius: 16px;
  border: 1px solid #a9b7da;
  padding: 12px;
  color: #2b2b2b;
  box-shadow: none;
  font-size: 16px;
  letter-spacing: 0.25px;

  &:focus {
    outline: none;
    border: 1px solid darken(#ddd, 5%);
    box-shadow: none;
  }

  ::placeholder {
    color: #475985;
  }
`;
const FooterContainer = styled.div`
  width: 100%;
  height: 96px;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 8px;
`;
const ButtonSubmit = styled.button`
  width: 200px;
  height: 48px;
  background: #06a3b4;
  border-radius: 24px;
  color: #ffffff;
  letter-spacing: 0.25px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  border: none;
  margin-bottom: 32px;

  ${props => props?.disabled && `opacity: 0.5;`}
`;
const DatePickerContainer = styled.div`
  .time-label {
    color: #475985;
    padding: 0;
  }

  .react-datepicker {
    box-shadow: 0px 4px 20px -1px rgb(113 131 178 / 10%);
    border-radius: 16px;
    border: none;
    position: absolute;
    z-index: 5;
    margin-top: 4px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper {
    z-index: 5;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 4px;
  }

  .react-datepicker__day {
    height: 35px;
    padding-top: 4px;
    font-size: 14px;

    @media only screen and (min-width: 320px) {
      width: 1.99rem;
    }

    @media only screen and (min-width: 360px) {
      width: 2.35rem;
    }

    @media only screen and (min-width: 375px) {
      width: 2.48rem;
    }

    @media only screen and (min-width: 411px) {
      width: 2.8rem;
    }

    @media only screen and (min-width: 414px) {
      width: 2.83rem;
    }

    @media only screen and (min-width: 415px) {
      width: 2.93rem;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background: transparent;
  }

  .react-datepicker__day--today {
    background: #e7f6f8;
    border-radius: 12px;
    font-size: 16px;
    color: #06a3b4;
  }

  .react-datepicker__day-name {
    color: #39a1ac;
    font-weight: bold;

    @media only screen and (min-width: 320px) {
      width: 1.99rem;
    }

    @media only screen and (min-width: 360px) {
      width: 2.35rem;
    }

    @media only screen and (min-width: 375px) {
      width: 2.48rem;
    }

    @media only screen and (min-width: 411px) {
      width: 2.78rem;
    }

    @media only screen and (min-width: 414px) {
      width: 2.83rem;
    }
  }

  .react-datepicker__day--selected {
    color: #fff;
    background: #06a3b4;
    box-shadow: 0px 4px 20px -1px rgb(6 163 180 / 25%);
    border-radius: 12px;
  }

  .ildId-dropdown-container {
    margin-top: 0;
  }

  .react-datepicker__header {
    background-color: #ffffff;
    border: none;
  }

  .react-datepicker__navigation-icon::before {
    border-color: #39a1ac;
    border-width: 2px 2px 0 0;
    top: 14px;
    width: 5px;
    height: 5px;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    background: #ffffff;
  }

  .react-datepicker__current-month {
    padding-top: 12px;
    margin-bottom: 5px;
  }

  .react-datepicker__navigation {
    top: 14px;
  }
`;
