import React, { Component } from 'react';
// import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  LiffHelper,
  PresonalInfo,
  MedicalInfo,
  Warning,
  NotFound,
  Knowledge,
  Consent,
  Profile,
  MyAccount,
  Dashboard,
  ProfileDataProtectionPolicy,
  ProfilePersonalInformation,
  ProfileDeleteAccount,
  SymptomTracker,
  SummaryRecord,
  PdfSymptomTracker,
  Appointment,
  AppointmentForm,
  Login,
  CMS,
  ChangePassword,
} from './pages';

import './scss/app.scss';

export default class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Switch>
            <Route
              sensitive
              strict
              exact
              path="/liffHelper"
              component={LiffHelper}
            />
            <Route
              sensitive
              strict
              exact
              path="/register/personalInfo"
              component={PresonalInfo}
            />
            <Route
              sensitive
              strict
              exact
              path="/register/medicalInfo"
              component={MedicalInfo}
            />
            <Route
              sensitive
              strict
              exact
              path="/knowledge/:params"
              component={Knowledge}
            />
            <Route
              sensitive
              strict
              exact
              path="/consent/:params"
              component={Consent}
            />
            <Route
              sensitive
              strict
              exact
              path="/profile/myAccount"
              component={MyAccount}
            />
            <Route
              sensitive
              strict
              exact
              path="/profile/dashboard"
              component={Dashboard}
            />
            <Route
              sensitive
              strict
              exact
              path="/profile/dataProtectionPolicy"
              component={ProfileDataProtectionPolicy}
            />
            <Route
              sensitive
              strict
              exact
              path="/profile/personalInformation"
              component={ProfilePersonalInformation}
            />
            <Route
              sensitive
              strict
              exact
              path="/profile/deleteAccount"
              component={ProfileDeleteAccount}
            />
            <Route sensitive strict exact path="/profile" component={Profile} />
            <Route
              sensitive
              strict
              exact
              path="/symptom/tracker/:step"
              component={SymptomTracker}
            />
            <Route
              sensitive
              strict
              exact
              path="/symptom/summary"
              component={SummaryRecord}
            />
            <Route
              sensitive
              strict
              exact
              path="/symptom/summaryPdf"
              component={PdfSymptomTracker}
            />
            <Route
              sensitive
              strict
              exact
              path="/appointment"
              component={Appointment}
            />
            <Route
              sensitive
              strict
              exact
              path="/appointment/create"
              component={AppointmentForm}
            />
            <Route sensitive strict exact path="/cms" component={CMS} />
            <Route sensitive strict exact path="/cms/login" component={Login} />
            <Route
              sensitive
              strict
              exact
              path="/cms/changePassword"
              component={ChangePassword}
            />
            <Route sensitive strict exact path="/warning" component={Warning} />
            <Route
              sensitive
              strict
              exact
              path="/not-found"
              component={NotFound}
            />
            <Route sensitive strict exact component={NotFound} />
          </Switch>
        </div>
      </Router>
    );
  }
}
