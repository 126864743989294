import React from 'react';
import styled from 'styled-components';

const CheckBoxWrapper = styled.label`
  display: block;
  position: relative;
  padding-left: 25px;
  margin: 0 8px 0 0;
  cursor: pointer;
  letter-spacing: 0.4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: normal;
  font-size: 22px;
  /* identical to box height, or 100% */

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  input:checked ~ .checkbox-checkmark:after {
    display: block;
  }

  .button-to-term {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #06a3b4;
    margin-left: 5px;
  }
`;

const CheckBoxCheckMark = styled.div`
  position: absolute;
  top: 46%;
  transform: translateY(-50%);
  left: 0;
  height: 20px;
  width: 19px;
  background-color: #fff;
  border-radius: 32%;
  border: 1.9px solid #a1d8df;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  &:after {
    left: 5px;
    top: 3px;
    width: 3px;
    height: 7px;
    border: solid #06a3b4;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const CheckBox = ({
  name,
  label,
  onChange,
  checked = null,
  isHTML,
  classWrapper,
  defaultValue,
  linkLabel,
  linkUrl,
  action,
}) => {
  return (
    <CheckBoxWrapper className={`checkbox-container ${classWrapper || ''}`}>
      {isHTML ? <div dangerouslySetInnerHTML={{ __html: label }} /> : label}
      <a onClick={() => action(linkUrl)} className="button-to-term">
        {linkLabel}
      </a>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        defaultValue={defaultValue || false}
      />
      <CheckBoxCheckMark className="checkbox-checkmark"></CheckBoxCheckMark>
    </CheckBoxWrapper>
  );
};

export default CheckBox;
