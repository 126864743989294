import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ScoreItem from './components/ScoreItem';
import { useSymptomTrackerContext } from '../../contexts';
import { checkLevelSymptom } from '../../utils/checkLevelSymptom';
import ModalIntroduction from './components/ModalIntroduction';
import Cookies from 'js-cookie';

const Container = styled.div`
  display: block;
  position: relative;
`;
const BoxBody = styled.div`
  display: block;
  text-align: center;
  padding: 0 24px;
`;
const TextFieldQuestion = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 24px;
  color: #2b2b2b;
  margin-top: 21px;

  .active {
    color: #39a1ac;
  }
`;
const ImageDyspnea = styled.img`
  margin: 48px 0 53px;
`;
const BoxIncrement = styled.div`
  width: 100%;
  height: 420px;
  display: flex;
  background: #06a3b4;
  border-radius: 16px 16px 0px 0px;
  align-items: center;
  flex-direction: column;
  padding: 16px 24px;

  @media only screen and (min-height: 700px) {
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;
const IncrementTitle = styled.span`
  font-size: 16px;
  line-height: 22px;
  color: #e7f6f8;
`;
const Line = styled.div`
  width: 100%;
  height: 0.67px;
  margin-top: 8px;
  background-color: #a1d8df;
`;
const BoxReport = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 39px 0 23px;

  ${props => props?.isShowValue && `margin: 5px 0 7px;`}
`;
const ReportTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #f8fbfc;
  letter-spacing: 0.25px;
`;
const RowValue = styled.div`
  height: 72px;
  display: flex;
  align-items: center;
`;
const ReportValue = styled.span`
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.25px;
  margin: 4px 0px;
  color: #f8fbfc;
  text-align: center;
`;
const BoxScore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 325px;
  margin-top: -10px;
`;
const BoxActionIncrease = styled.div`
  display: flex;
  margin-top: 48px;
  align-items: center;
`;
const Decrease = styled.img`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 23px;
  color: #e7f6f8;
  font-size: 23px;
  margin-right: 26px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;
const Increase = styled.img`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 23px;
  color: #e7f6f8;
  font-size: 23px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

export default function DyspneaTrackerContainer() {
  const { lvDyspnea, symptomTrackerAction } = useSymptomTrackerContext();
  const [isOpenIntroduction, setIsOpenIntroduction] = useState(false);

  useEffect(() => {
    if (!Cookies.get('introduction')) {
      setIsOpenIntroduction(true);
    }
  }, []);

  const level = parseFloat(lvDyspnea);
  const statusLabel = checkLevelSymptom(level);

  const handleClickOk = () => {
    Cookies.set('introduction', true);
    setIsOpenIntroduction(false);
  };

  return (
    <Container>
      <BoxBody>
        <TextFieldQuestion>
          สัปดาห์ที่แล้ว อาการ<span className="active">หอบเหนื่อย</span>
          <br />
          ของคุณอยู่ในขั้นไหน?
        </TextFieldQuestion>
        <ImageDyspnea src="/images/img-dyspnea.svg" alt="img dyspnea" />
      </BoxBody>
      <BoxIncrement>
        <IncrementTitle>
          เลือกหมายเลข ที่ตรงกับอาการของคุณมากที่สุด
        </IncrementTitle>
        <Line />
        <BoxReport isShowValue={!isNaN(level)}>
          <ReportTitle>อาการหอบเหนื่อย</ReportTitle>
          {!isNaN(level) && (
            <RowValue>
              <ReportValue>{statusLabel}</ReportValue>
            </RowValue>
          )}
        </BoxReport>
        <BoxScore>
          <ScoreItem defaultScore={0} color="#339900" currentScore={level} />
          <ScoreItem defaultScore={0.5} color="#339900" currentScore={level} />
          <ScoreItem defaultScore={1} color="#339900" currentScore={level} />
          <ScoreItem defaultScore={2} color="#8AB82E" currentScore={level} />
          <ScoreItem defaultScore={3} color="#8AB82E" currentScore={level} />
          <ScoreItem defaultScore={4} color="#FFCC00" currentScore={level} />
          <ScoreItem defaultScore={5} color="#FFCC00" currentScore={level} />
          <ScoreItem defaultScore={6} color="#FFCC00" currentScore={level} />
          <ScoreItem defaultScore={7} color="#FF9966" currentScore={level} />
          <ScoreItem defaultScore={8} color="#FF9966" currentScore={level} />
          <ScoreItem defaultScore={9} color="#CC3300" currentScore={level} />
          <ScoreItem defaultScore={10} color="#CC3300" currentScore={level} />
        </BoxScore>
        <BoxActionIncrease isActive={!isNaN(level)}>
          <Decrease
            src="/icons/decrease.svg"
            onClick={() =>
              symptomTrackerAction?.handleDyspneaIncreaseScore(
                level,
                'decrease',
              )
            }
          />
          <Increase
            src="/icons/increase.svg"
            onClick={() =>
              symptomTrackerAction?.handleDyspneaIncreaseScore(
                level,
                'increase',
              )
            }
          />
        </BoxActionIncrease>
      </BoxIncrement>
      <ModalIntroduction
        isOpen={isOpenIntroduction}
        handleClick={handleClickOk}
      />
    </Container>
  );
}
