import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { exportComponentAsPNG } from 'react-component-export-image';

const Container = styled.div``;

const TitleSummary = styled.span`
  width: 100%;
  font-weight: bold;
`;
const BoxHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
`;
const PersonalInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
const TransactionDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .title {
    font-weight: bold;
  }
`;
const BoxTextField = styled.div`
  display: flex;
  align-items: center;

  .title {
    width: 80px;
    font-weight: bold;
  }
`;
const BoxBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  border: 1px solid #000000;
`;
const BoxTableBody = styled.div`
  display: flex;
  width: 100%;
`;
const BoxFooter = styled.div``;
const BoxTableTitle = styled.div`
  display: flex;
  width: 100%;
`;
const ColumnTable = styled.div`
  flex: 1;
  border: 1px solid #000000;
  padding: 5px;

  ${props => props?.width && `max-width: ${props?.width}px`};
  ${props => props?.textAlign && `text-align: ${props?.textAlign}`}
`;

const ComponentToPrint = React.forwardRef((props, ref) => (
  <Container ref={ref}>
    <TitleSummary>สรุปบันทึกอาการของฉัน</TitleSummary>
    <BoxHeader>
      <PersonalInfo>
        <BoxTextField>
          <span className="title">ชื่อ-สกุล</span>
          <span>กรกนก เปรมจิตฤดี</span>
        </BoxTextField>
        <BoxTextField>
          <span className="title">ปีเกิด</span>
          <span>2534</span>
        </BoxTextField>
        <BoxTextField>
          <span className="title">เพศ</span>
          <span>หญิง</span>
        </BoxTextField>
        <BoxTextField>
          <span className="title">น้ำหนัก</span>
          <span>87</span>
        </BoxTextField>
        <BoxTextField>
          <span className="title">โรค ILD</span>
          <span>
            โรคปอดเป็นพังผืดหรืออักเสบ – จากโรคภูมิคุ้มกันทำลายตนเอง
            (Autoimmune-ILD)
          </span>
        </BoxTextField>
      </PersonalInfo>
      <TransactionDate>
        <span className="title">วันที่ทำรายการ</span>
        <span>27/09/2564</span>
      </TransactionDate>
    </BoxHeader>
    <BoxBody>
      <BoxTableTitle>
        <ColumnTable width={100}>วันที่บันทึก</ColumnTable>
        <ColumnTable width={100}>หอบเหนื่อย</ColumnTable>
        <ColumnTable width={100}>อาการไอ</ColumnTable>
        <ColumnTable width={200}>
          O2 ขณะนั่งพัก / ออกซิเจน(ลิตร/นาที)
        </ColumnTable>
        <ColumnTable width={200}>
          O2 หลังกิจกรรม / ออกซิเจน(ลิตร/นาที)
        </ColumnTable>
        <ColumnTable>อาการอื่นๆ</ColumnTable>
      </BoxTableTitle>

      <BoxTableBody>
        <ColumnTable width={100}>22/10/2021</ColumnTable>
        <ColumnTable width={100} textAlign="right">
          2
        </ColumnTable>
        <ColumnTable width={100} textAlign="right">
          5
        </ColumnTable>
        <ColumnTable width={200} textAlign="right">
          95/2
        </ColumnTable>
        <ColumnTable width={200} textAlign="right">
          90/2
        </ColumnTable>
        <ColumnTable>เป็นไข้หวัดท้องเสีย</ColumnTable>
      </BoxTableBody>
    </BoxBody>
    <BoxFooter>
      <span>33 รายการ</span>
    </BoxFooter>
  </Container>
));

export default function PdfSymptomTrackerContainer() {
  const componentRef = useRef(null);

  useEffect(() => {
    const options = {
      w: 1900,
    };
    exportComponentAsPNG(componentRef, options);
  }, []);

  return <ComponentToPrint ref={componentRef} />;
}
