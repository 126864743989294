import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

export const ProfileContext = createContext();

export function useProfileContext() {
  return useContext(ProfileContext);
}

export function ProfileProvider({ children }) {
  const [customer, setCustomer] = useState({});

  useEffect(() => {
    const lineId = Cookies.get('lineId');
    if (lineId) {
      async function isUserRegistered() {
        try {
          const response = await (
            await fetch(`${process.env.REACT_APP_HOST}/users/lineId/${lineId}`)
          ).json();

          setCustomer(response);
        } catch (error) {
          alert(`${JSON.stringify(error)}, ${error?.message}`);
        }
      }
      isUserRegistered();
    }
  }, []);

  const UpdateProfile = async data => {
    const settings = {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstname: data?.firstName || '',
        lastname: data?.lastName || '',
        weight: data?.weight || '',
        province: data?.province || '',
        hospitalName: data?.hospitalName || '',
        hospitalNo: data?.hospitalNo || '',
        ildId: data?.ildId || '',
      }),
    };

    try {
      const fetchResponse = await fetch(
        `${process.env.REACT_APP_HOST}/users/${data?.id}`,
        settings,
      );
      if (fetchResponse?.status === 200) {
        const data = await fetchResponse.json();
        return data;
      }
    } catch (e) {
      console.error({ e: e?.message });
      return e;
    }
  };

  const values = {
    customer,
    ProfileAction: {
      UpdateProfile,
    },
  };

  return (
    <ProfileContext.Provider value={values}>{children}</ProfileContext.Provider>
  );
}

export default ProfileProvider;
