import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  padding: 28px 24px;
`;
const Title = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #06a3b4;
  width: 152px;
  height: 32px;
`;
const BoxListMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  width: 100%;
  background: #f8fbfc;
  border: 1px solid #e7f6f8;
  box-sizing: border-box;
  border-radius: 16px;
  margin-top: 44px;
`;
const BoxRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e7f6f8;

  &:last-child {
    border: none;
  }
`;
const IconTopic = styled.img`
  width: 24px;
  height: 24px;
`;
const TextContent = styled.span`
  flex: 1;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #393e47;
  margin-left: 8px;
  margin-right: 11px;
  padding-right: 5px;
`;
const IconArrow = styled.img``;

function Profile() {
  const router = useHistory();
  const handleClickGoToPage = page => {
    router.push(page);
  };

  return (
    <Container>
      <Title>โปรไฟล์ของฉัน</Title>
      <BoxListMenu>
        {/* NOTE: phase 2 */}
        <BoxRow onClick={() => handleClickGoToPage('/profile/myAccount')}>
          <IconTopic src="/icons/user-circle.svg" />
          <TextContent>ข้อมูลส่วนตัวของฉัน</TextContent>
          <IconArrow src="/icons/arrow-right.svg" />
        </BoxRow>
        <BoxRow onClick={() => handleClickGoToPage('/profile/dashboard')}>
          <IconTopic src="/icons/chart.svg" />
          <TextContent>สรุปบันทึกอาการของฉัน</TextContent>
          <IconArrow src="/icons/arrow-right.svg" />
        </BoxRow>
        {/* ----- end phase 2 ----- */}
        <BoxRow
          onClick={() => handleClickGoToPage('/profile/dataProtectionPolicy')}
        >
          <IconTopic src="/icons/consent-personal-icon.svg" />
          <TextContent>
            นโยบายคุ้มครองข้อมูลส่วนบุคคล (Data Protection Policy)
          </TextContent>
          <IconArrow src="/icons/arrow-right.svg" />
        </BoxRow>
        <BoxRow
          onClick={() => handleClickGoToPage('/profile/personalInformation')}
        >
          <IconTopic src="/icons/consent-file-icon.svg.svg" />
          <TextContent>
            แบบฟอร์มการให้ความยินยอมเกี่ยวกับข้อมูลส่วนบุคคล
          </TextContent>
          <IconArrow src="/icons/arrow-right.svg" />
        </BoxRow>
        <BoxRow onClick={() => handleClickGoToPage(`/profile/deleteAccount`)}>
          <IconTopic src="/icons/remove-account-con.svg" />
          <TextContent>ลบบัญชี</TextContent>
          <IconArrow src="/icons/arrow-right.svg" />
        </BoxRow>
      </BoxListMenu>
    </Container>
  );
}

export default Profile;
