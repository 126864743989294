import React from 'react';
import styled from 'styled-components';

const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 39px;
  justify-content: center;

  ${props => props?.isIncreaseActive && `margin-top: -21.5px;`}
`;
const TextScore = styled.span`
  color: #e7f6f8;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 4px;
`;
const CircleScore = styled.div`
  width: 14px;
  height: 14px;
  background: ${props => props?.color || '#ffffff'};
  border: 2px solid #f8fbfc;
  border-radius: 19px;
  color: #f8fbfc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;

  ${props =>
    props?.active && `width: 36px; height: 36px; border: 4px solid #f8fbfc;`}
`;

function ScoreItem({ defaultScore, color, currentScore }) {
  currentScore = parseFloat(currentScore);

  const active = currentScore === defaultScore;
  const isIncrease =
    (defaultScore === 0 && currentScore !== 0) ||
    (defaultScore === 10 && currentScore !== 10) ||
    isNaN(currentScore);
  return (
    <ScoreContainer
      isIncreaseActive={
        ((defaultScore === 0 && currentScore !== 0) ||
          (defaultScore === 10 && currentScore !== 10)) &&
        !isNaN(currentScore)
      }
    >
      <TextScore>{isIncrease && defaultScore}</TextScore>
      <CircleScore active={active} color={color}>
        {active && defaultScore}
      </CircleScore>
    </ScoreContainer>
  );
}

export default ScoreItem;
