import React from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import Input from '../Form/Input';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const FormWrapper = styled.form`
  width: 100%;
  display: block;

  .ildId-dropdown-container {
    margin-top: 0;
  }
`;
const TitleDelete = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #f65b5b;
`;
const DescriptionDelete = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #393e47;
  margin-bottom: 18px;
`;
const ButtonConfirm = styled.button`
  width: 250px;
  height: 38px;
  background: #f65b5b;
  border-radius: 24px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.25px;
  border: none;
  margin-bottom: 8px;

  ${props => props?.disabled && 'opacity: 0.4;'}
`;
const ButtonCancel = styled.button`
  width: 250px;
  height: 38px;
  background: transparent;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #5F646F;
`;

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 20,
  },

  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '282px',
    backgroundColor: '#ffffff',
    borderRadius: '24px',
  },
};

export default function DeleteModel({
  isOpen,
  closeModal,
  handleClickCancel,
  handleClickSubmit,
}) {
  const { watch, register, setValue, handleSubmit } = useForm();
  const confirmDelete = watch('confirmDelete') || '';

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <Container>
        <TitleDelete>ยืนยันการลบบัญชี</TitleDelete>
        <DescriptionDelete>
          กรุณาพิมพ์คำว่า “ลบบัญชี” <br />
          เพื่อยืนยันการทำรายการ
        </DescriptionDelete>
        <FormWrapper onSubmit={handleSubmit(handleClickSubmit)}>
          <Input
            name="confirmDelete"
            label="ข้อความ"
            register={register}
            setValue={setValue}
            defaultValue={confirmDelete || ''}
            required
            maxLength={70}
            isThaiOnly
          />
          <ButtonConfirm
            disabled={confirmDelete === '' || confirmDelete !== 'ลบบัญชี'}
          >
            ลบบัญชี
          </ButtonConfirm>
        </FormWrapper>
        <ButtonCancel onClick={() => handleClickCancel()}>ยกเลิก</ButtonCancel>
      </Container>
    </Modal>
  );
}
