import React from 'react';
import styled from 'styled-components';
import PersonalFormInput from './components/PersonalFormInput';

const Container = styled.div`
  display: flex;
`;

function PersonalInfoContainer() {
  return (
    <Container>
      <PersonalFormInput />
    </Container>
  );
}

export default PersonalInfoContainer;
