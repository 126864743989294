import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TextFieldTitle = styled.span`
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.25px;
  color: #393e47;
  margin-bottom: 8px;
`;
const TextLabel = styled.span`
  display: flex;
  font-weight: 300;
  font-size: 14px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #393e47;
`;
const ButtonViewAppointment = styled.button`
  display: flex;
  width: 100%;
  height: 38px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #06a3b4;
  border-radius: 24px;
  border: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.25px;
  margin-top: 40px;
`;

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 20,
  },

  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '281px',
    backgroundColor: '#f1f1f1',
    borderRadius: '12px',
    padding: '16px',
  },
};

export default function AppointmentCompletedModal({
  isOpen,
  closeModal,
  onClick,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <Container>
        <TextFieldTitle>เสร็จสิ้น</TextFieldTitle>
        <TextLabel>เพิ่มตารางนัดหมายเรียบร้อย</TextLabel>
        <ButtonViewAppointment onClick={() => onClick()}>
          ดูนัดหมายของฉัน
        </ButtonViewAppointment>
      </Container>
    </Modal>
  );
}
