import React from 'react';
import styled from 'styled-components';

const BodyContent = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #393e47;

  .bold {
    font-weight: bold;
  }
`;

export default function DataProtectionPolicyContainer() {
  return (
    <BodyContent>
      &nbsp;&nbsp;&nbsp;&nbsp;
      คณะทำงานโรคปอดอินเตอร์สติเชียลในนามสมาคมอุรเวชช์แห่งประเทศไทยในพระบรมราชูปถัมภ์
      (<span className="bold">“เรา”</span>)
      ได้จัดทำนโยบายคุ้มครองข้อมูลส่วนบุคคล (
      <span className="bold">“นโยบายฯ”</span>) ในระหว่างการใช้แอพพลิเคชั่น LINE
      Official Account จากสมาคมอุรเวชช์ฯ
      โดยจะปกป้องข้อมูลส่วนบุคคลของท่านและปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
      พ.ศ.2562 และกฎหมายที่เกี่ยวข้อง
      <br />
      <br />
      <span className="bold">
        ข้อมูลส่วนบุคคลที่สมาคมอุรเวชช์ฯ เก็บรวบรวมผ่านระบบ LINE official
        account
      </span>
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;- ข้อมูลที่ระบุถึงตัวตนของท่าน ได้แก่ ชื่อ-นามสกุล
      และหมายเลขบัตรประจำตัวประชาชน 
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;- ข้อมูลอื่น ๆ ได้แก่ เพศ ปีเกิด และน้ำหนักส่วนสูง
      ทั้งนี้ ข้อมูลส่วนบุคคลของท่านจะไม่ถูกเปิดเผยกับผู้ใช้ท่านอื่น ๆ
      ในการใช้งาน LINE Official Account O2Lung
      รวมถึงการส่งต่อข้อมูลส่วนบุคคลของท่านจะเป็นสิทธิของท่านเท่านั้น
      <br />
      <br />
      <span className="bold">ข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน</span>
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;เราจะเก็บรวบรวม ใช้
      และ/หรือเปิดเผยข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน ได้แก่ ข้อมูลสุขภาพ
      เช่น โรคพังผืดในปอดที่ได้รับการวินิจฉัย หมายเลขเวชระเบียน
      โรงพยาบาลที่ทำการรักษา ข้อมูลการนัดหมายแพทย์
      ข้อมูลอาการเกี่ยวกับโรคพังผืดในปอด รวมถึงอาการอื่น ๆ
      เพื่อวัตถุประสงค์ดังต่อไปนี้
      <ul>
        <li>
          เพื่อให้ท่านสามารถติดตามอาการด้วยตนเองได้ที่บ้าน (home monitoring)
          โดยสามารถติดตามอาการหายใจหอบเหนื่อย อาการไอ ระดับออกซิเจนปลายนิ้ว
          อาการอื่น ๆ จากโรค หรือ อาการข้างเคียงจากยา และ
          สามารถส่งรายงานการติดตามอาการของท่านให้แพทย์หรือพยาบาลประจำตัวเพื่อให้สามารถติดตามอาการได้อย่างต่อเนื่องในระหว่างที่ท่านไม่ได้ไปพบแพทย์ที่โรงพยาบาล
        </li>
        <li>
          เพื่อพัฒนาปรับปรุงระบบและรูปแบบการใช้งานให้ท่านสามารถติดตามอาการด้วยตนเองได้ที่บ้าน
          (home monitoring)
          เพื่อเพิ่มประสิทธิภาพในการดูแลรักษาและติดตามโรคพังผืดในปอดได้อย่างต่อเนื่องและครอบคลุม
          และช่องทางในการรายงานอาการโรคพังผืดในปอดให้แพทย์ผู้ทำการรักษา
        </li>
        <li>
          เพื่อจัดเก็บฐานข้อมูลการดำเนินไปของโรคพังผืดในปอด
          ที่เป็นข้อมูลเฉพาะของประเทศไทย เพื่อทำการพัฒนาระบบ
          ตลอดจนการเข้าถึงข้อมูลและการรักษาได้อย่างครอบคลุม
          ตรงความต้องการของผู้ป่วย
          และช่วยให้แพทย์ปรับการรักษาให้เป็นประโยชน์สูงสุดแก่ท่าน
        </li>
      </ul>
      ทั้งนี้
      เราจะเก็บรวบรวมข้อมูลส่วนบุคคลที่มีความละเอียดอ่อนต่อเมื่อเราได้รับความยินยอมโดยชัดแจ้งจากท่านหรือในกรณีที่เรามีความจำเป็นตามที่กฎหมายอนุญาต
      <br />
      <br />
      <span className="bold">การเก็บรักษาข้อมูลส่วนบุคคล และความปลอดภัย</span>
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;ข้อมูลส่วนบุคคลของท่านจะถูกเก็บรักษาไว้นานเท่าที่จำเป็นอย่างเหมาะสม
      เพื่อวัตถุประสงค์ต่าง ๆ ที่อธิบายไว้ในนโยบายฯ ฉบับนี้
      หรือภายใต้ข้อบังคับของกฎหมาย หรือเพื่อการดำเนินการทางกฎหมาย นอกจากนี้
      เราได้จัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลที่เหมาะสม
      เพื่อป้องกันการสูญหาย การเข้าถึง ใช้ เปลี่ยนแปลง แก้ไข
      หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจ หรือโดยมิชอบ ทั้งนี้
      เป็นไปตามที่กฎหมายใช้บังคับกำหนด
      <br />
      <br />
      <span className="bold">
        วัตถุประสงค์และฐานทางกฎหมายที่สมาคมอุรเวชช์ฯ เก็บ รวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
      </span>
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;เว้นแต่ในบางกรณีโดยเฉพาะที่เราต้องอาศัยฐานความยินยอมของท่าน
      สมาคมอุรเวชช์ฯ อาจเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
      โดยอาศัยฐานการปฏิบัติตามสัญญา หรือฐานการปฏิบัติตามกฎหมาย
      หรือฐานประโยชน์โดยชอบด้วยกฎหมายของเราและของบุคคลภายนอก
      หรือฐานการป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล
      ทั้งนี้ ตามวัตถุประสงค์ดังต่อไปนี้
      <ul>
        <li>
          พัฒนาปรับปรุงระบบและรูปแบบการใช้งานให้ท่านสามารถติดตามอาการด้วยตนเองได้ที่บ้าน
          (home monitoring)
          เพื่อเพิ่มประสิทธิภาพในการดูแลรักษาและติดตามโรคพังผืดในปอดได้อย่างต่อเนื่องและครอบคลุม
          และช่องทางในการรายงานอาการโรคพังผืดในปอดให้แพทย์ผู้ทำการรักษา
        </li>
        <li>ส่งข้อความแจ้งเตือนการนัดหมายแพทย์</li>
        <li>
          ส่งข้อความประชาสัมพันธ์ต่าง ๆ รวมถึงข้อมูลสำหรับผู้ป่วยโรคพังผืดในปอด
          ในเรื่องของโรค การดูแลตัวเอง การจัดการผลข้างเคียงจากยา
          และข่าวสารที่เป็นประโยชน์สำหรับท่าน
        </li>
        <li>
          จัดเก็บฐานข้อมูลการดำเนินไปของโรคพังผืดในปอด
          ที่เป็นข้อมูลเฉพาะของประเทศไทย เพื่อทำการพัฒนาระบบ
          ตลอดจนการเข้าถึงข้อมูลและการรักษาได้อย่างครอบคลุม
          ตรงความต้องการของผู้ป่วย
          และช่วยให้แพทย์ปรับการรักษาให้เป็นประโยชน์สูงสุดแก่ท่าน
        </li>
        <li>
          วัตถุประสงค์อื่น ๆ ที่สนับสนุนการดำเนินการตามวัตถุประสงค์ข้างต้น
        </li>
      </ul>
      &nbsp;&nbsp;&nbsp;&nbsp;ทั้งนี้ หากท่านไม่สามารถให้ข้อมูลส่วนบุคคลแก่เรา
      เมื่อเราร้องขอ ท่านอาจไม่สามารถใช้บริการของเราทั้งหมดหรือบางส่วน
      หรืออาจไม่ได้รับความสะดวก
      หรือไม่ได้รับการปฏิบัติตามสัญญาหรือการปฏิบัติตามวัตถุประสงค์ที่เราได้แจ้งไว้ข้างต้น
      <br />
      <br />
      <span className="bold">การเปิดเผยข้อมูลส่วนบุคคล</span>
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;สมาคมอุรเวชช์ฯ
      อาจเปิดเผยหรือถ่ายโอนข้อมูลส่วนบุคคลของท่านเพื่อทำการวิเคราะห์ข้อมูลโดยรวมระดับประเทศ
      หรือโรงพยาบาล โดยจะดำเนินตามมาตรการที่จำเป็นและเหมาะสม
      หรือเป็นไปตามข้อบังคับและกฎหมาย เพื่อวัตถุประสงค์ที่ตามระบุไว้ข้างต้น
      ให้แก่ ผู้ให้บริการด้านสุขภาพ (health care provider) หรือ กองทุนสุขภาพ
      (payer)
      <br />
      <br />
      <span className="bold">สิทธิของเจ้าของข้อมูลส่วนบุคคล</span>
      <br />
      ตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล ท่านมีสิทธิดังต่อไปนี้
      <ul>
        <li>
          สิทธิในการเข้าถึงข้อมูลส่วนบุคคลของท่าน
          รวมถึงสิทธิในการขอรับสำเนาข้อมูลส่วนบุคคลของท่าน และ/หรือ
          ขอให้โอนย้ายข้อมูลส่วนบุคคลของท่านไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่น
        </li>
        <li>
          สิทธิในการขอถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลตามที่ท่านได้ให้ความยินยอมได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่กับเราในบางกรณี{' '}
        </li>
        <li>
          สิทธิในการระงับใช้ข้อมูลส่วนบุคคลของท่าน และ/หรือ คัดค้านการเก็บรวบรวม
          ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
        </li>
        <li>
          สิทธิในการร้องขอให้แก้ไขข้อมูลส่วนบุคคลนั้นให้ถูกต้อง เป็นปัจจุบัน
          สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด และ สิทธิในการลบ
          หรือทำลายหรือทำให้ข้อมูลส่วนบุคคลของท่านเป็นข้อมูลที่ไม่สามารถระบุตัวท่าน
          โดยท่านสามารถใช้สิทธิดังกล่าวผ่านการตั้งค่า แอพพลิเคชั่น LINE Official
          Account ของเราได้
        </li>
        <li>
          สิทธิในการร้องเรียนต่อหน่วยงานของรัฐเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล
        </li>
      </ul>
      <bi />
      &nbsp;&nbsp;&nbsp;&nbsp;ทั้งนี้ ท่านรับทราบว่าการขอถอนความยินยอม
      หรือขอให้ลบ หรือทำลายข้อมูลส่วนบุคคลของท่านนั้น
      อาจส่งผลทำให้ท่านไม่สามารถใช้งานบริการ หรือรับการอำนวยความสะดวกอื่น ๆ
      จากเราหรือจากแอพพลิเคชั่น LINE Official Account ของเราได้
      <br />
      <br />
      <span className="bold">ช่องทางการติดต่อ</span>
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;หากท่านมีข้อสงสัยหรือคำถามเกี่ยวกับนโยบายฯ ฉบับนี้
      หรือหากท่านมีความประสงค์ที่จะใช้สิทธิต่าง ๆ ของท่านตามที่ระบุในนโยบายฯ
      ฉบับนี้ ท่านสามารถติดต่อสมาคมอุรเวชช์ฯ ได้ตามช่องทางดังต่อไปนี้
      <ul>
        <li>ที่อยู่อีเมล: thailandildconnect@gmail.com</li>
        <li>
          ที่อยู่ตามไปรษณีย์: โรงพยาบาลประสานมิตร 1281 ถ.พหลโยธิน แขวงสามเสนใน
          เขตพญาไท กรุงเทพมหานคร 10400
        </li>
      </ul>
    </BodyContent>
  );
}
