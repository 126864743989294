import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import Layout from '../../components/CMS/components/Layout';
import CMSDashboardContainer from '../../components/CMS/CMSDashboardContainer';
import MedicalInfoProvider from '../../contexts/MedicalInfoContext';
import CMSProvider from '../../contexts/CMSContext';

class CMS extends PureComponent {
  componentDidMount() {
    if (!Cookies.get('accountId')) {
      this.props.history.push('/cms/login');
    }
  }

  render() {
    return (
      <Layout>
        <Container>
          <MedicalInfoProvider>
            <CMSProvider>
              <CMSDashboardContainer />
            </CMSProvider>
          </MedicalInfoProvider>
        </Container>
      </Layout>
    );
  }
}

const Container = styled.div`
  max-width: 1280px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default withRouter(CMS);
