import React from 'react';
import styled from 'styled-components';

const BodyContent = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #393e47;

  .bold {
    font-weight: bold;
  }
`;

export default function PersonalInformationContainer() {
  return (
    <BodyContent>
      &nbsp;&nbsp;&nbsp;&nbsp;เพื่อปฏิบัติตามกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
      ข้าพเจ้าในฐานะเจ้าของข้อมูลส่วนบุคคลยินยอมให้คณะทำงานโรคปอดอินเตอร์สติเชียลในนามสมาคมอุรเวชช์แห่งประเทศไทยในพระบรมราชูปถัมภ์
      (<span className="bold">“สมาคมฯ”</span>) ดำเนินการเก็บรวบรวม ใช้
      และ/หรือเปิดเผยข้อมูลส่วนบุคคลที่มีความละเอียดอ่อนของข้าพเจ้า
      เพื่อวัตถุประสงค์ดังต่อไปนี้
      <br />
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;<span className="bold">ข้อมูลสุขภาพ</span> เช่น
      โรคพังผืดในปอดที่ได้รับการวินิจฉัย หมายเลขเวชระเบียน
      โรงพยาบาลที่ทำการรักษา ข้อมูลการนัดหมายแพทย์
      ข้อมูลอาการเกี่ยวกับโรคพังผืดในปอด รวมถึงอาการอื่น ๆ
      เพื่อวัตถุประสงค์ดังต่อไปนี้
      <ul>
        <li>
          เพื่อให้ท่านสามารถติดตามอาการด้วยตนเองได้ที่บ้าน (home monitoring)
          โดยสามารถติดตามอาการหายใจหอบเหนื่อย อาการไอ ระดับออกซิเจนปลายนิ้ว
          อาการอื่น ๆ จากโรค หรือ อาการข้างเคียงจากยา และ
          สามารถส่งรายงานการติดตามอาการของท่านให้แพทย์หรือพยาบาลประจำตัวเพื่อให้สามารถติดตามอาการได้อย่างต่อเนื่องในระหว่างที่ท่านไม่ได้ไปพบแพทย์ที่โรงพยาบาล
        </li>
        <li>
          เพื่อพัฒนาปรับปรุงระบบและรูปแบบการใช้งานให้ท่านสามารถติดตามอาการด้วยตนเองได้ที่บ้าน
          (home monitoring)
          เพื่อเพิ่มประสิทธิภาพในการดูแลรักษาและติดตามโรคพังผืดในปอดได้อย่างต่อเนื่องและครอบคลุม
          และช่องทางในการรายงานอาการโรคพังผืดในปอดให้แพทย์ผู้ทำการรักษา
        </li>
        <li>
          เพื่อจัดเก็บฐานข้อมูลการดำเนินไปของโรคพังผืดในปอด
          ที่เป็นข้อมูลเฉพาะของประเทศไทย เพื่อทำการพัฒนาระบบ
          ตลอดจนการเข้าถึงข้อมูลและการรักษาได้อย่างครอบคลุม
          ตรงความต้องการของผู้ป่วย
          และช่วยให้แพทย์ปรับการรักษาให้เป็นประโยชน์สูงสุดแก่ท่าน
        </li>
      </ul>
      ท่านได้อ่านและรับทราบรายละเอียดต่างๆ เกี่ยวกับการขอความยินยอม
      และเข้าใจว่าสามารถใช้สิทธิเพิกถอนความยินยอมเมื่อใดก็ได้ ทั้งนี้
      ท่านสามารถศึกษารายละเอียดเกี่ยวกับการเก็บรวบรวม ใช้
      หรือเปิดเผยข้อมูลส่วนบุคคลของเรา ได้ตามนโยบายคุ้มครองข้อมูลส่วนบุคคล
      <br />
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;ข้าพเจ้าได้อ่านและรับทราบนโยบายคุ้มครองข้อมูลส่วนบุคคลของสมาคมฯ
      และให้ความยินยอมแก่สมาคมฯ ในการดำเนินการเก็บรวบรวม ใช้
      และ/หรือเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า ตามที่ระบุไว้ข้างต้น
      <br />
      <br />
    </BodyContent>
  );
}
