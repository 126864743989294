import React from 'react';
import { useParams } from 'react-router-dom';
import DataProtectionPolicy from '../../components/Consent/DataProtectionPolicy';
import PersonalInformation from '../../components/Consent/PersonalInformation';

function ConsentPage() {
  const { params } = useParams();

  return params === 'data-protection-policy' ? (
    <DataProtectionPolicy />
  ) : (
    <PersonalInformation />
  );
}

export default ConsentPage;
