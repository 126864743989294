import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 28px 24px;
`;
const Title = styled.h1`
  margin-bottom: 0;
`;
const Description = styled.p``;
export class NotFound extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
    };
  }

  render() {
    return (
      <Container className="Warning">
        <Title>404</Title>
        <Description>We couldn’t find this page.</Description>
      </Container>
    );
  }
}

export default NotFound;
