import React, { PureComponent } from 'react';
import ChangePasswordContainer from '../../components/CMS/ChangePasswordContainer';
import Layout from '../../components/CMS/components/Layout';
import { CMSProvider } from '../../contexts';

export default class ChangePassword extends PureComponent {
  render() {
    return (
      <Layout>
        <CMSProvider>
          <ChangePasswordContainer />
        </CMSProvider>
      </Layout>
    );
  }
}
