import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import LoadingOverlay from 'react-loading-overlay';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import uniqWith from 'lodash/uniqWith';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import concat from 'lodash/concat';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import Input from '../../Form/Input';
import Dropdown from '../../Form/Dropdown';
import CheckBox from '../../Form/CheckBox';
import ModalSuccess from '../../Modals/ModalSuccess';
import ModalWarningLiffHelper from '../../Modals/ModalWarningLiffHelper';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(buddhistEra);

let suggestionTimeoutId = null;

function MedicalFormInput() {
  const router = useHistory();

  const cookiePersonalForm = Cookies.get('personalInfo');
  const cookieMedicalForm = Cookies.get('medicalInfo');
  const lineId = Cookies.get('lineId');

  const [ildOptions, setIldOptions] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [hospitalNameOptions, setHospitalNameOptions] = useState([]);
  const [suggestionProvince, setSuggestionProvince] = useState('');
  const [provinceSuggestionOptions, setProvinceSuggestionOptions] = useState(
    [],
  );
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [isRegisComplete, setIsRegisComplete] = useState(false);
  const [loaded, setLoaded] = useState(true);

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const ildId = watch('ildId') || '';
  const province = watch('province') || '';
  const hospitalId = watch('hospitalId') || '';
  const hospitalName = watch('hospitalName') || '';
  const termAndCondition1 = watch('termAndCondition1') || false;
  const termAndCondition2 = watch('termAndCondition2') || false;
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [messageWarning, setMessageWarning] = useState('');

  const handleChangeInput = event => {
    if (event?.target?.name) {
      if (event?.target?.name === 'province' && event?.target?.value === '') {
        setValue('hospitalName', '');
        clearTimeout(suggestionTimeoutId);
        setShowSuggestion(false);
        setSuggestionProvince('');
      }

      setValue(event?.target?.name, event?.target?.value);
    }
  };

  const handleClickIldId = ({ value }) => {
    setValue('ildId', value);
  };

  const handleClickHospitalName = ({ value }) => {
    setValue('hospitalName', value);
  };

  const handleClickTermAndCondition1 = () => {
    setValue('termAndCondition1', !termAndCondition1);
  };

  const handleClickTermAndCondition2 = () => {
    setValue('termAndCondition2', !termAndCondition2);
  };

  const handleClickSuggestion = suggestion => {
    document.getElementById('contact_form_province').value = suggestion;
    setValue('hospitalName', '');
    setValue('province', suggestion);
    setSuggestionProvince(suggestion);
    clearTimeout(suggestionTimeoutId);
    setShowSuggestion(false);
  };

  const closePopupWarning = async () => {
    const liff = (await import('@line/liff')).default;
    liff.closeWindow();
  };

  const handleClickRegister = async data => {
    setLoaded(false);
    Cookies.set(
      'medicalInfo',
      JSON.stringify({
        ildId: data?.ildId,
        province: data?.province,
        hospitalId: data?.hospitalId,
        hospitalName: data?.hospitalName,
        termAndCondition1: data?.termAndCondition1,
        termAndCondition2: data?.termAndCondition2,
      }),
    );
    const personalInfo = JSON.parse(cookiePersonalForm || '{}');

    // TODO: create contexts register and move function to contexts register.
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstname: personalInfo?.firstname || '',
        lastname: personalInfo?.lastname || '',
        personalId: personalInfo?.personalId || '',
        isActive: true,
        lineId: lineId || '',
        gender: personalInfo?.gender || '',
        dateOfBirth: personalInfo?.yearOfBirth?.toString() || '',
        weight: personalInfo?.weight || '',
        province: data?.province || '',
        hospitalName: data?.hospitalName || '',
        hospitalNo: data?.hospitalId || '',
        ildId: data?.ildId || '',
      }),
    };

    try {
      const fetchResponse = await fetch(
        `${process.env.REACT_APP_HOST}/users/register`,
        settings,
      );

      if (fetchResponse?.status === 200) {
        Cookies.remove('medicalInfo');
        Cookies.remove('personalInfo');
        const data = await fetchResponse.json();

        setLoaded(true);
        setIsRegisComplete(true);

        setTimeout(async () => {
          const web = Cookies.get('web');
          Cookies.set('userId', data?.userId);

          if (web === 'library') {
            router.replace(`/profile`);
          } else if (web === 'appointment') {
            router.replace('/appointment');
          } else if (web === 'symptomTracker') {
            if (
              dayjs().format('ddd') === 'Sat' ||
              dayjs().format('ddd') === 'Sun' ||
              dayjs().format('ddd') === 'Mon'
            ) {
              router.replace('/symptom/tracker/1');
            } else {
              setIsOpenWarning(true);
              setMessageWarning(
                'ผู้ใช้สามารถทำการบันทึกได้ระหว่าง\nวันเสาร์ ถึง วันจันทร์ เท่านั้น',
              );
            }
          } else {
            const liff = (await import('@line/liff')).default;
            liff.closeWindow();
          }
        }, 1000);

        return data;
      }

      setLoaded(true);
    } catch (e) {
      console.log({ e: e?.message });
      setLoaded(true);
      return e;
    }
    // End TODO
  };

  const setCookie = () => {
    Cookies.set(
      'medicalInfo',
      JSON.stringify({
        ildId,
        province,
        hospitalId,
        hospitalName,
        termAndCondition1,
        termAndCondition2,
      }),
    );
  };

  const handleGotoConsent = link => {
    setCookie();
    router.push(link);
  };

  const handleBack = () => {
    setCookie();
    router.goBack();
  };

  useEffect(() => {
    async function fetchDiseases() {
      let diseases = await (
        await fetch(`${process.env.REACT_APP_HOST}/diseases`)
      ).json();
      if (diseases) {
        diseases = orderBy(diseases, 'diseaseSort', 'asc');
        diseases = diseases?.map(value => {
          return value?.diseaseName;
        });

        setIldOptions(diseases);
      }
    }
    async function fetchHospital() {
      let hospital = await (
        await fetch(`${process.env.REACT_APP_HOST}/hospitals`)
      ).json();

      if (hospital) {
        hospital = orderBy(hospital, 'hospitalName', 'asc');
        let province = map(hospital, data => {
          return data?.province;
        });
        province = sortBy(uniqWith(province, isEqual));

        setHospitals(hospital);
        setProvinceList(province);
      }
    }

    fetchDiseases();
    fetchHospital();
  }, []);

  useEffect(() => {
    if (!isEmpty(cookieMedicalForm)) {
      const medicalInfo = JSON.parse(cookieMedicalForm);
      setValue('ildId', medicalInfo?.ildId);
      setValue('province', medicalInfo?.province);
      setValue('hospitalId', medicalInfo?.hospitalId);
      setValue('hospitalName', medicalInfo?.hospitalName);
      setValue('termAndCondition1', medicalInfo?.termAndCondition1);
      setValue('termAndCondition2', medicalInfo?.termAndCondition2);

      setSuggestionProvince(medicalInfo?.province);
    }
  }, [cookieMedicalForm, setValue, hospitals]);

  useEffect(() => {
    if (suggestionProvince === province) return;

    if (suggestionTimeoutId) {
      clearTimeout(suggestionTimeoutId);
    }

    if (province === '') {
      clearTimeout(suggestionTimeoutId);
      setShowSuggestion(false);
      setSuggestionProvince('');
      return;
    }

    suggestionTimeoutId = setTimeout(() => {
      setShowSuggestion(true);
      const filterProvince = filter(provinceList, data => {
        return 0 === data?.indexOf(province);
      });

      setProvinceSuggestionOptions(filterProvince);
    }, 500);
  }, [hospitals, province, provinceList, suggestionProvince]);

  useEffect(() => {
    async function fetchHospitalOptions() {
      let hospitalOptions = await filter(hospitals, data => {
        return data?.province === suggestionProvince;
      });

      hospitalOptions = map(hospitalOptions, val => {
        return val?.hospitalName;
      });

      const hospitalName = [];
      const hospitalOther = [];

      for (let i = 0; i < hospitalOptions?.length; i++) {
        const data = hospitalOptions[i];
        if (data === 'อื่นๆ') {
          hospitalOther.push(data);
        } else {
          hospitalName.push(data);
        }
      }
      hospitalOptions = concat(hospitalName, hospitalOther);
      setHospitalNameOptions(hospitalOptions);
    }

    fetchHospitalOptions();
  }, [hospitals, suggestionProvince]);

  const validateDisabled =
    isEmpty(ildId) ||
    isEmpty(province) ||
    isEmpty(hospitalName) ||
    !termAndCondition1 ||
    !termAndCondition2;

  return (
    <Container>
      <FormWrapper onSubmit={handleSubmit(handleClickRegister)}>
        <Dropdown
          classContainer="ildId-dropdown-container"
          label="โรคพังผืดในปอดที่ได้รับการวินิจฉัย"
          defaultValue={ildId}
          handleClick={handleClickIldId}
          options={ildOptions}
          register={register}
          required
        />
        <Input
          name="hospitalId"
          label="หมายเลขเวชระเบียน H.N."
          handleChangeInput={handleChangeInput}
          defaultValue={hospitalId || ''}
          register={register}
          setValue={setValue}
        />
        <Boxhospital>
          <HospitalTitle>โรงพยาบาล</HospitalTitle>
          <BoxInputProvince>
            <Input
              classInputContainer="container-input-province"
              name="province"
              label="จังหวัด"
              handleChangeInput={handleChangeInput}
              defaultValue={province || ''}
              register={register}
              setValue={setValue}
              required
            />
            {showSuggestion && provinceSuggestionOptions && (
              <SuggestionOptionContainer>
                {map(provinceSuggestionOptions, (province, index) => (
                  <SuggestionOptionList
                    key={`province-${index}`}
                    onClick={() => handleClickSuggestion(province)}
                  >
                    {province}
                  </SuggestionOptionList>
                ))}
              </SuggestionOptionContainer>
            )}
          </BoxInputProvince>
          <Dropdown
            classContainer="hospital-dropdown"
            label="โรงพยาบาล"
            defaultValue={hospitalName || 'เลือกโรงพยาบาล'}
            handleClick={handleClickHospitalName}
            options={hospitalNameOptions}
            disabled={isEmpty(suggestionProvince)}
          />
        </Boxhospital>
        <BoxTC>
          <CheckBox
            classWrapper="checkbox-tc"
            label="ฉันยอมรับ"
            linkLabel="นโยบายคุ้มครองข้อมูลส่วนบุคคล (Data Protection Policy)"
            linkUrl="/consent/data-protection-policy"
            defaultValue={termAndCondition1}
            onChange={() => handleClickTermAndCondition1()}
            checked={termAndCondition1}
            action={handleGotoConsent}
          />
        </BoxTC>
        <BoxTC2>
          <CheckBox
            classWrapper="checkbox-tc"
            label="ฉันยอมรับ"
            linkLabel="แบบฟอร์มการให้ความยินยอมเกี่ยวกับ ข้อมูลส่วนบุคคล"
            linkUrl="/consent/consent-form-about-personal-info"
            defaultValue={termAndCondition2}
            onChange={() => handleClickTermAndCondition2()}
            checked={termAndCondition2}
            action={handleGotoConsent}
          />
        </BoxTC2>
        <ButtonNext disabled={!isEmpty(errors) || validateDisabled}>
          ลงทะเบียน
        </ButtonNext>
      </FormWrapper>
      <ButtonBack onClick={() => router.replace('/register/personalInfo')}>กลับ</ButtonBack>
      <ModalSuccess isOpen={isRegisComplete} />
      <DarkBackground disappear={!loaded}>
        <LoadingOverlay active={true} spinner={true} />
      </DarkBackground>
      <ModalWarningLiffHelper
        isOpen={isOpenWarning}
        messages={messageWarning}
        handleClick={closePopupWarning}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const FormWrapper = styled.form`
  display: block;

  .ildId-dropdown-container {
    margin-top: 0;
  }
`;
const ButtonNext = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 240px;
  height: 40px;
  background: #06a3b4;
  color: #ffffff;
  border-radius: 24px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;

  ${props => props?.disabled && `opacity: 0.5;`}
`;
const ButtonBack = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 240px;
  height: 40px;
  background: transparent;
  font-weight: bold;
  font-size: 14px;
  border: none;
  color: #06a3b4;
  border-radius: 24px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
`;
const Boxhospital = styled.div`
  border: 1px solid #a1d8df;
  box-sizing: border-box;
  border-radius: 21px;
  padding: 16px 16px 8px;
  display: flex;
  flex-direction: column;

  .hospital-dropdown {
    margin-top: 0;
    margin-bottom: 7px;
  }
`;
const HospitalTitle = styled.span`
  color: #06a3b4;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  margin-bottom: 14px;
`;
const BoxTC = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 3px 16px;
  font-size: 12px;

  .checkbox-tc {
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #727272;
  }
`;
const BoxTC2 = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 16px;
  font-size: 12px;

  .checkbox-tc {
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #727272;
  }
`;
const BoxInputProvince = styled.div`
  position: relative;
  margin-bottom: 28px;

  .container-input-province {
    margin-bottom: 0;
  }
`;
const SuggestionOptionContainer = styled.div`
  width: 100%;
  max-height: 200px;
  position: absolute;
  background-color: #ffffff;
  z-index: 2;
  box-shadow: 0px 4px 20px -1px rgb(113 131 178 / 10%);
  border-radius: 12px;
  overflow: scroll;
`;
const SuggestionOptionList = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
`;
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props => props.disappear && `display: block;`}

  ._loading_overlay_content {
    top: 45%;
    left: 45%;
    position: fixed;
  }
`;

export default MedicalFormInput;
