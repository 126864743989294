import React from 'react';
import styled from 'styled-components';
import Header from './Header';

export default function Layout({ children }) {
  return (
    <Container>
      <Header />
      {children}
    </Container>
  );
}

const Container = styled.div`
  max-width: 1280px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
`;
