import React, { Component } from 'react';
import { Redirect } from 'react-router';

export class Warning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
    };
  }

  goHome = () => this.setState({ redirect: true });

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="Warning">
        <h1>500</h1>
        <p>Sorry Internet Server Error.</p>

        <button onClick={this.goHome}>Login</button>
      </div>
    );
  }
}

export default Warning;
