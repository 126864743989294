import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Input from '../../Form/Input';
import Radio from '../../Form/Radio';
import Dropdown from '../../Form/Dropdown';

const optionsYearOfBirth = [];

function PersonalFormInput() {
  const router = useHistory();
  const cookiePersonalForm = Cookies.get('personalInfo');

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const firstname = watch('firstname') || '';
  const lastname = watch('lastname') || '';
  const gender = watch('gender') || 'male';
  const yearOfBirth = watch('yearOfBirth') || '';
  const weight = watch('weight') || '';

  useEffect(() => {
    if (!isEmpty(cookiePersonalForm)) {
      const personalInfo = JSON.parse(cookiePersonalForm);
      setValue('firstname', personalInfo?.firstname);
      setValue('lastname', personalInfo?.lastname);
      setValue('personalId', personalInfo?.personalId);
      setValue('gender', personalInfo?.gender);
      setValue('yearOfBirth', personalInfo?.yearOfBirth);
      setValue('weight', personalInfo?.weight);
    }
  }, [cookiePersonalForm, setValue]);

  useEffect(() => {
    if (weight) {
      setValue('weight', weight?.replace(/^(\d+.?\d{0,1})\d*$/, '$1'));
    }
  }, [weight, setValue]);

  const handleClickOptionGender = value => {
    setValue('gender', value);
  };

  const handleClickOptionYearOfBirth = ({ value }) => {
    setValue('yearOfBirth', value);
  };

  const handleClickNextStep = data => {
    data = {
      ...data,
      gender,
      yearOfBirth,
    };

    Cookies.set('personalInfo', JSON.stringify(data));
    router.replace('/register/medicalInfo');
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear() + 543;
    for (let index = 0; index < 100; index++) {
      optionsYearOfBirth.push(currentYear - index);
    }
  }, []);

  const validateDisabled =
    isEmpty(firstname) ||
    isEmpty(lastname) ||
    isEmpty(gender) ||
    isEmpty(yearOfBirth.toString()) ||
    isEmpty(weight);

  return (
    <Container>
      <FormWrapper onSubmit={handleSubmit(handleClickNextStep)}>
        <Input
          name="firstname"
          label="ชื่อ"
          register={register}
          setValue={setValue}
          defaultValue={firstname || ''}
          required
          isStringOnly
          maxLength={70}
        />
        <Input
          name="lastname"
          label="นามสกุล"
          register={register}
          setValue={setValue}
          defaultValue={lastname || ''}
          required
          isStringOnly
          maxLength={100}
        />
        <BoxRedioGender>
          <RowTitleGender>เพศ</RowTitleGender>
          <RowChoiceGender>
            <Radio
              key={`gender-male`}
              name="gender"
              label="ชาย"
              checked={gender === 'male'}
              onChange={() => handleClickOptionGender('male')}
            />
            <Radio
              key={`gender-female`}
              name="gender"
              label="หญิง"
              checked={gender === 'female'}
              onChange={() => handleClickOptionGender('female')}
            />
          </RowChoiceGender>
        </BoxRedioGender>
        <Dropdown
          label="ปีเกิด (พ.ศ.)*"
          defaultValue={yearOfBirth.toString()}
          handleClick={handleClickOptionYearOfBirth}
          options={optionsYearOfBirth}
        />
        <Input
          type="number"
          name="weight"
          label="น้ำหนัก (กก.)"
          register={register}
          setValue={setValue}
          defaultValue={weight || ''}
          maxLength={10}
          required
        />
        <ButtonNext disabled={!isEmpty(errors) || validateDisabled}>
          ถัดไป
        </ButtonNext>
      </FormWrapper>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const BoxRedioGender = styled.div`
  display: flex;
  width: 100%;
  margin-top: -10px;
  flex-direction: column;
  padding: 0 8px;
`;
const RowTitleGender = styled.span`
  color: #39a1ac;
  font-size: 14px;
  margin-bottom: 8px;
`;
const RowChoiceGender = styled.div`
  display: flex;
`;
const FormWrapper = styled.form`
  display: block;
`;
const ButtonNext = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 240px;
  height: 40px;
  background: #06a3b4;
  font-weight: bold;
  font-size: 14px;
  border: none;
  color: #ffffff;
  border-radius: 24px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;

  ${props => props?.disabled && `opacity: 0.5;`}
`;

export default PersonalFormInput;
