import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function ColumnChart({
  sitting,
  working,
  categories,
  handleClickViewDetail,
}) {
  const chart = {
    series: [
      {
        name: 'ขณะนั่งพัก',
        data: sitting,
      },
      {
        name: 'หลังกิจกรรม',
        data: working,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 281,
        toolbar: {
          autoSelected: 'pan',
          show: false,
        },
        zoom: {
          enabled: false,
        },
        events: {
          dataPointSelection: handleClickViewDetail,
        },
      },
      legend: {
        show: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '40%',
          endingShape: 'rounded',
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return val + '%';
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      stroke: {
        show: true,
        width: 3,
        colors: ['transparent'],
      },
      xaxis: {
        categories: categories,
      },
      colors: ['#81B829', '#FFA159'],
      yaxis: {
        show: false,
        tickAmount: 5,
        min: 75,
        max: 100,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + '%';
          },
        },
      },
      fill: {
        opacity: 1,
      },
    },
  };

  const widthChart = sitting?.length < 7 ? 320 : sitting?.length * 52;

  return (
    <ReactApexChart
      options={chart.options}
      series={chart.series}
      type="bar"
      width={widthChart}
      height={281}
    />
  );
}
