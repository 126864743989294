import React from 'react';
import styled, { keyframes } from 'styled-components';

export default function Loader() {
  return <LoadingSpinner />;
}

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
}`;
const LoadingSpinner = styled.div`
  border: 4px solid #06a3b4;
  border-radius: 50%;
  border-top-color: white;
  opacity: 1;
  margin: auto;
  width: 24px;
  height: 24px;
  transition: opacity 200ms;
  animation: ${rotate} 1s linear;
  animation-iteration-count: infinite;
  transition-delay: '200ms';
`;
