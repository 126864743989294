import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 20,
  },

  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '281px',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    padding: '0',
  },
};

export default function ModalAppointmentConfirmDelete({
  isOpen,
  closeModal,
  deleteAppointmentId,
  handleClick,
  handleClosePopup,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <Container>
        <TitleModal>ลบนัดหมาย</TitleModal>
        <TextFieldDescription>
          คุณแน่ใจหรือไม่ว่าต้องการจะลบ นัดหมายนี้
        </TextFieldDescription>
        <BoxButton>
          <ButtonCancel onClick={() => handleClosePopup(false)}>
            ยกเลิก
          </ButtonCancel>
          <ButtonConfirm onClick={() => handleClick(deleteAppointmentId)}>
            ลบ
          </ButtonConfirm>
        </BoxButton>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  padding: 16px;
`;
const TitleModal = styled.span`
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.25px;
  color: #2b2b2b;
  margin-bottom: 8px;
`;
const TextFieldDescription = styled.span`
  font-size: 16px;
  color: #5f646f;
  letter-spacing: 0.25px;
  text-align: center;
  margin-bottom: 32px;
`;
const BoxButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const ButtonCancel = styled.button`
  flex: 1;
  height: 38px;
  font-weight: 600;
  font-size: 14px;
  color: #5f646f;
  letter-spacing: 0.25px;
  border-radius: 24px;
  background-color: transparent;
  border: none;
`;
const ButtonConfirm = styled.button`
  flex: 1;
  height: 38px;
  background: #f65b5b;
  border-radius: 24px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.25px;
  border: none;
`;
