import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import styled from 'styled-components';

export default function Header() {
  const router = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen]);

  const handleClick = ({ action }) => {
    if (action === 'change-password') {
      router.push('/cms/changePassword');
    } else if (action === 'logout') {
      Cookies.remove('accountId');
      router.push('/cms/login');
    }
  };

  return (
    <HeaderContainer ref={ref}>
      <TextFieldTitle onClick={() => router.push('/cms')}>
        ILD Assembly
      </TextFieldTitle>
      <MenuAccount onClick={() => setIsOpen(!isOpen)}>
        <IconAccount src="/icons/icon-user-circle.svg" alt="icon account" />
        <TextFieldMenu>ผู้ดูแลระบบ</TextFieldMenu>
        {!isOpen ? <DropDownArrowDown /> : <DropDownArrowUp />}
      </MenuAccount>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            <ListItem
              key={`option-change-password`}
              onClick={() => {
                handleClick({
                  action: 'change-password',
                });
                setIsOpen(false);
              }}
            >
              เปลี่ยนรหัสผ่าน
            </ListItem>
            <ListItem
              className="logout"
              key={`option-logout`}
              onClick={() => {
                handleClick({
                  action: 'logout',
                });
                setIsOpen(false);
              }}
            >
              ออกจากระบบ
            </ListItem>
          </DropDownList>
        </DropDownListContainer>
      )}
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  height: 72px;
  justify-content: space-between;
  align-items: center;
`;
const TextFieldTitle = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: #06a3b4;
  cursor: pointer;
`;
const MenuAccount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`;
const IconAccount = styled.img`
  width: 24px;
`;
const TextFieldMenu = styled.span`
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #404040;
  margin: 0 11px 0 4px;
`;
const DropDownArrowDown = styled.div`
  height: 9px;
  width: 9px;
  border-bottom: 2px solid #06a3b4;
  border-right: 2px solid #06a3b4;
  transform: translateY(-25%) rotate(45deg);
  background-color: transparent;
`;
const DropDownArrowUp = styled.div`
  height: 9px;
  width: 9px;
  border-left: 2px solid #06a3b4;
  border-top: 2px solid #06a3b4;
  transform: translateY(25%) rotate(45deg);
  background-color: transparent;
`;
const DropDownListContainer = styled.div`
  position: absolute;
  width: 166px;
  z-index: 2;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  border-radius: 12px;
  background: #ffffff;
  top: 50px;
  right: 55px;
`;
const DropDownList = styled.ul`
  padding: 8px 16px;
  margin: 0;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  max-height: 210px;
  overflow: scroll;
  box-shadow: 0px 4px 20px -1px rgb(113 131 178 / 10%);
  border-radius: 12px;
`;
const ListItem = styled.li`
  min-height: 40px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #393e47;
  cursor: pointer;
  padding: 12px 0;

  :last-child {
    border-bottom: none;
  }

  &.logout {
    color: #f65b5b;
  }
`;
