import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import ModalWarningLiffHelper from '../components/Modals/ModalWarningLiffHelper';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const LoadingContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 180px;
    height: 180px;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -140px;
  }

  @keyframes spin {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
const Loader = styled.div`
  left: 50%;
  margin-left: -91px;
  font-size: 10px;
  border: 0.8em solid rgba(218, 219, 223, 1);
  border-left: 0.8em solid rgba(58, 166, 165, 1);
  animation: spin 1.1s infinite linear;
`;
const BoxLoadingImage = styled.div``;
const LoadingImage = styled.img`
  left: 50%;
  margin-top: -125px;
  margin-left: -77px;
  position: absolute;
  top: 50%;
`;

function LiffHelper() {
  const router = useHistory();
  const { search } = useLocation();
  const { liffId, web } = queryString.parse(search);
  const [lineId, setLineId] = useState('');
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [messageWarning, setMessageWarning] = useState('');
  const [loading, setLoading] = useState(true);

  const liff = window.liff;

  useEffect(() => {
    async function lineLiffAPI() {
      // console.log(liffId);
      // const liff = (await import('@line/liff')).default;
      try {
        await liff.init({ liffId });
      } catch (error) {
        console.error('liff init error', error.message);
      }
      if (!liff.isLoggedIn()) {
        liff.login();
      } else {
        liff
          .getProfile()
          .then(profile => {
            setLineId(profile?.userId);
          })
          .catch(err => {
            console.log('error', err);
          });
      }
    }
    if (liffId) {
      lineLiffAPI();
    }
  }, [liffId]);

  useEffect(() => {
    if (!isEmpty(lineId) && !isEmpty(web)) {
      async function isUserRegistered() {
        try {
          const response = await (
            await fetch(`${process.env.REACT_APP_HOST}/users/lineId/${lineId}`)
          ).json();

          Cookies.set('lineId', lineId);

          if (!response) {
            Cookies.set('web', web);
            router.push('/register/personalInfo');
          } else {
            Cookies.set('userId', response?.userId);
            if (web === 'library') {
              router.push(`/profile`);
            } else if (web === 'appointment') {
              router.push('/appointment');
            } else if (web === 'symptomTracker') {
              let dateRecord = '';
              if (dayjs().format('ddd') === 'Sat') {
                dateRecord = dayjs().day(6).format('YYYY-MM-DD');
              } else if (dayjs().format('ddd') === 'Sun') {
                dateRecord = dayjs().day(-1).format('YYYY-MM-DD');
              } else if (dayjs().format('ddd') === 'Mon') {
                dateRecord = dayjs().day(-1).format('YYYY-MM-DD');
              }

              const settings = {
                method: 'GET',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'x-user-id': response?.userId,
                },
              };

              const verifyRecord = await (
                await fetch(
                  `${process.env.REACT_APP_HOST}/symptom-trackers/verify-record/${dateRecord}`,
                  settings,
                )
              ).json();

              if (web === 'symptomTracker') {
                if (
                  dayjs().format('ddd') === 'Sat' ||
                  dayjs().format('ddd') === 'Sun' ||
                  dayjs().format('ddd') === 'Mon'
                ) {
                  if (verifyRecord?.count === 0) {
                    router.push('/symptom/tracker/1');
                  } else {
                    setLoading(false);
                    setIsOpenWarning(true);
                    setMessageWarning(
                      'สัปดาห์นี้ผู้ใช้ได้ทำการบันทึกแล้ว \n กรุณาบันทึกใหม่ในสัปดาห์หน้า',
                    );
                  }
                } else {
                  setLoading(false);
                  setIsOpenWarning(true);
                  setMessageWarning(
                    'ผู้ใช้สามารถทำการบันทึกได้ระหว่าง\nวันเสาร์ ถึง วันจันทร์ เท่านั้น',
                  );
                }
              }
            }
          }
        } catch (error) {
          alert(`${JSON.stringify(error)}, ${error?.message}`);
        }
      }
      isUserRegistered();
    }
  }, [lineId, router, web]);

  const closePopupWarning = async () => {
    // const liff = (await import('@line/liff')).default;
    liff.closeWindow();
  };

  return (
    <Container>
      {loading && (
        <React.Fragment>
          <LoadingContainer>
            <Loader className="loader"></Loader>
          </LoadingContainer>
          <BoxLoadingImage>
            <LoadingImage src="../images/logo.png" />
          </BoxLoadingImage>
        </React.Fragment>
      )}

      <ModalWarningLiffHelper
        isOpen={isOpenWarning}
        messages={messageWarning}
        handleClick={closePopupWarning}
      />
    </Container>
  );
}

export default LiffHelper;
