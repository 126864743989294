import React, { PureComponent } from 'react';
import styled from 'styled-components';
import MyAccountContainer from '../../components/MyAccount/MyAccountContainer';
import { ProfileProvider, MedicalInfoProvider } from '../../contexts';

const Container = styled.div`
  width: 100%;
  padding: 0 24px 31px;
`;

class MyAccount extends PureComponent {
  render() {
    return (
      <Container>
        <ProfileProvider>
          <MedicalInfoProvider>
            <MyAccountContainer />
          </MedicalInfoProvider>
        </ProfileProvider>
      </Container>
    );
  }
}

export default MyAccount;
