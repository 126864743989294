import React, { PureComponent } from 'react';
import styled from 'styled-components';
import CreateAppointmentForm from '../../components/Appointment/components/CreateAppointmentForm';
import { AppointmentProvider } from '../../contexts';

const Container = styled.div`
  display: block;
`;
const Title = styled.span`
  height: 88px;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #06a3b4;
  padding: 0 24px;
  background-color: #ffffff;
`;

export default class AppointmentForm extends PureComponent {
  render() {
    return (
      <Container>
        <Title>เพิ่มแจ้งเตือนนัดหมาย</Title>
        <AppointmentProvider>
          <CreateAppointmentForm />
        </AppointmentProvider>
      </Container>
    );
  }
}
