import React from 'react';
import styled from 'styled-components';

const ButtonRadioWrapper = styled.label`
  display: flex;
  height: 20px;
  align-items: center;
  position: relative;
  padding-left: 25px;
  margin: 0 42px 8px 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.4px;
  user-select: none;
  color: #39a1ac;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: -1;
  }

  &:hover input ~ .radio-checkmark {
    background-color: #eee;
  }

  input:checked ~ .radio-checkmark {
    background-color: white;
    border: 1px solid #06a3b4;
  }

  input:checked ~ .radio-checkmark:after {
    display: block;
  }
`;

const RadioCheckMark = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #a1d8df;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffff;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  &:after {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #06a3b4;
  }
`;

const Radio = ({ name, label, onChange, checked = null, readOnly = null }) => {
  return (
    <ButtonRadioWrapper className="radio-container">
      {label}
      <input
        type="radio"
        name={name}
        checked={checked}
        onChange={() => onChange(label)}
        readOnly={readOnly}
      />
      <RadioCheckMark className="radio-checkmark"></RadioCheckMark>
    </ButtonRadioWrapper>
  );
};

export default Radio;
