import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import SymptomTrackerContainer from '../../components/SymptomTracker/SymptomTrackerContainer';
import { SymptomTrackerProvider } from '../../contexts';

const Container = styled.div`
  display: block;
  padding: 17px 0;
`;
const TextFieldTitle = styled.div`
  width: 100%;
  color: #06a3b4;
  font-size: 16px;
  letter-spacing: 0.25px;
  text-align: center;
`;

class SymptomTracker extends PureComponent {
  render() {
    const step = parseInt(this.props?.match?.params?.step) || 1;
    return (
      <Container>
        <TextFieldTitle>บันทึกติดตามอาการ</TextFieldTitle>
        <SymptomTrackerProvider>
          <SymptomTrackerContainer step={step} />
        </SymptomTrackerProvider>
      </Container>
    );
  }
}

export default withRouter(SymptomTracker);
