import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { useDashboardContext } from '../../../contexts';
import { checkLevelSymptom } from '../../../utils/checkLevelSymptom';
import LineChart from './chart/LineChart';

import 'dayjs/locale/th';

dayjs.extend(buddhistEra);

const Container = styled.div`
  padding: 0 16px 24px;
`;
const RowChart = styled.div`
  display: flex;
  justify-content: center;
`;
const ChartLine = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;

  .apexcharts-tooltip {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .apexcharts-yaxis {
    display: none;
  }
`;
const BoxTabX = styled.div`
  display: flex;
  height: 260px;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 17px;
`;
const TextFieldTabX = styled.span`
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.25px;
  margin-bottom: 19px;
  color: ${props => props?.color && props?.color};
`;
const TextFieldDate = styled.span`
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #5f646f;
  border-bottom: 0.67px solid #cccccc;
  padding: 0 0 4px;
  margin-top: 16px;
`;
const BoxDetail = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #06a3b4;
    margin-left: 4px;
  }
`;
const IconTitle = styled.img`
  width: 22px;
`;
const RowValue = styled.div`
  margin-top: 8px;
  span {
    font-size: 16px;
    letter-spacing: 0.25px;
    color: #2b2b2b;
  }
`;

export default function DyspneaTrackerChart() {
  const scroller = useRef(null);
  const { symptomTracker } = useDashboardContext();
  const [dyspneaDate, setDyspneaTracker] = useState([]);
  const [measuredValue, setMeasuredValue] = useState([]);
  const [dyspneaDetail, setDyspneaDetail] = useState([]);

  useEffect(() => {
    if (symptomTracker?.length > 0) {
      const date = [];
      const measured = [];
      symptomTracker.map(data => {
        date.push(dayjs(data?.dateRecord).locale('th').format('DD MMM'));
        measured.push(parseFloat(data?.levelDyspnea));
      });

      setDyspneaDetail(symptomTracker[symptomTracker?.length - 1]);
      setDyspneaTracker(date);
      setMeasuredValue(measured);
    }
  }, [symptomTracker]);

  useEffect(() => {
    if (!isEmpty(symptomTracker)) {
      setTimeout(() => {
        scroller.current.scrollLeft += 1600;
      }, 300);
    }
  }, [symptomTracker]);

  const handleClickViewDetail = (event, chartContext, config) => {
    const detail = symptomTracker[config?.dataPointIndex];
    const currentScrollLeft = scroller.current.scrollLeft;

    setDyspneaDetail(detail);
    scroller.current.scrollLeft += currentScrollLeft;
  };

  return (
    <Container>
      {!isEmpty(dyspneaDate) && (
        <RowChart>
          <ChartLine ref={scroller} id="chart-line">
            <LineChart
              categories={dyspneaDate}
              value={measuredValue}
              handleClickViewDetail={handleClickViewDetail}
            />
          </ChartLine>
          <BoxTabX>
            <TextFieldTabX color="#CC3300">10</TextFieldTabX>
            <TextFieldTabX color="#FF9966">8</TextFieldTabX>
            <TextFieldTabX color="#FFCC00">6</TextFieldTabX>
            <TextFieldTabX color="#FFCC00">4</TextFieldTabX>
            <TextFieldTabX color="#99CC33">2</TextFieldTabX>
            <TextFieldTabX color="#339900">0</TextFieldTabX>
          </BoxTabX>
        </RowChart>
      )}
      {!isEmpty(dyspneaDetail) && (
        <BoxDetail>
          <TextFieldDate>{`วันที่ ${dayjs(dyspneaDetail?.dateRecord)
            .locale('th')
            .format('DD MMMM BBBB')}`}</TextFieldDate>
          <Row>
            <IconTitle src="/icons/icon-dyspnea.svg" />
            <span>อาการหอบเหนื่อย</span>
          </Row>
          <RowValue>
            <span>{`${
              dyspneaDetail?.levelDyspnea || '-'
            } คะแนน (${checkLevelSymptom(
              parseFloat(dyspneaDetail?.levelDyspnea || '-'),
            )})`}</span>
          </RowValue>
          <Row>
            <IconTitle src="/icons/icon-other-symptom.svg" />
            <span>อาการอื่นๆ</span>
          </Row>
          <RowValue>
            <span>{`${
              dyspneaDetail?.otherSymptomsRemark &&
              dyspneaDetail?.otherSymptomsRemark !== 'NULL'
                ? dyspneaDetail?.otherSymptomsRemark
                : '-'
            }`}</span>
          </RowValue>
        </BoxDetail>
      )}
    </Container>
  );
}
