import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

const Input = ({
  classInputContainer,
  type = 'text',
  label,
  name,
  defaultValue,
  required = false,
  maxLength,
  isThaiOnly,
  isStringOnly,
  errorMessage,
  register,
  setValue,
  isNumber,
}) => {
  const handleValidateNumberOnly = (event, maxLength) => {
    const keyCode = event.which ? event.which : event.keyCode;
    const ret = (keyCode >= 48 && keyCode <= 57) || keyCode === 46;
    if (!ret || event?.target?.value?.length >= maxLength) {
      event.preventDefault();
    }
  };

  const handleValidateStringOnly = (event, maxLength) => {
    const keyCode = event.which ? event.which : event.keyCode;
    const ret = (keyCode >= 48 && keyCode <= 57) || keyCode === 46;

    if (ret || event?.target?.value?.length >= maxLength) {
      event.preventDefault();
    }
  };

  const handleValidateThaiOnly = (event, maxLength) => {
    const keyCode = event.which ? event.which : event.keyCode;
    const ret =
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      (keyCode >= 48 && keyCode <= 57) ||
      keyCode === 46;

    if (ret || event?.target?.value?.length >= maxLength) {
      event.preventDefault();
    }
  };

  const handleValidate = ({
    event,
    type,
    isThaiOnly,
    isStringOnly,
    maxLength,
  }) => {
    if (type === 'text' && isThaiOnly) {
      handleValidateThaiOnly(event, maxLength);
    } else if (type === 'text' && isStringOnly) {
      handleValidateStringOnly(event, maxLength);
    } else if (type === 'number') {
      handleValidateNumberOnly(event, maxLength);
    }
  };

  const handleClearInput = (field, setValue) => {
    setValue(field, '');
  };

  return (
    <BoxContainer className={classInputContainer}>
      <BoxInputWrapper>
        <InputBox
          type={type}
          name={name}
          placeholder=" "
          defaultValue={defaultValue || ''}
          autoComplete="off"
          id={`contact_form_${name}`}
          {...register(name, { required })}
          step="any"
          maxLength={maxLength}
          onKeyPress={e =>
            handleValidate({ event: e, type, isThaiOnly, isStringOnly, isNumber, maxLength })
          }
          errorMessage={!isEmpty(errorMessage)}
        />
        <Topic
          htmlFor={`contact_form_${name}`}
          className="form__label"
          errorMessage={!isEmpty(errorMessage)}
          isOnTop={defaultValue !== ''}
        >
          {`${label}${required ? '*' : ''}`}
        </Topic>
        {defaultValue !== '' && (
          <RemoveText
            className="remove-text"
            onClick={() => handleClearInput(name, setValue)}
            src={
              !isEmpty(errorMessage)
                ? '/icons/icon-remove-text-red.svg'
                : '/icons/remove-text-icon.svg'
            }
            alt="clear input"
          />
        )}
      </BoxInputWrapper>
      {!isEmpty(errorMessage) && (
        <BoxError>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </BoxError>
      )}
    </BoxContainer>
  );
};

const BoxContainer = styled.div`
  display: block;
  margin-bottom: 28px;
  min-height: 44px;
`;
const BoxInputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 44px;
`;
const InputBox = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  border: 1px solid #a9b7da;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 12px;
  color: #393e47;
  font-size: 16px;
  outline: none;
  padding: 10px 40px 10px 22px;
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  ${props => props?.errorMessage && `border: 1px solid #F65B5B`};

  :focus ~ .form__label,
  :not(:placeholder-shown) :not(:focus) ~ .form__label {
    color: #7183b2;
    top: -0.5rem;
    font-size: 12px;
  }
`;
const Topic = styled.label`
  position: absolute;
  left: 22px;
  top: 12px;
  font-weight: 300;
  font-size: 16px;
  color: #474747;
  cursor: text;
  padding: 0 3px;
  background-color: #ffffff;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;

  ${props =>
    props?.isOnTop &&
    `color: #7183B2;
    top: -0.5rem;
    font-size: 12px;`}
  ${props => props?.errorMessage && `color: #F65B5B`}
`;
const RemoveText = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
`;
const BoxError = styled.div`
  padding: 0 18px;
`;
const ErrorMessage = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #f65b5b;
`;

export default Input;
