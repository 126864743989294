import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ComingAppointment from './components/ComingAppointment';
import PastAppointment from './components/PastAppointment';

export default function AppointmentContainer() {
  const [isTab, setIsTab] = useState(1);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  return (
    <Container>
      <BoxTabBar>
        <Tab isActive={isTab === 1} onClick={() => setIsTab(1)}>
          กำลังมาถึง
        </Tab>
        <Tab isActive={isTab === 2} onClick={() => setIsTab(2)}>
          ที่ผ่านมา
        </Tab>
      </BoxTabBar>
      {isTab === 1 ? (
        <ComingAppointment tab={isTab} />
      ) : isTab === 2 ? (
        <PastAppointment tab={isTab} />
      ) : (
        ''
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 24px;
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  height: 100%;
`;
const BoxTabBar = styled.div`
  background: #f8fbfc;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 40px;
`;
const Tab = styled.div`
  background: ${props => (props?.isActive ? '#ffffff' : 'transparent')};
  flex: 1;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 16px;
  color: ${props => (props?.isActive ? '#2b2b2b' : '#9196A1')};
  letter-spacing: 0.25px;

  ${props =>
    props?.isActive &&
    `box-shadow: 0px 4px 20px -8px rgba(113, 131, 178, 0.1);`}
`;
const BoxFooter = styled.div`
  display: flex;
  width: 100%;
  height: 88px;
  justify-content: center;
  position: absolute;
  bottom: 88px;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0px 4px 20px -1px rgba(113, 131, 178, 0.1);
`;
const ButtonToCreate = styled.button`
  width: 197px;
  height: 40px;
  border: none;
  background: #06a3b4;
  border-radius: 24px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.25px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IconCreate = styled.img`
  width: 24px;
  margin-right: 10px;
`;
