import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import Radio from '../Form/Radio';
import { useSymptomTrackerContext } from '../../contexts';

export default function OxygenValueAtFingertipsContainer({ activity }) {
  const {
    oxygenValueBySitting,
    oxygenContentBySitting,
    useOxygenationToolsBySitting,
    oxygenValueByWorking,
    oxygenContentByWorking,
    useOxygenationToolsByWorking,
    errorOxygenBySitting,
    errorOxygenByWorking,
    symptomTrackerAction,
  } = useSymptomTrackerContext();

  const [oxygenValue, setOxygenValue] = useState('');
  const [oxygenContent, setOxygenContent] = useState('');
  const [useOxygenationTools, setUseOxygenationTools] = useState('');

  useEffect(() => {
    if (activity === 'Sitting') {
      setOxygenValue(oxygenValueBySitting);
      setOxygenContent(oxygenContentBySitting);
      setUseOxygenationTools(useOxygenationToolsBySitting);
    }

    if (activity === 'Working') {
      setOxygenValue(oxygenValueByWorking);
      setOxygenContent(oxygenContentByWorking);
      setUseOxygenationTools(useOxygenationToolsByWorking);
    }
  }, [
    activity,
    oxygenValueBySitting,
    oxygenContentBySitting,
    useOxygenationToolsBySitting,
    oxygenValueByWorking,
    oxygenContentByWorking,
    useOxygenationToolsByWorking,
  ]);

  const handleOxygenValueKeyUp = (e, activity) => {
    const oxygenValue = e?.target?.value;
    e.target.value = oxygenValue.replace(/^(\d+.?\d{0,2})\d*$/, '$1');

    if (e.target.type === 'number' && e.target.max && e.target.min) {
      const value = parseFloat(e.target.value);
      const max = parseFloat(e.target.max);
      const min = parseFloat(e.target.min);

      e.target.value = value;

      if (e.target.value <= 0 && e.target.value !== '') {
        e.target.value = 0;
      }

      if (activity === 'Sitting') {
        if (value > max || value < min) {
          symptomTrackerAction.setErrorOxygenBySitting({
            ...errorOxygenBySitting,
            oxygenValue: true,
          });
        } else {
          symptomTrackerAction.setErrorOxygenBySitting({
            ...errorOxygenBySitting,
            oxygenValue: false,
          });
        }
        symptomTrackerAction.setOxygenValueBySitting(e.target.value);
      }

      if (activity === 'Working') {
        if (value > max || value < min) {
          symptomTrackerAction.setErrorOxygenByWorking({
            ...errorOxygenByWorking,
            oxygenValue: true,
          });
        } else {
          symptomTrackerAction.setErrorOxygenByWorking({
            ...errorOxygenByWorking,
            oxygenValue: false,
          });
        }

        symptomTrackerAction.setOxygenValueByWorking(e.target.value);
      }

      Cookies.set(`oxygenValueBy${activity}`, e.target.value);
    }
  };

  const handleOxygenContentKeyUp = e => {
    const oxygenContent = e?.target?.value;
    e.target.value = oxygenContent.replace(/^(\d+.?\d{0,2})\d*$/, '$1');

    if (e.target.type === 'number' && e.target.max) {
      e.target.value = parseFloat(e.target.value);
      const max = parseFloat(e.target.max);

      if (e.target.value <= 0 && e.target.value !== '') {
        e.target.value = 0;
      }

      if (activity === 'Sitting') {
        if (e.target.value > max) {
          symptomTrackerAction.setErrorOxygenBySitting({
            ...errorOxygenBySitting,
            oxygenContent: true,
          });
        } else {
          symptomTrackerAction.setErrorOxygenBySitting({
            ...errorOxygenBySitting,
            oxygenContent: false,
          });
        }

        symptomTrackerAction.setOxygenContentBySitting(e.target.value);
      }
      if (activity === 'Working') {
        if (e.target.value > max) {
          symptomTrackerAction.setErrorOxygenByWorking({
            ...errorOxygenByWorking,
            oxygenContent: true,
          });
        } else {
          symptomTrackerAction.setErrorOxygenByWorking({
            ...errorOxygenByWorking,
            oxygenContent: false,
          });
        }

        symptomTrackerAction.setOxygenContentByWorking(e.target.value);
      }

      Cookies.set(`oxygenContentBy${activity}`, e.target.value);
    }
  };

  return (
    <Container>
      <BoxBody>
        <TextFieldQuestion>
          ค่า<span className="active">ออกซิเจน</span>ปลายนิ้ว <br />
          สัปดาห์ที่แล้ว
        </TextFieldQuestion>
        {activity === 'Sitting' ? (
          <ImageOxygenValue
            src="/images/oxygen-at-finger.png"
            alt="img dyspnea"
          />
        ) : (
          <ImageOxygenValue
            src="/images/oxygen-working.png"
            alt="img dyspnea"
          />
        )}

        <BoxLabelActivity>
          <LabelActivity activity={activity}>
            {activity === 'Sitting' ? 'ขณะนั่งพัก' : 'หลังเดินหรือทำกิจกรรม'}
          </LabelActivity>
        </BoxLabelActivity>
        <BoxInformation>
          <span>ค่าออกซิเจนปลายนิ้วของคุณ คือ</span>
          <BoxInputInformation>
            <RowInput>
              <BoxTextInput
                isError={
                  !isEmpty(oxygenValue) &&
                  (activity === 'Sitting'
                    ? errorOxygenBySitting?.oxygenValue
                    : errorOxygenByWorking?.oxygenValue)
                }
              >
                <InputOxygenValue
                  isError={
                    !isEmpty(oxygenValue) &&
                    (activity === 'Sitting'
                      ? errorOxygenBySitting?.oxygenValue
                      : errorOxygenByWorking?.oxygenValue)
                  }
                  type="number"
                  name="oxygenValue"
                  placeholder="ค่าออกซิเจน"
                  defaultValue={oxygenValue}
                  min={75}
                  max={100}
                  onKeyUp={e => handleOxygenValueKeyUp(e, activity)}
                />
                {!isEmpty(oxygenValue) &&
                  (activity === 'Sitting'
                    ? errorOxygenBySitting?.oxygenValue
                    : errorOxygenByWorking?.oxygenValue) && (
                    <span>ค่าออกซิเจน</span>
                  )}
              </BoxTextInput>
              <LabelOxygenValue>
                <span>%</span>
              </LabelOxygenValue>
            </RowInput>
            {!isEmpty(oxygenValue) &&
              (activity === 'Sitting'
                ? errorOxygenBySitting?.oxygenValue
                : errorOxygenByWorking?.oxygenValue) && (
                <span className="error-message">
                  กรุณาระบุค่าในระหว่าง 75-100%
                </span>
              )}
          </BoxInputInformation>
          <span>คุณใช้อุปกรณ์การให้ออกซิเจนหรือไม่?</span>
          <BoxUseOxygen>
            <RowUseOxygen>
              <Radio
                key={`no-other-symptoms`}
                name="useOxygen"
                label="ไม่ใช้"
                checked={useOxygenationTools === false}
                onChange={() =>
                  symptomTrackerAction.handleClickUseOxygenationTools(
                    false,
                    activity,
                  )
                }
              />
              <Radio
                key={`other-symptoms`}
                name="useOxygen"
                label="ใช้"
                checked={useOxygenationTools === true}
                onChange={() =>
                  symptomTrackerAction.handleClickUseOxygenationTools(
                    true,
                    activity,
                  )
                }
              />
            </RowUseOxygen>
          </BoxUseOxygen>
          {useOxygenationTools && (
            <React.Fragment>
              <span>หากคุณใช้ออกซิเจน ใช้ปริมาณเท่าใด?</span>
              <BoxInputInformation>
                <RowInput>
                  <BoxTextInput
                    isError={
                      activity === 'Sitting'
                        ? errorOxygenBySitting?.oxygenContent
                        : errorOxygenByWorking?.oxygenContent
                    }
                  >
                    <InputOxygenContent
                      isError={
                        activity === 'Sitting'
                          ? errorOxygenBySitting?.oxygenContent
                          : errorOxygenByWorking?.oxygenContent
                      }
                      type="number"
                      name="oxygenContent"
                      placeholder="ปริมาณออกซิเจน"
                      defaultValue={oxygenContent}
                      max={99}
                      onKeyUp={e => handleOxygenContentKeyUp(e, activity)}
                    />
                    {(activity === 'Sitting'
                      ? errorOxygenBySitting?.oxygenContent
                      : errorOxygenByWorking?.oxygenContent) && (
                      <span>ปริมาณออกซิเจน</span>
                    )}
                  </BoxTextInput>
                  <LabelOxygenValue>
                    <span>ลิตร/นาที</span>
                  </LabelOxygenValue>
                </RowInput>
                {(activity === 'Sitting'
                  ? errorOxygenBySitting?.oxygenContent
                  : errorOxygenByWorking?.oxygenContent) && (
                  <span className="error-message">
                    กรุณาระบุค่าในระหว่าง 0-99
                  </span>
                )}
              </BoxInputInformation>
            </React.Fragment>
          )}
        </BoxInformation>
      </BoxBody>
    </Container>
  );
}

const Container = styled.div`
  display: block;
  position: relative;
  margin-bottom: 100px;
`;
const BoxBody = styled.div`
  display: block;
  text-align: center;
  padding: 0 27px;
`;
const TextFieldQuestion = styled.div`
  width: 100%;
  height: 64px;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #2b2b2b;
  margin-top: 21px;

  .active {
    color: #39a1ac;
  }
`;
const ImageOxygenValue = styled.img`
  margin-top: 48px;
`;
const BoxLabelActivity = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const LabelActivity = styled.span`
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.25px;
  text-align: center;
  color: ${props => (props?.activity === 'Sitting' ? '#81B829' : '#FFA159')};
`;
const BoxInformation = styled.div`
  display: block;
  text-align: center;
  margin-top: 16px;

  span {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #2b2b2b;
    margin-bottom: 13px;
  }
`;
const BoxInputInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  margin-bottom: 26px;

  .error-message {
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.25px;
    color: #f65b5b;
  }
`;
const RowInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BoxTextInput = styled.div`
  position: relative;

  span {
    color: #f65b5b;
    background-color: #f8fbfc;
    font-weight: 300;
    font-size: 12px;
    position: absolute;
    top: -9px;
    left: 22px;
    padding: 0 2px;
  }
`;
const InputOxygenValue = styled.input`
  width: 186px;
  height: 44px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #a9b7da;
  padding: 10px;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #475985;
  outline: none;

  ${props => props?.isError && `border: 1px solid #f65b5b;`}

  ::placeholder {
    color: #475985;
  }
`;
const InputOxygenContent = styled.input`
  width: 155px;
  height: 44px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #a9b7da;
  padding: 10px;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #475985;
  outline: none;

  ${props => props?.isError && `border: 1px solid #f65b5b;`}

  ::placeholder {
    color: #475985;
  }
`;
const LabelOxygenValue = styled.div`
  span {
    color: #39a1ac;
    font-weight: 600;
    font-size: 14px;
    margin-left: 8px;
  }
`;
const RowUseOxygen = styled.div`
  display: block;
  margin-top: 24px;
  margin-bottom: 20px;
  padding: 0 40px;

  label {
    margin-bottom: 16px;
  }
`;
const BoxUseOxygen = styled.div`
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
`;
