import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import Radio from '../Form/Radio';
import { useSymptomTrackerContext } from '../../contexts';

export default function MedicineTrackerContainer() {
  const { isMedicineChanged, medicineChangedReason, symptomTrackerAction } = 
    useSymptomTrackerContext();

  const [characterColor, setCharacterColor] = useState('#A9B7DA');
  const [countCharacter, setCountCharacter] = useState(0);

  useEffect(() => {
    if (isMedicineChanged) {
      setCountCharacter(medicineChangedReason?.length || 0);
      checkCharacterColor(medicineChangedReason?.length || 0);
    } else {
      setCountCharacter(0);
    }
  }, [isMedicineChanged, medicineChangedReason]);

  const checkCharacterColor = characterCount => {
    let color = '#A9B7DA';
    if (characterCount > 100 && characterCount < 120) {
      color = '#6d5555';
    }
    if (characterCount >= 120 && characterCount < 140) {
      color = '#793535';
    }
    if (characterCount >= 140 && characterCount < 160) {
      color = '#841c1c';
    }
    if (characterCount >= 160) {
      color = '#8f0001';
    }

    setCharacterColor(color);
  };

  const handleKeyUpCharacter = ({ e, maxLength }) => {
    let characterCount = e?.target?.value?.length;
    if (characterCount > maxLength) {
      characterCount = maxLength;
      e.target.value = e.target.value.slice(0, maxLength);
    }

    setCountCharacter(characterCount);
    checkCharacterColor(characterCount);    
    symptomTrackerAction.setMedicineChangedReason(e.target.value);
    
    Cookies.set('medicineChangedReason', e.target.value);
  };

  return (
    <Container>
      <BoxBody>
        <TextFieldQuestion>
          <span className="active">ยาที่ใช้รักษา</span> สัปดาห์นี้
        </TextFieldQuestion>

        <ImageOxygenValue
          src="/images/take-pill.png"
          alt="img take pill"
        />

        <RowOtherSymptoms>
          <Radio
            key={`no-medicine-changed`}
            name="medicineChanged"
            label="ไม่เปลี่ยน"
            checked={isMedicineChanged === false}
            onChange={() =>
              symptomTrackerAction?.handleClickMedicineChange(false)
            }
          />
          <Radio
            key={`medicine-changed`}
            name="medicineChanged"
            label="เปลี่ยน"
            checked={isMedicineChanged === true}
            onChange={() => symptomTrackerAction?.handleClickMedicineChange(true)}
          />
        </RowOtherSymptoms>
        {isMedicineChanged && (
          <RowTextareaMedicineChanged>
            <TextareaMedicineChanged
              name="MedicineChangedTextarea"
              id="medicine-changed-textarea"
              placeholder="โปรดระบุ ชื่อยา และ/หรือ ขนาดยาที่รับประทาน เพื่อแจ้งทีมแพทย์ของท่าน (จำเป็น)"
              defaultValue={medicineChangedReason || ''}
              onKeyUp={e => handleKeyUpCharacter({ e, maxLength: 200 })}
            ></TextareaMedicineChanged>
            <BoxCountCharacter>
              <SpanCountCharacter color={characterColor} id="current">
                {countCharacter}
              </SpanCountCharacter>
              <SpanCountCharacter id="maximum">/200</SpanCountCharacter>
            </BoxCountCharacter>
          </RowTextareaMedicineChanged>
        )}
      </BoxBody>
    </Container>
  );
}

const Container = styled.div`
  display: block;
  position: relative;
`;
const BoxBody = styled.div`
  display: block;
  text-align: center;
  padding: 0 27px;
`;
const RowOtherSymptoms = styled.div`
  display: block;
  margin-top: 24px;

  label {
    margin-bottom: 16px;
    font-weight: bold;
  }
`;
const TextFieldQuestion = styled.div`
  width: 100%;
  height: 64px;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #2b2b2b;
  margin-top: 21px;

  .active {
    color: #39a1ac;
  }
`;
const RowTextareaMedicineChanged = styled.div`
  display: block;
`;
const TextareaMedicineChanged = styled.textarea`
  width: 100%;
  min-height: 150px;
  resize: none;
  border-radius: 12px;
  border: 1px solid #a9b7da;
  padding: 0.5rem;
  color: #666;
  box-shadow: none;
  &:focus {
    outline: none;
    border: 1px solid darken(#ddd, 5%);
    box-shadow: none;
  }
  &[placeholder] {
    font-size: 16px;
    letter-spacing: 0.25px;
    color: #475985;
  }
`;
const BoxCountCharacter = styled.div`
  text-align: right;
`;
const SpanCountCharacter = styled.span`
  font-size: 14px;
  letter-spacing: 0.25px;
  color: ${props => props?.color || '#A9B7DA'};
`;
const ImageOxygenValue = styled.img`
`;
