import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import concat from 'lodash/concat';
import { useForm } from 'react-hook-form';
import { useProfileContext, useMedicalInfoContext } from '../../contexts';
import ModalSelectProvince from './components/ModalSelectProvince';
import { validateTextField } from '../../utils/validateTextField';

function MyAccountContainer() {
  const { customer, ProfileAction } = useProfileContext();
  const { provinceList, hospitals, ildOptions } = useMedicalInfoContext();
  const [hospitalNameOptions, setHospitalNameOptions] = useState([]);
  const [isEdit, setIsEdit] = useState(true);
  const [isOpenModalSelect, setIsOpenModalSelect] = useState(false);
  const [name, setName] = useState('');
  const [options, setOptions] = useState([]);
  // const [htmlElRef, setFocus] = UseFocus();
  const { watch, setValue, register, handleSubmit } = useForm({});
  const [isErrorMessages, setIsErrorMessages] = useState({});

  useEffect(() => {
    filterHospital(customer?.province);
  }, [customer?.province, hospitals]);

  useEffect(() => {
    if (watch('weight')) {
      setValue('weight', watch('weight')?.replace(/^(\d+.?\d{0,1})\d*$/, '$1'));
    }
  }, [watch('weight'), setValue]);

  useEffect(() => {
    if (customer) {
      setValue('firstName', customer?.firstname || '');
      setValue('lastName', customer?.lastname || '');
      setValue('weight', customer?.weight || '');
      setValue('ildId', customer?.ildId || '');
      setValue('personalId', customer?.personalId || '');
      setValue('province', customer?.province || '');
      setValue('hospitalName', customer?.hospitalName || '');
      setValue('hospitalNo', customer?.hospitalNo || '');
      setValue('yearOfBirth', customer?.dateOfBirth || '');
    }
  }, [customer]);

  useEffect(() => {
    if (watch('firstName')) {
      setIsErrorMessages({
        ...isErrorMessages,
        firstnameTh: '',
      });
    } else if (watch('lastName')) {
      setIsErrorMessages({
        ...isErrorMessages,
        lastnameTh: '',
      });
    }
  }, [watch('firstName'), watch('lastName')]);

  const handleClickToEdit = data => {
    if (!isEdit) {
      handleUpdateInformation(data);
    } else {
      setIsEdit(!isEdit);
    }
  };

  const validateInput = data => {
    const pattern_thai = /^[ก-ํ\s]+$/u;
    let errorsList = {};
    if (data?.firstName === '') {
      setValue('firstName', '');
      errorsList = {
        ...errorsList,
        firstname: 'กรุณาระบุ',
      };
    }
    if (data?.lastName === '') {
      setValue('lastName', '');
      errorsList = {
        ...errorsList,
        lastname: 'กรุณาระบุ',
      };
    }
    if (data?.weight === '') {
      errorsList = {
        ...errorsList,
        weight: 'กรุณาระบุ',
      };
    }

    setIsErrorMessages(errorsList);
    return errorsList;
  };

  const handleUpdateInformation = data => {
    const body = {
      id: customer?.userId || '',
      firstName: data?.firstName.trim(),
      lastName: data?.lastName.trim(),
      weight: data?.weight,
      ildId: data?.ildId || customer?.ildId,
      province: data?.province || customer?.province,
      hospitalName: data?.hospitalName || customer?.hospitalName,
      hospitalNo: data?.hospitalNo,
    };

    const error = validateInput(body);

    if (
      (body?.firstName !== customer?.firstname ||
        body?.lastName !== customer?.lastname ||
        body?.weight !== customer?.weight ||
        body?.ildId !== customer?.ildId ||
        body?.province !== customer?.province ||
        body?.hospitalName !== customer?.hospitalName ||
        body?.hospitalNo !== customer?.hospitalNo) &&
      isEmpty(error)
    ) {
      ProfileAction.UpdateProfile(body);
    }

    if (isEmpty(error)) {
      setIsEdit(!isEdit);
    }
  };

  const filterHospital = async province => {
    let hospitalOptions = await filter(hospitals, data => {
      return data?.province === province;
    });

    hospitalOptions = map(hospitalOptions, val => {
      return val?.hospitalName;
    });

    const hospitalName = [];
    const hospitalOther = [];

    for (let i = 0; i < hospitalOptions?.length; i++) {
      const data = hospitalOptions[i];
      if (data === 'อื่นๆ') {
        hospitalOther.push(data);
      } else {
        hospitalName.push(data);
      }
    }
    hospitalOptions = concat(hospitalName, hospitalOther);
    setHospitalNameOptions(hospitalOptions);
  };

  const handleSelected = (name, val) => {
    setValue(name, val);
    if (name === 'province') {
      filterHospital(val);
    }
    setIsOpenModalSelect(false);
  };

  return (
    <Container>
      <form id="form_id" onSubmit={handleSubmit(handleClickToEdit)}>
        <BoxHeader>
          <Title>ข้อมูลส่วนตัวของฉัน</Title>
          <ButtonEdit>
            <IconEdit
              src={`/icons/${!isEdit ? 'icon-check.svg' : 'user-edit.svg'}`}
              alt=""
            />
            <LabelEdit isEdit={isEdit}>
              {!isEdit ? 'บันทึก' : 'แก้ไข'}
            </LabelEdit>
          </ButtonEdit>
        </BoxHeader>
        <BoxPersonalInfomation>
          <Row>
            <LabelTitle>ชื่อจริง</LabelTitle>
            <TextField
              isError={
                !isEmpty(isErrorMessages?.firstname) ||
                (!isEmpty(customer) && watch('firstName')?.trim() === '')
              }
              isErrorTH={isErrorMessages?.firstnameTh}
              {...register('firstName')}
              autoComplete="off"
              readOnly={isEdit}
              // ref={htmlElRef}
              defaultValue={customer?.firstname}
              placeholder={'กรุณาระบุ'}
              onKeyPress={e =>
                validateTextField({
                  event: e,
                  type: 'text',
                  maxLength: 70,
                })
              }
            />
          </Row>
          <Row>
            <LabelTitle>นามสกุล</LabelTitle>
            <TextField
              isError={
                !isEmpty(isErrorMessages?.lastname) ||
                (!isEmpty(customer) && watch('lastName')?.trim() === '')
              }
              isErrorTH={isErrorMessages?.lastnameTh}
              {...register('lastName')}
              autoComplete="off"
              readOnly={isEdit}
              defaultValue={customer?.lastname}
              placeholder={'กรุณาระบุ'}
              onKeyPress={e =>
                validateTextField({
                  event: e,
                  type: 'text',
                  maxLength: 100,
                })
              }
            />
          </Row>
          <Row>
            <LabelTitle>เพศ</LabelTitle>
            <TextField
              {...register('gender')}
              defaultValue={
                customer?.gender
                  ? customer?.gender === 'male'
                    ? 'ชาย'
                    : 'หญิง'
                  : ''
              }
              autoComplete="off"
              readOnly
            />
          </Row>
          <Row>
            <LabelTitle>ปีเกิด (พ.ศ.)</LabelTitle>
            <TextField
              {...register('yearOfBirth')}
              autoComplete="off"
              defaultValue={customer?.dateOfBirth}
              readOnly
            />
          </Row>
          <Row>
            <LabelTitle>น้ำหนัก (กก.)</LabelTitle>
            <TextField
              isError={!isEmpty(isErrorMessages?.weight)}
              type="number"
              {...register('weight')}
              autoComplete="off"
              readOnly={isEdit}
              step="any"
              defaultValue={customer?.weight}
              placeholder={'กรุณาระบุ'}
              onKeyPress={e =>
                validateTextField({
                  event: e,
                  type: 'number',
                  maxLength: 10,
                })
              }
            />
          </Row>
        </BoxPersonalInfomation>
        <ErrorBox>
          {isErrorMessages?.firstnameTh && (
            <span>กรุณาระบุชื่อเป็นตัวอักษรภาษาไทย</span>
          )}
          {isErrorMessages?.lastnameTh && (
            <span>กรุณาระบุนามสกุลเป็นตัวอักษรภาษาไทย</span>
          )}
        </ErrorBox>

        <TextTitleMedicalInfo>ข้อมูลทางการแพทย์</TextTitleMedicalInfo>
        <BoxDiseaseInfo isEdit={isEdit}>
          <TextValue
            isEdit={isEdit}
            onClick={() => {
              !isEdit && setIsOpenModalSelect(true);
              setOptions(ildOptions);
              setName('ildId');
            }}
          >
            {watch('ildId') || customer?.ildId || ''}
          </TextValue>
        </BoxDiseaseInfo>
        <BoxMedicalInformation>
          <Row>
            <LabelTitle>จังหวัด</LabelTitle>
            <RowContent>
              <TextFieldDropdown
                isEdit={isEdit}
                onClick={() => {
                  !isEdit && setIsOpenModalSelect(true);
                  setOptions(provinceList);
                  setName('province');
                }}
              >
                {watch('province') || customer?.province}
              </TextFieldDropdown>
              {!isEdit && (
                <IconArrowDown
                  src="/icons/arrow-down.svg"
                  alt="icons arrow down"
                />
              )}
            </RowContent>
          </Row>
          <Row>
            <LabelTitle>โรงพยาบาล</LabelTitle>
            <RowContent>
              <TextFieldDropdown
                isEdit={isEdit}
                onClick={() => {
                  !isEdit && setIsOpenModalSelect(true);
                  setOptions(hospitalNameOptions);
                  setName('hospitalName');
                }}
              >
                {watch('hospitalName') || customer?.hospitalName}
              </TextFieldDropdown>
              {!isEdit && (
                <IconArrowDown
                  src="/icons/arrow-down.svg"
                  alt="icons arrow down"
                />
              )}
            </RowContent>
          </Row>
          <Row>
            <LabelTitle>เลขเวชระเบียน (H.N.)</LabelTitle>
            <TextField
              {...register('hospitalNo')}
              autoComplete="off"
              defaultValue={customer?.hospitalNo}
              readOnly={isEdit}
            />
          </Row>
        </BoxMedicalInformation>
        <ModalSelectProvince
          isOpen={isOpenModalSelect}
          name={name}
          options={options}
          handleClick={handleSelected}
        />
      </form>
    </Container>
  );
}

const Container = styled.div`
  display: block;
`;
const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 0;
  margin-bottom: 16px;
`;
const Title = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: #06a3b4;
`;
const ButtonEdit = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;
const IconEdit = styled.img`
  margin-right: 5.67px;
`;
const LabelEdit = styled.span`
  color: ${props => (props?.isEdit ? '#9196a1' : '#06A3B4')};
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
`;
const BoxPersonalInfomation = styled.div`
  display: block;
  background-color: #f8fbfc;
  border: 1px solid #e7f6f8;
  border-radius: 16px;
`;
const Row = styled.div`
  display: flex;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e7f6f8;
  padding: 12px 16px;

  :last-child {
    border: none;
  }
`;
const LabelTitle = styled.span`
  flex: 1;
  font-size: 16px;
  line-height: 22px;
  color: #5f646f;
  min-width: 120px;
`;
const TextField = styled.input`
  flex: 1;
  max-width: 146px;
  min-width: 120px;
  background: transparent;
  border: none;
  text-align: right;
  outline-width: 0;
  color: #2b2b2b;
  font-size: 16px;
  line-height: 24px;

  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  ${props => !props?.readOnly && `color: #06A3B4;`}
  ${props => props?.isErrorTH && `color: #F65B5B`}
  ${props =>
    props.isError &&
    `::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #F65B5B;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #F65B5B;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #F65B5B;
    }`}
`;
const TextValue = styled.span`
  flex: 1;
  color: #2b2b2b;
  font-size: 16px;
  line-height: 24px;

  ${props => !props?.isEdit && `color: #06A3B4;`}
`;
const TextTitleMedicalInfo = styled.span`
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #06a3b4;
  margin-bottom: 16px;
  margin-top: 32px;
`;
const BoxDiseaseInfo = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f8fbfc;
  border: 1px solid #e7f6f8;
  box-sizing: border-box;
  border-radius: 16px;
  height: 72px;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 16px;

  ${props => !props?.isEdit && `border: 1px solid #06A3B4;`}
`;
const BoxMedicalInformation = styled.div`
  display: block;
  background-color: #f8fbfc;
  border: 1px solid #e7f6f8;
  border-radius: 16px;
`;
const TextFieldDropdown = styled.span`
  flex: 1;
  color: #2b2b2b;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 125px;

  ${props => !props?.isEdit && `color: #06A3B4;`}
`;
const IconArrowDown = styled.img`
  width: 24px;
`;
const RowContent = styled.div`
  display: flex;
  align-items: center;
`;
const ErrorBox = styled.div`
  display: flex;
  flex-direction: column;
  color: #f65b5b;
  margin-top: 8px;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;

  span {
    margin-bottom: 4px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export default MyAccountContainer;
