import React from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import PersonalInformationContainer from './components/PersonalInformationContainer';

const Container = styled.div`
  padding: 16px 24px;
`;
const TitlePersonalInfo = styled.div`
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #06a3b4;
  margin: 16px 0px;
  text-align: center;
`;
const BoxButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const ButtonAccept = styled.button`
  background: #06a3b4;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 240px;
  height: 40px;
  border: none;
  letter-spacing: 0.25px;
  color: #ffffff;
  font-weight: 600;
`;

export default function PersonalInformation() {
  const router = useHistory();

  const handleClickAccept = () => {
    let medicalData = Cookies.get('medicalInfo');

    if (!isEmpty(medicalData)) {
      medicalData = JSON.parse(medicalData);
      const addAccept = {
        ...medicalData,
        termAndCondition2: true,
      };
      Cookies.set('medicalInfo', JSON.stringify(addAccept));
    }
    router.goBack();
  };

  return (
    <Container>
      <TitlePersonalInfo>
        แบบฟอร์มการให้ความยินยอม เกี่ยวกับข้อมูลส่วนบุคคล
      </TitlePersonalInfo>
      <PersonalInformationContainer />
      <BoxButton>
        <ButtonAccept onClick={() => handleClickAccept()}>ยอมรับ</ButtonAccept>
      </BoxButton>
    </Container>
  );
}
