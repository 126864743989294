import React, { PureComponent } from 'react';
import styled from 'styled-components';
import MedicalInfoContainer from '../../components/RegisterForm/MedicalInfoContainer';

const Container = styled.div`
  width: 100%;
  padding: 32px;
`;
const BoxHeader = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: #06a3b4;
`;
const Description = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #797b87;
  margin-top: 10px;
`;
const BoxForm = styled.div`
  margin-top: 40px;
`;

class MedicalInfo extends PureComponent {
  render() {
    return (
      <Container>
        <BoxHeader>
          <Title>ลงทะเบียน</Title>
          <Description>กรอกข้อมูลทางการแพทย์</Description>
        </BoxHeader>
        <BoxForm>
          <MedicalInfoContainer />
        </BoxForm>
      </Container>
    );
  }
}

export default MedicalInfo;
