export function checkLevelSymptom(level) {
  let statusLabel = '';
  if (level === 0) {
    statusLabel = 'ไม่รู้สึกเหนื่อยแม้แต่น้อย';
  } else if (level === 0.5) {
    statusLabel = 'แค่เริ่มรู้สึกเหนื่อยเล็กน้อยเท่านั้น';
  } else if (level === 1) {
    statusLabel = 'เหนื่อยน้อยมาก';
  } else if (level === 2) {
    statusLabel = 'เหนื่อยเล็กน้อย';
  } else if (level === 3) {
    statusLabel = 'เหนื่อยพอควร';
  } else if (level === 4) {
    statusLabel = 'เหนื่อยค่อนข้างมาก';
  } else if (level === 5 || level === 6) {
    statusLabel = 'เหนื่อยมาก';
  } else if (level === 7 || level === 8) {
    statusLabel = 'เหนื่อยสุดสุด';
  } else if (level === 9) {
    statusLabel = 'เหนื่อยสาหัสสากรรจ์';
  } else if (level === 10) {
    statusLabel = 'เหนื่อยที่สุดในชีวิต';
  }

  return statusLabel;
}
