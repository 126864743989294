import React, { createContext, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import orderBy from 'lodash/orderBy';
import Cookies from 'js-cookie';

dayjs.extend(utc);
dayjs.extend(timezone);

export const DashboardContext = createContext();

export function useDashboardContext() {
  return useContext(DashboardContext);
}

export function DashboardProvider({ children }) {
  const [exportCompleted, setExportCompleted] = useState(false);
  const [symptomTracker, setSymptomTracker] = useState([]);

  useEffect(() => {
    async function fetchSymptomTracker() {
      const userId = Cookies.get('userId');
      let symptomTracker = await (
        await fetch(`${process.env.REACT_APP_HOST}/symptom-trackers/${userId}`)
      ).json();
      if (symptomTracker) {
        symptomTracker = orderBy(symptomTracker, 'dateRecord', 'asc');
        symptomTracker = symptomTracker?.map(value => {
          return {
            ...value,
            dateRecord: dayjs(value?.dateRecord).tz('Asia/Bangkok'),
          };
        });

        setSymptomTracker(symptomTracker);
      }
    }

    fetchSymptomTracker();
  }, []);

  const exportPDF = async () => {
    try {
      const userId = Cookies.get('userId');
      let trackerExport = await (
        await fetch(
          `${process.env.REACT_APP_HOST}/symptom-trackers/export/${userId}`,
        )
      ).json();
      if (trackerExport) {
        setExportCompleted(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const values = {
    symptomTracker,
    exportCompleted,
    dashboardAction: {
      exportPDF,
    },
  };

  return (
    <DashboardContext.Provider value={values}>
      {children}
    </DashboardContext.Provider>
  );
}

export default DashboardProvider;
