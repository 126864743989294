import React from 'react';
import map from 'lodash/map';
import styled from 'styled-components';
import Modal from 'react-modal';

const ProvinceListContainer = styled.div`
  display: block;
  width: 100%;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
`;
const BoxProvinceList = styled.ul`
  padding: 8px 16px;
  margin: 0;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  max-height: 500px;
  overflow: scroll;
  box-shadow: 0px 4px 20px -1px rgb(113 131 178 / 10%);

  ::-webkit-scrollbar {
    display: none;
  }
`;
const ListItem = styled.li`
  min-height: 40px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #393e47;
  cursor: pointer;
  padding: 12px 0;
  border-bottom: 1px solid #eef5f6;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  :last-child {
    border-bottom: none;
  }
`;

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 20,
  },

  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '327px',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    padding: '0',
  },
};

export default function ModalSelectProvince({
  isOpen,
  options,
  name,
  closeModal,
  handleClick,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <ProvinceListContainer>
        <BoxProvinceList>
          {map(options, (option, idx) => (
            <ListItem
              key={`option-${idx}`}
              onClick={() => handleClick(name, option?.value || option)}
            >
              {option?.value || option}
            </ListItem>
          ))}
        </BoxProvinceList>
      </ProvinceListContainer>
    </Modal>
  );
}
