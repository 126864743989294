import React from 'react';
import styled from 'styled-components';
import MedicalFormInput from './components/MedicalFormInput';

const Container = styled.div`
  display: flex;
`;

function MedicalInfoContainer() {
  return (
    <Container>
      <MedicalFormInput />
    </Container>
  );
}

export default MedicalInfoContainer;
