import React from 'react';
import styled from 'styled-components';
import PdfSymptomTrackerContainer from '../../components/Dashboard/PdfSymptomTrackerContainer';
import { DashboardProvider } from '../../contexts';

const Container = styled.div`
  padding: 50px 20px;
`;

export default function pdfSymptomTracker() {
  return (
    <Container>
      <DashboardProvider>
        <PdfSymptomTrackerContainer />
      </DashboardProvider>
    </Container>
  );
}
