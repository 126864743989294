import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  padding: 24px;
`;
const TitleModal = styled.span`
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.25px;
  color: #2b2b2b;
  margin-bottom: 8px;
`;
const TextFieldDescription = styled.span`
  font-size: 16px;
  color: #5f646f;
  letter-spacing: 0.25px;
  margin-bottom: 24px;

  ul {
    padding-left: 24px;

    li {
      margin-bottom: 23px;

      :last-child {
        margin-bottom: 0;
      }
    }
  }
`;
const BoxButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 17px;
`;
const ButtonConfirm = styled.button`
  width: 100%;
  height: 40px;
  background: #06a3b4;
  border-radius: 24px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.25px;
  border: none;
`;

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 20,
  },

  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '331px',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    padding: '0',
  },
};

export default function ModalIntroduction({ isOpen, closeModal, handleClick }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <Container>
        <TitleModal>ข้อควรปฏิบัติ</TitleModal>
        <TextFieldDescription>
          <ul>
            <li>
              โรคพังผืดในปอดมีการดำเนินไปของโรค แตกต่างกันในแต่ละคน การติดตาม
              อาการเป็นสิ่งสำคัญในการเริ่มหรือ ปรับการรักษา
            </li>

            <li>
              โปรดประเมินอาการตัวเองสัปดาห์ละ ครั้ง (โดยลงข้อมูล ในวันเสาร์
              อาทิตย์ หรือจันทร์)
            </li>

            <li>
              ส่งรายงานให้แพทย์หรือพยาบาลของคุณอย่างสม่ำเสมอเพื่อติดตามอาการ
              อย่างต่อเนื่อง (เช่นทุกเดือนหรือทุกครั้ง ที่มีการนัดหมาย หรือ
              ตามที่แพทย์ กำหนด)
            </li>
          </ul>
        </TextFieldDescription>
        <BoxButton>
          <ButtonConfirm onClick={() => handleClick()}>ตกลง</ButtonConfirm>
        </BoxButton>
      </Container>
    </Modal>
  );
}
