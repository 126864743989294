import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppointmentContext } from '../../../contexts';
import NoListAppointmentForthComing from '../components/NoListAppointmentForthComing';
import AppointmentItem from './AppointmentItem';
import Loader from '../../Animation/Loader';
import ModalAppointmentInfo from './ModalAppointmentInfo';

export default function PastAppointment({ tab }) {
  const { isLoading, pastAppointments, currentPastPage, AppointmentAction } =
    useAppointmentContext();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(currentPastPage || 0);
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [appointmentInfo, setAppointmentInfo] = useState({});

  useEffect(() => {
    if (pastAppointments?.length <= 0) {
      AppointmentAction.fetchAppointment('past', page);
    }
  }, []);

  const fetchMoreData = () => {
    setTimeout(() => {
      if (pastAppointments.length >= page) {
        AppointmentAction.fetchAppointment('past', page);

        if (!isLoading) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
        return;
      }
    }, 1000);
  };

  useEffect(() => {
    if (pastAppointments?.length > page) {
      setPage(page + 10);
    }
  }, [pastAppointments]);

  useEffect(() => {
    AppointmentAction.setCurrentPastPage(page);
  }, [page]);

  const handleClickOpenViewInfo = data => {
    setAppointmentInfo(data);
    setIsOpenInfo(true);
  };

  return (
    <Container>
      {pastAppointments?.length > 0 ? (
        <>
          <AppointmentList id="scrollableDiv">
            <InfiniteScroll
              dataLength={pastAppointments.length}
              next={page => fetchMoreData({ page })}
              hasMore={hasMore}
              loader={<Loader />}
              scrollableTarget="scrollableDiv"
            >
              {pastAppointments.map((val, key) => (
                <AppointmentItem
                  key={`appointment-${key}`}
                  data={val}
                  handleClickView={handleClickOpenViewInfo}
                />
              ))}
            </InfiniteScroll>
          </AppointmentList>
        </>
      ) : pastAppointments?.length === 0 && isLoading ? (
        <Loader />
      ) : (
        <NoListAppointmentForthComing tab={tab} />
      )}
      <ModalAppointmentInfo
        isOpen={isOpenInfo}
        data={appointmentInfo}
        handleClosePopup={() => setIsOpenInfo(false)}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-top: 24px;
`;
const AppointmentList = styled.div`
  overflow: scroll;

  @media only screen and (min-height: 568px) {
    max-height: 370px;
  }
  @media only screen and (min-height: 640px) {
    max-height: 411px;
  }
  @media only screen and (min-height: 667px) {
    max-height: 465px;
  }
  @media only screen and (min-height: 713px) {
    max-height: 536px;
  }
  @media only screen and (min-height: 731px) {
    max-height: 530px;
  }
  @media only screen and (min-height: 736px) {
    max-height: 536px;
  }
  @media only screen and (min-height: 812px) {
    max-height: 610px;
  }
  @media only screen and (min-height: 823px) {
    max-height: 625px;
  }
  @media only screen and (min-height: 1024px) {
    max-height: 820px;
  }
  @media only screen and (min-height: 1366px) {
    max-height: 1165px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .infinite-scroll-component {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
