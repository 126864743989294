import React, { useState, useRef, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import styled from 'styled-components';

const BoxContainer = styled.div`
  width: 100%;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #a9b7da;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 10px 17px 10px 22px;
  cursor: pointer;
`;
const TextLabel = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  color: #474747;
  position: absolute;
  z-index: 1;
  top: 14px;
  left: 22px;
  padding: 0 3px;
  background-color: #ffffff;

  ${props => props?.isValue && `color: #7183B2; top: -0.5rem; font-size: 12px;`}
`;
const DropDownContainer = styled.div`
  position: relative;
  min-height: 44px;
  margin-bottom: 28px;
  min-height: 44px;
  margin-top: 20px;

  ${props => props?.disabled && `opacity: 0.5;`}
`;
const DropDownWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const DropDownHeader = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  min-height: 44px;
  -webkit-tap-highlight-color: transparent;
`;
const DropDownTitle = styled.span`
  max-width: 95%;
  font-weight: normal;
  font-size: 16px;
  color: #393e47;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props => props?.disabled && `color: #bcbcbc;`}
`;
const DropDownArrowDown = styled.div`
  height: 9px;
  width: 9px;
  border-bottom: 2px solid #7183b2;
  border-right: 2px solid #7183b2;
  transform: translateY(-25%) rotate(45deg);
  background-color: transparent;
`;
const DropDownArrowUp = styled.div`
  height: 9px;
  width: 9px;
  border-left: 2px solid #7183b2;
  border-top: 2px solid #7183b2;
  transform: translateY(25%) rotate(45deg);
  background-color: transparent;
`;
const DropDownListContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2;
  margin-top: 46px;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  border-radius: 12px;
  background: #ffffff;
`;
const DropDownList = styled.ul`
  padding: 8px 16px;
  margin: 0;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  max-height: 210px;
  overflow: scroll;
  box-shadow: 0px 4px 20px -1px rgb(113 131 178 / 10%);
`;
const ListItem = styled.li`
  min-height: 40px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #393e47;
  cursor: pointer;
  padding: 12px 0;
  border-bottom: 1px solid #eef5f6;

  :last-child {
    border-bottom: none;
  }
`;

const Dropdown = ({
  classContainer,
  classNameTextLabel,
  label,
  defaultValue,
  options,
  placeholder,
  disabled,
  handleClick,
  required,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen]);

  const toggling = () => !disabled && setIsOpen(!isOpen);

  return (
    <DropDownContainer
      ref={ref}
      disabled={disabled}
      className={classContainer || ''}
    >
      <DropDownWrapper>
        {!isEmpty(label) && (
          <TextLabel
            className={classNameTextLabel || ''}
            isValue={!isEmpty(defaultValue)}
            onClick={toggling}
          >
            {`${label}${required ? '*' : ''}`}
          </TextLabel>
        )}
        <DropDownHeader onClick={toggling} disabled={disabled}>
          <BoxContainer>
            <DropDownTitle disabled={disabled}>
              {defaultValue || placeholder}
            </DropDownTitle>
            {!isOpen ? (
              <DropDownArrowDown disabled={disabled} />
            ) : (
              <DropDownArrowUp disabled={disabled} />
            )}
          </BoxContainer>
        </DropDownHeader>
      </DropDownWrapper>
      {isOpen && (
        <DropDownListContainer>
          {!disabled && options?.length > 0 && (
            <DropDownList>
              {map(options, (option, idx) => (
                <ListItem
                  key={`option-${idx}`}
                  onClick={() => {
                    handleClick({
                      value: option?.value || option,
                    });
                    setIsOpen(false);
                  }}
                >
                  {option?.value || option}
                </ListItem>
              ))}
            </DropDownList>
          )}
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

export default Dropdown;
