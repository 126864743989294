import React, { useRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import ColumnChart from './chart/ColumnChart';
import { useDashboardContext } from '../../../contexts';

import 'dayjs/locale/th';

dayjs.extend(buddhistEra);

const Container = styled.div`
  padding: 0 16px 24px;
`;
const RowChart = styled.div`
  display: flex;
  justify-content: center;
`;
const ChartLine = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;

  .apexcharts-tooltip {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .apexcharts-yaxis {
    display: none;
  }
`;
const BoxTabY = styled.div`
  display: flex;
  height: 260px;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 19px;
`;
const TextFieldTabY = styled.span`
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.25px;
  margin-bottom: 25px;
  color: #39a1ac;
`;
const RowGuide = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
`;
const GuideColumn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;

  span {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: #2b2b2b;
  }
`;
const IconColumn = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${props => props?.color && props?.color};
  border-radius: 4px;
  margin-right: 12px;
`;
const BoxDetail = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #06a3b4;
    margin-left: 4px;
  }
`;
const TextFieldDate = styled.span`
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #5f646f;
  border-bottom: 0.67px solid #cccccc;
  padding: 0 0 4px;
  margin-top: 16px;
`;
const IconTitle = styled.img`
  width: 22px;
`;
const RowValue = styled.div`
  margin-top: 8px;
  span {
    font-size: 16px;
    letter-spacing: 0.25px;
    color: #2b2b2b;
  }
`;
const BoxValue = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 5px;

  span {
    font-size: 16px;
    color: #2b2b2b;

    :first-child {
      ${props => props?.isWarning && `color: #F65B5B;`}
    }
  }
`;

export default function OxygenValueChart() {
  const scroller = useRef(null);
  const { symptomTracker } = useDashboardContext();
  const [oxgenValueDate, setOxgenValueDate] = useState([]);
  const [otherDetail, setOtherDetail] = useState([]);
  const [oxygenValueBySitting, setOxygenValueBySitting] = useState([]);
  const [oxygenValueByWorking, setOxygenValueByWorking] = useState([]);

  useEffect(() => {
    if (symptomTracker?.length > 0) {
      const date = [];
      const measured = [];
      const sitting = [];
      const working = [];
      symptomTracker.map(data => {
        date.push(dayjs(data?.dateRecord).locale('th').format('DD MMM'));
        measured.push(parseFloat(data?.levelCough));
        sitting.push(data?.oxygenValueBySitting);
        working.push(data?.oxygenValueByWorking);
      });

      setOtherDetail(symptomTracker[symptomTracker?.length - 1]);
      setOxgenValueDate(date);
      setOxygenValueBySitting(sitting);
      setOxygenValueByWorking(working);
    }
  }, [symptomTracker]);

  useEffect(() => {
    if (!isEmpty(symptomTracker)) {
      setTimeout(() => {
        scroller.current.scrollLeft += 1600;
      }, 300);
    }
  }, [symptomTracker]);

  const handleClickViewDetail = (event, chartContext, config) => {
    const detail = symptomTracker[config?.dataPointIndex];
    const currentScrollLeft = scroller.current.scrollLeft;

    setOtherDetail(detail);
    scroller.current.scrollLeft += currentScrollLeft;
  };

  return (
    <Container>
      <RowChart>
        <ChartLine ref={scroller} id="chart-line">
          <ColumnChart
            sitting={oxygenValueBySitting}
            working={oxygenValueByWorking}
            categories={oxgenValueDate}
            handleClickViewDetail={handleClickViewDetail}
          />
        </ChartLine>
        <BoxTabY>
          <TextFieldTabY>100%</TextFieldTabY>
          <TextFieldTabY>95%</TextFieldTabY>
          <TextFieldTabY>90%</TextFieldTabY>
          <TextFieldTabY>85%</TextFieldTabY>
          <TextFieldTabY>80%</TextFieldTabY>
          <TextFieldTabY>75%</TextFieldTabY>
        </BoxTabY>
      </RowChart>
      <RowGuide>
        <GuideColumn>
          <IconColumn color="#81B829" />
          <span>ขณะนั่งพัก</span>
        </GuideColumn>
        <GuideColumn>
          <IconColumn color="#FFA159" />
          <span>หลังกิจกรรม</span>
        </GuideColumn>
      </RowGuide>
      {!isEmpty(otherDetail) && (
        <BoxDetail>
          <TextFieldDate>{`วันที่ ${dayjs(otherDetail?.dateRecord)
            .locale('th')
            .format('DD MMMM BBBB')}`}</TextFieldDate>
          <Row>
            <IconTitle src="/icons/icon-sitting.svg" />
            <span>ค่าออกซิเจนปลายนิ้ว : ขณะนั่งพัก</span>
          </Row>
          <BoxValue>
            <span>{`${otherDetail?.oxygenValueBySitting || '-'}%`}</span>
            <span>{`ออกซิเจน : ${
              otherDetail?.oxygenContentBySitting &&
              otherDetail?.oxygenContentBySitting !== 'NULL'
                ? `ใช้ ${otherDetail?.oxygenContentBySitting || '-'}  ลิตร/นาที`
                : 'ไม่ใช้'
            }`}</span>
          </BoxValue>
          <Row>
            <IconTitle src="/icons/icon-walking.svg" />
            <span>ค่าออกซิเจนปลายนิ้ว : หลังกิจกรรม</span>
          </Row>
          <BoxValue>
            <span>{`${otherDetail?.oxygenValueByWorking || '-'}%`}</span>
            <span>{`ออกซิเจน : ${
              otherDetail?.oxygenContentByWorking &&
              otherDetail?.oxygenContentByWorking !== 'NULL'
                ? `ใช้ ${otherDetail?.oxygenContentByWorking || '-'} ลิตร/นาที`
                : 'ไม่ใช้'
            }`}</span>
          </BoxValue>
          <Row>
            <IconTitle src="/icons/icon-other-symptom.svg" />
            <span>อาการอื่นๆ</span>
          </Row>
          <RowValue>
            <span>{`${
              otherDetail?.otherSymptomsRemark &&
              otherDetail?.otherSymptomsRemark !== 'NULL'
                ? otherDetail?.otherSymptomsRemark
                : '-'
            }`}</span>
          </RowValue>
          <Row>
            <IconTitle src="/icons/icon-fvc.svg" />
            <span>FVC</span>
          </Row>
          <RowValue>
            <span>{`${
              otherDetail?.litreFVC && otherDetail?.percentFVC
              ? `BEST : ${otherDetail?.litreFVC} ลิตร : %PRED : ${otherDetail?.percentFVC}%`
              : 'ไม่ได้วัด'
            }`}</span>
          </RowValue>
          <Row>
            <IconTitle src="/icons/icon-solid-pill.svg" />
            <span>ยาที่ใช้รักษา</span>
          </Row>
          <BoxValue>
            {otherDetail.medicineChangedReason?
              <span style={{ color: "#F65B5B" }} > เปลี่ยน </span>
              :
              <span> ไม่เปลี่ยน </span>
            }
            <span>{`${otherDetail.medicineChangedReason || ''}`}</span>
          </BoxValue>
        </BoxDetail>
      )}
    </Container>
  );
}
