import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useCMSContext } from '../../contexts';
import Input from '../Form/Input';
import ModalChangeCompleted from './components/ModalChangeCompleted';

export default function ChangePasswordContainer() {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isChangeCompleted, setIsChangeCompleted] = useState(false);

  const { CMSAccount, CMSAction } = useCMSContext();

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const [errorMessages, isErrorMessages] = useState({});

  const oldPassword = watch('oldPassword') || '';
  const password = watch('password') || '';
  const confirmPassword = watch('confirmPassword') || '';

  useEffect(() => {
    if (oldPassword === '' || password === '' || confirmPassword === '') {
      if (oldPassword === '') {
        isErrorMessages({
          oldPassword: '',
        });
      } else if (confirmPassword === '') {
        isErrorMessages({
          confirmPassword: '',
        });
      }
    }

    if (oldPassword !== '' && oldPassword !== CMSAccount?.password) {
      isErrorMessages({
        oldPassword: 'รหัสผ่านเดิมไม่ถูกต้อง',
      });
    } else if (
      password !== '' &&
      confirmPassword !== '' &&
      password !== confirmPassword
    ) {
      isErrorMessages({
        confirmPassword: 'ยืนยันรหัสผ่านใหม่ไม่ถูกต้อง',
      });
    } else {
      isErrorMessages({});
    }
  }, [oldPassword, password, confirmPassword]);

  const handleSubmitToChangePassword = async data => {
    const response = await CMSAction.handleChangePassword(data);

    if (response?.status === 200 || response?.status === 204) {
      setIsChangeCompleted(true);
    }
  };

  return (
    <Container>
      <BoxChangePassword>
        <FormWrapper onSubmit={handleSubmit(handleSubmitToChangePassword)}>
          <TextFieldChangePasswordTitle>
            เปลี่ยนรหัสผ่าน
          </TextFieldChangePasswordTitle>
          <BoxInput>
            <Input
              classInputContainer={`input-old-password ${
                !isEmpty(errorMessages?.oldPassword) ? 'isError' : ''
              }`}
              type={showOldPassword ? 'text' : 'password'}
              name="oldPassword"
              label="รหัสผ่านเดิม"
              register={register}
              setValue={setValue}
              defaultValue={oldPassword || ''}
              maxLength={70}
              errorMessage={errorMessages?.oldPassword || ''}
            />
            <IconEyeInput
              src={`/icons/${
                showOldPassword
                  ? 'icon-open-password.svg'
                  : 'icon-close-password.svg'
              }`}
              alt="icon eye password"
              onClick={() => setShowOldPassword(!showOldPassword)}
            />
          </BoxInput>
          <BoxInput>
            <Input
              classInputContainer={`input-password ${
                !isEmpty(errorMessages?.password) ? 'isError' : ''
              }`}
              type={showPassword ? 'text' : 'password'}
              name="password"
              label="รหัสผ่าน"
              register={register}
              setValue={setValue}
              defaultValue={password || ''}
              maxLength={70}
              errorMessage={errorMessages?.password || ''}
            />
            <IconEyeInput
              src={`/icons/${
                showPassword
                  ? 'icon-open-password.svg'
                  : 'icon-close-password.svg'
              }`}
              alt="icon eye password"
              onClick={() => setShowPassword(!showPassword)}
            />
          </BoxInput>
          <BoxInput>
            <Input
              classInputContainer={`input-confirm-password ${
                !isEmpty(errorMessages?.confirmPassword) ? 'isError' : ''
              }`}
              type={showConfirmPassword ? 'text' : 'password'}
              name="confirmPassword"
              label="ยืนยันรหัสผ่าน"
              register={register}
              setValue={setValue}
              defaultValue={confirmPassword || ''}
              maxLength={70}
              errorMessage={errorMessages?.confirmPassword || ''}
            />
            <IconEyeInput
              src={`/icons/${
                showConfirmPassword
                  ? 'icon-open-password.svg'
                  : 'icon-close-password.svg'
              }`}
              alt="icon eye password"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            />
          </BoxInput>
          <ButtonLogin
            disabled={
              oldPassword === '' ||
              password === '' ||
              confirmPassword === '' ||
              !isEmpty(errorMessages)
            }
          >
            ยืนยัน
          </ButtonLogin>
        </FormWrapper>
      </BoxChangePassword>
      <ModalChangeCompleted isOpen={isChangeCompleted} />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: auto;
  bottom: 0px;
  top: 72px;
  left: 0;
  position: absolute;
`;
const BoxChangePassword = styled.div`
  width: 394px;
  height: 418px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 20px -1px rgba(113, 131, 178, 0.1);
  border-radius: 20px;
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -200px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
`;
const TextFieldChangePasswordTitle = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #39a1ac;
  margin-bottom: 30px;
`;
const FormWrapper = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;

  .input-old-password {
    margin-bottom: 44px;

    &.isError {
      margin-bottom: 20px;
    }
  }

  .input-password {
    margin-bottom: 28px;

    &.isError {
      margin-bottom: 4px;
    }
  }

  .input-confirm-password {
    margin-bottom: 34px;

    &.isError {
      margin-bottom: 10px;
    }
  }

  .remove-text {
    display: none;
  }
`;
const ButtonLogin = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 314px;
  height: 38px;
  background: #06a3b4;
  opacity: ${props => (props?.disabled ? 0.5 : 1)};
  border-radius: 24px;
  border: none;

  // NOTE: Text button
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
`;
const BoxInput = styled.div`
  position: relative;
`;
const IconEyeInput = styled.img`
  width: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
`;
