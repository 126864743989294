import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

export default function NoListAppointmentForthComing({ tab }) {
  const router = useHistory();

  return (
    <Container>
      <TextField>
        {tab === 1 ? (
          <React.Fragment>
            <span>คุณไม่มีนัดหมาย</span>
            <br />
            <span>ที่กำลังจะมาถึง</span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span>คุณไม่มี</span>
            <br />
            <span>ประวัติการนัดหมาย</span>
          </React.Fragment>
        )}
      </TextField>
      {tab === 1 && (
        <BoxAddAppointment onClick={() => router.push('/appointment/create')}>
          <IconAddAppointment src="/icons/icon-plus.svg" />
          <span>เพิ่มตารางนัดหมาย</span>
        </BoxAddAppointment>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TextField = styled.span`
  color: #06a3b4;
  opacity: 0.4;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.25px;
  margin-top: 135px;
`;
const BoxAddAppointment = styled.button`
  width: 197px;
  height: 40px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  span {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #06a3b4;
  }
`;
const IconAddAppointment = styled.img`
  width: 24px;
  margin-right: 10px;
`;
