import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Radio from '../Form/Radio';
import { useSymptomTrackerContext } from '../../contexts';

export default function FVCTrackerContainer({ activity }) {
  const { isFVCChecked, isFVCValid, litreFVC, percentFVC, symptomTrackerAction, } = useSymptomTrackerContext();

  useEffect(() => {
    let validateFVC = {
      litreFVC: (0 <= Cookies?.get('litreFVC') && Cookies?.get('litreFVC') <= 9.99),
      percentFVC: (0 <= Cookies?.get('percentFVC') && Cookies?.get('percentFVC') <= 100)
    };
    symptomTrackerAction.setIsFVCValid(validateFVC);

  }, []);

  const isBlank = (val) => {
    return isNaN(val) || val === '';
  }

  const handleLitreFVCBlur = e => {
    const value = parseFloat(e.target.value.replace(/^(\d+.?\d{0,2})\d*$/, '$1'));
    e.target.value = value.toFixed(2);
    Cookies.set('litreFVC', e.target.value);
  }
  const handleLitreFVCKeyUp = e => {
    if (e.target.type === 'number' && e.target.min && e.target.max) {
      const value = parseFloat(e.target.value.replace(/^(\d+.?\d{0,2})\d*$/, '$1'));
      const max = parseFloat(e.target.max);
      const min = parseFloat(e.target.min);

    
      if (value > max || value < min) {
        symptomTrackerAction.setIsFVCValid({...isFVCValid, litreFVC: false});
      }
      else {
        symptomTrackerAction.setIsFVCValid({...isFVCValid, litreFVC: true});
      }

      symptomTrackerAction.setLitreFVC(value);
      Cookies.set('litreFVC', value); 
    }
  };

  const handlePercentFVCKeyUp = e => {
    if (e.target.type === 'number' && e.target.min && e.target.max) {
      e.target.value = parseFloat(e.target.value);
      const value = parseFloat(e.target.value);
      const min = parseFloat(e.target.min);
      const max = parseFloat(e.target.max);

      if (e.target.value <= 0 && e.target.value !== '') {
        e.target.value = 0;
      }

      if (value > max || value < min) {
        symptomTrackerAction.setIsFVCValid({...isFVCValid, percentFVC: false});
      }
      else {
        symptomTrackerAction.setIsFVCValid({...isFVCValid, percentFVC: true});
      }

      symptomTrackerAction.setPercentFVC(e.target.value);
      Cookies.set('percentFVC', e.target.value);
    }
  };

  return (
    <Container>
      <BoxBody>
        <TextFieldQuestion>
          ค่า <span className="active">FVC</span> สัปดาห์นี้
        </TextFieldQuestion>
        
        <ImageOxygenValue
          src="/images/FVC.png"
          alt="img FVC"
        />

        <BoxInformation>
          <span>คุณได้วัดค่า FVC หรือไม่?</span>
          <BoxUseOxygen>
            <RowUseOxygen>
              <Radio
                name="fvcChecked"
                label="ไม่ได้วัด"
                checked={isFVCChecked === false}
                onChange={() =>
                  symptomTrackerAction.handleClickFVCChecked(false)
                }
              />
              <Radio
                name="fvcChecked"
                label="ได้วัด"
                checked={isFVCChecked === true}
                onChange={() =>
                  symptomTrackerAction.handleClickFVCChecked(true)
                }
              />
            </RowUseOxygen>
          </BoxUseOxygen>

          {isFVCChecked && (
            <React.Fragment>
              <span>ค่า FVC ของคุณคือ ?</span> <br/>
              <span>ควรกรอกโดยทีมแพทย์/พยาบาล</span>
              <BoxInputInformation>
                <RowInput>
                  <BoxTextInput
                    isError={ !isBlank(litreFVC) && !isFVCValid.litreFVC }
                  >
                    <InputOxygenContent
                      isError={ !isBlank(litreFVC) && !isFVCValid.litreFVC }
                      type="number"
                      inputmode="decimal"
                      name="litreFVC"
                      placeholder="BEST (0.00)"
                      defaultValue={litreFVC}
                      step="any"
                      min={0}
                      max={9.99}
                      onKeyUp={e => handleLitreFVCKeyUp(e)}
                      onBlur={e => handleLitreFVCBlur(e)}
                    />
                    { !isBlank(litreFVC) && !isFVCValid.litreFVC &&
                      <span>ค่า BEST</span>
                    }
                  </BoxTextInput>
                  <LabelOxygenValue>
                    <span>ลิตร</span>
                  </LabelOxygenValue>
                </RowInput>
                { !isBlank(litreFVC) && !isFVCValid.litreFVC &&
                  <span className="error-message">
                    กรุณาระบุค่าในระหว่าง 0.00 - 9.99
                  </span>
                }
              </BoxInputInformation>
              <BoxInputInformation>
                <RowInput>
                  <BoxTextInput
                    isError={ !isBlank(percentFVC) && !isFVCValid.percentFVC }
                  >
                    <InputOxygenContent
                      isError={ !isBlank(percentFVC) && !isFVCValid.percentFVC }
                      type="number"
                      inputmode="decimal"
                      name="percentFVC"
                      placeholder="PRED (0-100)"
                      defaultValue={percentFVC}
                      step="any"
                      min={0}
                      max={100}
                      onKeyUp={e => handlePercentFVCKeyUp(e,)}
                    />
                    { !isBlank(percentFVC) && !isFVCValid.percentFVC &&
                      <span>ค่า PRED</span>
                    }
                  </BoxTextInput>
                  <LabelOxygenValue>
                    <span>  %</span>
                  </LabelOxygenValue>
                </RowInput>
                { !isBlank(percentFVC) && !isFVCValid.percentFVC &&
                  <span className="error-message">
                    กรุณาระบุค่าในระหว่าง 0 - 100
                  </span>
                }
              </BoxInputInformation>
            </React.Fragment>
          )}
        </BoxInformation>
      </BoxBody>
    </Container>
  );
}

const Container = styled.div`
  display: block;
  position: relative;
  margin-bottom: 100px;
`;
const BoxBody = styled.div`
  display: block;
  text-align: center;
  padding: 0 27px;
`;
const TextFieldQuestion = styled.div`
  width: 100%;
  height: 64px;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #2b2b2b;
  margin-top: 21px;

  .active {
    color: #39a1ac;
  }
`;
const ImageOxygenValue = styled.img`

`;
const BoxInformation = styled.div`
  display: block;
  text-align: center;
  margin-top: 16px;

  span {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #2b2b2b;
    margin-bottom: 13px;
  }
`;
const BoxInputInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  margin-bottom: 26px;

  .error-message {
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.25px;
    color: #f65b5b;
  }
`;
const RowInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BoxTextInput = styled.div`
  position: relative;

  span {
    color: #f65b5b;
    background-color: #f8fbfc;
    font-weight: 300;
    font-size: 12px;
    position: absolute;
    top: -9px;
    left: 22px;
    padding: 0 2px;
  }
`;
const InputOxygenContent = styled.input`
  width: 155px;
  height: 44px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #a9b7da;
  padding: 10px;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #475985;
  outline: none;

  ${props => props?.isError && `border: 1px solid #f65b5b;`}

  ::placeholder {
    color: #475985;
  }
`;
const LabelOxygenValue = styled.div`
  span {
    display: inline-block;
    width: 16px;
    height: 18px;
    color: #39a1ac;
    font-weight: 600;
    font-size: 14px;
    margin-left: 8px;
    margin-top: 12px;
  }
`;
const RowUseOxygen = styled.div`
  display: block;
  margin-top: 24px;
  margin-bottom: 20px;
  padding: 0 40px;

  label {
    margin-bottom: 16px;
  }
`;
const BoxUseOxygen = styled.div`
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
`;
