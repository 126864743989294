import React from 'react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { useCMSContext } from '../../../contexts/CMSContext';
import Loader from '../../Animation/Loader';

export default function DashboardTableList({ isLoaded }) {
  const { CMSExport, CMSReports, CMSAction } = useCMSContext();

  const handleClickExport = data => {
    CMSAction.handleExportExcel(data);
  };

  return (
    !isEmpty(CMSReports) && (
      <TableListContainer>
        {!isLoaded ? (
          <Loader />
        ) : (
          <>
            <TableHeader>
              <RowHeader width="160px" padding="17px 32px">
                จังหวัด
              </RowHeader>
              <RowHeader width="187px">โรงพยาบาล</RowHeader>
              <RowHeader width="243px">
                โรคพังผืดในปอดที่ได้รับการวินิจฉัย
              </RowHeader>
              <RowHeader width="117px">จำนวนผู้ใช้งาน</RowHeader>
              <RowHeader width="113px">สร้างล่าสุด</RowHeader>
              <RowHeader textAlign="center">ไฟล์</RowHeader>
            </TableHeader>
            {CMSReports?.count > 0 ? (
              <TableContent>
                <RowDataTable width="160px" padding="17px 32px">
                  {CMSReports?.province}
                </RowDataTable>
                <RowDataTable width="187px">
                  {CMSReports?.hospital}
                </RowDataTable>
                <RowDataTable width="243px">{CMSReports?.ild}</RowDataTable>
                <RowDataTable width="117px">{CMSReports?.count}</RowDataTable>
                <RowDataTable width="113px">{CMSReports?.date}</RowDataTable>
                <RowDataTable justifyContent="center">
                  <ButtonExport onClick={() => handleClickExport(CMSExport)}>
                    ดาวน์โหลด (.xlsx)
                  </ButtonExport>
                </RowDataTable>
              </TableContent>
            ) : (
              <NoDataTable>ไม่พบข้อมูล</NoDataTable>
            )}
          </>
        )}
      </TableListContainer>
    )
  );
}

const TableListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const TableHeader = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  background: #e7f6f8;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #2b2b2b;
  font-weight: 600;
  border-bottom: 1px solid #39a1ac;
`;
const RowHeader = styled.div`
  flex: ${props => (props.width ? `${props.width} 0` : 1)};
  padding: ${props => (props.padding ? `${props.padding}` : `17px 10px`)};
  ${props => props.textAlign && `text-align: center;`}
  ${props => props.width && `max-width: ${props.width}`}
`;
const TableContent = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  background: #ffffff;
`;
const NoDataTable = styled.div`
  width: 100%;
  height: 72px;
  background: #f1f2f3;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const RowDataTable = styled.div`
  display: flex;
  flex: ${props => (props.width ? `${props.width} 0` : 1)};
  padding: ${props => (props.padding ? `${props.padding}` : `17px 10px`)};
  ${props => props.textAlign && `text-align: center;`};
  ${props => props.width && `max-width: ${props.width}`};
  ${props =>
    props.justifyContent && `justify-content: ${props.justifyContent}`};
`;
const ButtonExport = styled.button`
  width: 143px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #06a3b4;
  box-sizing: border-box;
  border-radius: 24px;
  cursor: pointer;
  background-color: #ffffff;

  color: #06a3b4;
  font-weight: bold;
  letter-spacing: 0.25px;
  font-size: 14px;
`;
