import React, { createContext, useContext, useState } from 'react';
import concat from 'lodash/concat';
import Cookies from 'js-cookie';

export const AppointmentContext = createContext();

export function useAppointmentContext() {
  return useContext(AppointmentContext);
}

export function AppointmentProvider({ children }) {
  const [comingAppointments, setComingAppointments] = useState([]);
  const [currentComingPage, setCurrentComingPage] = useState(0);
  const [pastAppointments, setPastAppointments] = useState([]);
  const [currentPastPage, setCurrentPastPage] = useState(0);
  const [isAppointmentCompleted, setIsAppointmentCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const CreateAppointment = async data => {
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: Cookies.get('userId'),
        lineId: Cookies.get('lineId'),
        appointmentDate:
          `${data?.dateAppointment} ${data?.timeAppointment}` || '',
        appointmentRemark: data?.appointmentNote || '',
      }),
    };

    try {
      const fetchResponse = await fetch(
        `${process.env.REACT_APP_HOST}/appointments`,
        settings,
      );
      if (fetchResponse?.status === 200) {
        const data = await fetchResponse.json();

        setIsAppointmentCompleted(true);
        return data;
      }
    } catch (e) {
      console.log({ e: e?.message });
      return e;
    }
  };

  const fetchAppointment = async (type, page, needReload = false) => {
    setIsLoading(true);

    const userId = Cookies.get('userId');
    const settings = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-user-id': userId,
      },
    };

    let res = await (
      await fetch(
        `${process.env.REACT_APP_HOST}/appointments/${type}/${page}`,
        settings,
      )
    ).json();

    if (res) {
      if (needReload) {
        if (type === 'coming') {
          setComingAppointments(res);
          setCurrentPastPage(0);
        }
      } else {
        if (type === 'coming') {
          setComingAppointments(concat(comingAppointments, res));
        } else {
          setPastAppointments(concat(pastAppointments, res));
        }
      }
      setIsLoading(false);
    }
  };

  const deleteAppointment = async id => {
    const settings = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      const fetchResponse = await fetch(
        `${process.env.REACT_APP_HOST}/appointments/${id}`,
        settings,
      );

      if (fetchResponse?.status === 200 || fetchResponse?.status === 204) {
        const data = await fetchResponse.json();

        return { status: data?.status };
      }
    } catch (e) {
      console.error({ e: e?.message });
      return e;
    }
  };

  const values = {
    isLoading,
    comingAppointments,
    currentComingPage,
    pastAppointments,
    currentPastPage,
    isAppointmentCompleted,
    AppointmentAction: {
      CreateAppointment,
      fetchAppointment,
      setCurrentComingPage,
      setCurrentPastPage,
      deleteAppointment,
    },
  };

  return (
    <AppointmentContext.Provider value={values}>
      {children}
    </AppointmentContext.Provider>
  );
}

export default AppointmentProvider;
