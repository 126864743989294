import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { useDashboardContext } from '../../../contexts';
import LineChart from './chart/LineChart';

import 'dayjs/locale/th';

dayjs.extend(buddhistEra);

const Container = styled.div`
  padding: 0 16px 24px;
`;
const RowChart = styled.div`
  display: flex;
  justify-content: center;
`;
const ChartLine = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;

  .apexcharts-tooltip {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .apexcharts-yaxis {
    display: none;
  }
`;
const BoxTabY = styled.div`
  display: flex;
  height: 260px;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 17px;
`;
const TextFieldTabY = styled.span`
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.25px;
  margin-bottom: 19px;
  color: ${props => props?.color && props?.color};
`;
const TextFieldDate = styled.span`
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #5f646f;
  border-bottom: 0.67px solid #cccccc;
  padding: 0 0 4px;
  margin-top: 16px;
`;
const BoxDetail = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #06a3b4;
    margin-left: 4px;
  }
`;
const IconTitle = styled.img`
  width: 22px;
`;
const RowValue = styled.div`
  margin-top: 8px;
  span {
    font-size: 16px;
    letter-spacing: 0.25px;
    color: #2b2b2b;
  }
`;

export default function CoughTrackerChart() {
  const scroller = useRef(null);
  const { symptomTracker } = useDashboardContext();
  const [coughDate, setCoughTracker] = useState([]);
  const [measuredValue, setMeasuredValue] = useState([]);
  const [coughDetail, setCoughDetail] = useState([]);

  useEffect(() => {
    if (symptomTracker?.length > 0) {
      const date = [];
      const measured = [];
      symptomTracker.map(data => {
        date.push(dayjs(data?.dateRecord).locale('th').format('DD MMM'));
        measured.push(parseFloat(data?.levelCough));
      });

      setCoughDetail(symptomTracker[symptomTracker?.length - 1]);
      setCoughTracker(date);
      setMeasuredValue(measured);
    }
  }, [symptomTracker]);

  useEffect(() => {
    if (!isEmpty(symptomTracker)) {
      setTimeout(() => {
        scroller.current.scrollLeft += 1600;
      }, 300);
    }
  }, [symptomTracker]);

  const handleClickViewDetail = (event, chartContext, config) => {
    const detail = symptomTracker[config?.dataPointIndex];
    const currentScrollLeft = scroller.current.scrollLeft;

    setCoughDetail(detail);
    scroller.current.scrollLeft += currentScrollLeft;
  };

  return (
    <Container>
      {!isEmpty(coughDate) && (
        <RowChart>
          <ChartLine ref={scroller} id="chart-line">
            <LineChart
              categories={coughDate}
              value={measuredValue}
              handleClickViewDetail={handleClickViewDetail}
            />
          </ChartLine>
          <BoxTabY>
            <TextFieldTabY color="#CC3300">10</TextFieldTabY>
            <TextFieldTabY color="#FF9966">8</TextFieldTabY>
            <TextFieldTabY color="#FFCC00">6</TextFieldTabY>
            <TextFieldTabY color="#FFCC00">4</TextFieldTabY>
            <TextFieldTabY color="#99CC33">2</TextFieldTabY>
            <TextFieldTabY color="#339900">0</TextFieldTabY>
          </BoxTabY>
        </RowChart>
      )}
      {!isEmpty(coughDetail) && (
        <BoxDetail>
          <TextFieldDate>{`วันที่ ${dayjs(coughDetail?.dateRecord)
            .locale('th')
            .format('DD MMMM BBBB')}`}</TextFieldDate>
          <Row>
            <IconTitle src="/icons/icon-cough.svg" />
            <span>อาการไอ</span>
          </Row>
          <RowValue>
            <span>{`${coughDetail?.levelCough || '-'} คะแนน`}</span>
          </RowValue>
          <Row>
            <IconTitle src="/icons/icon-other-symptom.svg" />
            <span>อาการอื่นๆ</span>
          </Row>
          <RowValue>
            <span>{`${
              coughDetail?.otherSymptomsRemark &&
              coughDetail?.otherSymptomsRemark !== 'NULL'
                ? coughDetail?.otherSymptomsRemark
                : '-'
            }`}</span>
          </RowValue>
        </BoxDetail>
      )}
    </Container>
  );
}
