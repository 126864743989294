import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ModalDelete from '../../components/Modals/ModalDelete';
import FullpageLoading from '../../components/Modals/FullpageLoading';
import ModalSuccess from '../../components/Modals/ModalSuccess';
import Cookies from 'js-cookie';

const BoxContainer = styled.div`
  padding: 28px 24px;
`;
const BoxHeader = styled.div`
  display: flex;
  align-items: center;
`;
const TextTitle = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #06a3b4;
`;
const BoxBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`;
const ImageWarning = styled.img`
  width: 80px;
  margin-bottom: 21.31px;
`;
const ImageArrowLeft = styled.img`
  width: 32px;
`;
const TextTitleContent = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #f65b5b;
  margin-bottom: 8px;
`;
const TextDescriptionContent = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #393e47;
  margin-bottom: 24px;
`;
const ButtonDelete = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 240px;
  height: 40px;
  background: #f65b5b;
  border: none;
  border-radius: 24px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
`;

function ProfileDeleteAccount() {
  const router = useHistory();
  const userId = Cookies.get('userId');
  const [isOpen, setIsOpen] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [isDeleteComplete, setIsDeleteComplete] = useState(false);

  const handleBack = () => {
    router.goBack();
  };

  const handleClickSubmit = async data => {
    setLoaded(false);
    setIsOpen(false);

    const settings = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      const fetchResponse = await fetch(
        `${process.env.REACT_APP_HOST}/users/${userId}`,
        settings,
      );

      if (fetchResponse?.status === 200 || fetchResponse?.status === 204) {
        setLoaded(true);
        setIsDeleteComplete(true);
        setTimeout(async () => {
          const liff = (await import('@line/liff')).default;
          liff.closeWindow();
        }, 1000);

        return data;
      }
    } catch (e) {
      console.log({ e: e?.message });
      setLoaded(true);
      return e;
    }
  };

  const handleClickCancel = () => {
    setIsOpen(false);
  };

  return (
    <BoxContainer>
      <BoxHeader>
        <ImageArrowLeft
          onClick={() => handleBack()}
          src="/icons/arrow-left-b.svg"
        />
        <TextTitle>ลบบัญชี</TextTitle>
      </BoxHeader>
      <BoxBody>
        <ImageWarning src="/icons/alert-twotone.svg" />
        <TextTitleContent>ต้องการลบบัญชี?</TextTitleContent>
        <TextDescriptionContent>
          ข้อมูลทั้งหมด รวมถึงข้อมูลสุขภาพปอด ของคุณ จะถูกลบออกจากระบบ
          คุณแน่ใจหรือไม่ว่าต้องการที่จะลบบัญชี?
        </TextDescriptionContent>
        <ButtonDelete onClick={() => setIsOpen(true)}>ลบบัญชี</ButtonDelete>
        <ModalDelete
          isOpen={isOpen}
          handleClickCancel={handleClickCancel}
          handleClickSubmit={handleClickSubmit}
        />
        <FullpageLoading loaded={loaded} />
        <ModalSuccess isOpen={isDeleteComplete} />
      </BoxBody>
    </BoxContainer>
  );
}

export default ProfileDeleteAccount;
