import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const IconSuccess = styled.img`
  max-width: 40px;
`;
const TextLabel = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #393e47;
  margin-top: 7px;
`;

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 20,
  },

  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '112px',
    backgroundColor: '#f1f1f1',
    borderRadius: '24px',
  },
};

export default function ModalSuccess({ isOpen, closeModal }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <Container>
        <IconSuccess src="/icons/success.svg" />
        <TextLabel>สำเร็จ</TextLabel>
      </Container>
    </Modal>
  );
}
