import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function LineChart({
  categories,
  value,
  handleClickViewDetail,
}) {
  const charts = {
    options: {
      chart: {
        group: 'social',
        type: 'line',
        width: '100%',
        height: 350,
        toolbar: {
          autoSelected: 'pan',
          show: false,
        },
        zoom: {
          enabled: false,
        },
        events: {
          markerClick: handleClickViewDetail,
        },
      },
      annotations: {
        yaxis: [
          {
            y: 5,
            borderColor: '#A1D8DF',
            label: {
              borderColor: '#A1D8DF',
              style: {
                color: '#fff',
                background: '#A1D8DF',
              },
              text: '',
            },
          },
        ],
      },
      legend: {
        show: false,
      },
      yaxis: {
        show: true, // NOTE: เปิด / ปิด แกน Y
        min: 0, // NOTE: ค่าเริ่มต้นแกน Y
        max: 10, // NOTE: ค่าสิ้นสุดแกน Y
        tickAmount: 5, // NOTE: จำนวนที่โชว์ในแกน Y
      },
      xaxis: {
        categories: categories,
        labels: {
          show: true,
          style: {
            colors: '#9196A1',
            fontSize: '12px',
            cssClass: 'apexcharts-xaxis-label',
          },
        },
      },
      markers: {
        size: [6], // NOTE: ขนาดของจุดกลมๆในกราฟ EX.[เส้นที่1, เส้นที่2]
      },
      stroke: {
        width: 3,
        curve: 'straight',
        dashArray: [0], // NOTE: ตั้งค่าเส้นกราฟ 0 คือเส้นทึบปกติ 3 แบบไข่ปลาระยะห่าง 3
      },
      colors: ['#06A3B4'],
      dataLabels: {
        enabled: true,
        offsetY: -6,
        style: {
          fontSize: '16px',
          fontWeight: 'normal',
        },
        background: {
          enabled: false,
        },
      },
      grid: {
        show: true,
        borderColor: '#E7F6F8',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        row: {
          colors: undefined,
          opacity: 1,
        },
        column: {
          colors: undefined,
          opacity: 0.5,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    },
    series: [
      {
        name: 'ค่าที่วัดได้',
        data: value,
      },
    ],
  };

  const widthChart = categories?.length < 7 ? 325 : categories?.length * 55;

  return (
    <ReactApexChart
      options={charts?.options}
      series={charts?.series}
      type="line"
      width={widthChart}
      height={281}
    />
  );
}
