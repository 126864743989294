import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import Dropdown from '../Form/Dropdown';
import CheckBox from '../Form/CheckBox';
import { useMedicalInfoContext } from '../../contexts/MedicalInfoContext';
import { useCMSContext } from '../../contexts/CMSContext';
import DashboardTableList from './components/DashboardTableList';

export default function CMSDashboardContainer() {
  const { hospitalNameOptions, provinceList, ildOptions, MedicalInfoAction } =
    useMedicalInfoContext();
  const { CMSExport, CMSAction } = useCMSContext();
  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoaded, setIsLoaded] = useState(false);

  const province = watch('province') || 'ทั้งหมด';
  const hospital = watch('hospital') || 'ทั้งหมด';
  const ild = watch('ild') || 'ทั้งหมด';
  const minAge = watch('minAge');
  const maxAge = watch('maxAge');
  const male = watch('male') || false;
  const female = watch('female') || false;

  useEffect(() => {
    if (!isLoaded) {
      setTimeout(() => {
        setIsLoaded(true);
      }, 1000);
    }
  }, [isLoaded]);

  const handleClickOptionProvince = ({ value }) => {
    setValue('province', value);
    MedicalInfoAction.fetchHospitalOptions({ suggestionProvince: value });
  };

  const handleClickOptionHospital = ({ value }) => {
    setValue('hospital', value);
  };

  const handleClickOptionIld = ({ value }) => {
    setValue('ild', value);
  };

  const handleFilterMale = () => {
    setValue('male', !male);
  };

  const handleFilterFemale = () => {
    setValue('female', !female);
  };

  const handleClickFilter = data => {
    setIsLoaded(false);
    CMSAction.handleFilterReport(data);
  };

  return (
    <CMSContainer>
      <CMSTitle>รายงานสรุป</CMSTitle>
      <CMSDescription>ข้อมูลสรุปของผู้ป่วย</CMSDescription>
      <FormWrapper onSubmit={handleSubmit(handleClickFilter)}>
        <BoxFilter>
          <BoxDropdown>
            <RowDropdown>
              <Dropdown
                classNameTextLabel="dropdown-province"
                label="เลือกจังหวัด"
                defaultValue={province}
                handleClick={handleClickOptionProvince}
                options={concat(['ทั้งหมด'], provinceList)}
              />
            </RowDropdown>
            <RowDropdown>
              <Dropdown
                classNameTextLabel="dropdown-hospital"
                label="เลือกโรงพยาบาล"
                defaultValue={hospital}
                handleClick={handleClickOptionHospital}
                options={concat(['ทั้งหมด'], hospitalNameOptions)}
              />
            </RowDropdown>
            <RowDropdown>
              <Dropdown
                classNameTextLabel="dropdown-ild"
                label="โรคพังผืดในปอดที่ได้รับการวินิจฉัย*"
                defaultValue={ild}
                handleClick={handleClickOptionIld}
                options={concat(['ทั้งหมด'], ildOptions)}
              />
            </RowDropdown>
          </BoxDropdown>
          <BoxFilterInput>
            <BoxInput>
              <GroupInput>
                <TextFieldTitleInput>อายุ</TextFieldTitleInput>
                <RowInput>
                  <input
                    type="text"
                    placeholder="0"
                    {...register('minAge')}
                    autoComplete="off"
                  />
                  <span>-</span>
                  <input
                    type="text"
                    placeholder="100"
                    {...register('maxAge')}
                    autoComplete="off"
                  />
                </RowInput>
              </GroupInput>
              <GroupInput>
                <TextFieldTitleInput>เพศ</TextFieldTitleInput>
                <RowCheckbox>
                  <CheckBox
                    classWrapper="checkbox-gender-male"
                    label="ชาย"
                    defaultValue={male}
                    onChange={() => handleFilterMale()}
                    checked={male}
                  />
                  <CheckBox
                    classWrapper="checkbox-gender-female"
                    label="หญิง"
                    defaultValue={female}
                    onChange={() => handleFilterFemale()}
                    checked={female}
                  />
                </RowCheckbox>
              </GroupInput>
            </BoxInput>
            <BoxButton>
              <GroupButtonFilter>
                <ButtonFilter
                  disabled={
                    isEmpty(province) &&
                    isEmpty(hospital) &&
                    isEmpty(ild) &&
                    isEmpty(minAge) &&
                    isEmpty(maxAge) &&
                    !male &&
                    !female
                  }
                >
                  ตกลง
                </ButtonFilter>
              </GroupButtonFilter>
            </BoxButton>
          </BoxFilterInput>
        </BoxFilter>
      </FormWrapper>
      <DashboardTableList isLoaded={isLoaded} />
    </CMSContainer>
  );
}

const CMSContainer = styled.div`
  max-width: 1062px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
`;
const CMSTitle = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #06a3b4;
  margin-bottom: 8px;
`;
const CMSDescription = styled.div`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #9b9b9b;
  margin-bottom: 24px;
`;
const BoxFilter = styled.div`
  height: 218px;
  background: #ffffff;
  box-shadow: 0px 4px 20px -1px rgba(113, 131, 178, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;
const BoxDropdown = styled.div`
  display: flex;
  justify-content: space-between;
`;
const RowDropdown = styled.div`
  flex: 1;
  padding: 0 16px;
`;
const BoxFilterInput = styled.div`
  display: flex;
  align-items: center;
`;
const BoxInput = styled.div`
  flex: 1;
  display: flex;
`;
const BoxButton = styled.div`
  flex: 1;
`;
const TextFieldTitleInput = styled.span`
  min-height: 22px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #2b2b2b;
  margin-bottom: 8px;
`;
const RowInput = styled.div`
  display: flex;
  align-items: center;

  input {
    width: 76px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #a9b7da;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px;

    ::placeholder {
      color: #2b2b2b;
      opacity: 0.5;
    }
  }

  span {
    margin: 0 10px;
  }
`;
const GroupInput = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

const RowCheckbox = styled.div`
  height: 44px;
  display: flex;
  align-items: center;

  label {
    font-size: 16px;
    letter-spacing: 0.25px;
    color: #2b2b2b;
    margin-right: 41px;
  }
`;
const FormWrapper = styled.form`
  display: block;
  margin-bottom: 25px;
`;
const GroupButtonFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
`;
const ButtonFilterAll = styled.span`
  width: 140px;
  height: 40px;
  cursor: pointer;
  letter-spacing: 0.25px;
  color: #06a3b4;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;
const ButtonFilter = styled.button`
  width: 140px;
  height: 40px;
  background: #06a3b4;
  border-radius: 24px;
  letter-spacing: 0.25px;
  font-weight: bold;
  font-size: 14px;
  border: none;
  color: #ffffff;
  cursor: pointer;

  ${props => props.disabled && `opacity: 0.5;`}
`;
