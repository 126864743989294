import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import DyspneaTrackerContainer from '../../components/SymptomTracker/DyspneaTrackerContainer';
import CoughTrackerContainer from '../../components/SymptomTracker/CoughTrackerContainer';
import OtherSymptomsContainer from '../../components/SymptomTracker/OtherSymptomsContainer';
import OxygenValueAtFingertipsContainer from '../../components/SymptomTracker/OxygenValueAtFingertipsContainer';
import FVCTrackerContainer from '../../components/SymptomTracker/FVCTrackerContainer';
import MedicineTrackerContainer from '../../components/SymptomTracker/MedicineTrackerContainer';
import { useSymptomTrackerContext } from '../../contexts';

export default function SymptomTrackerContainer({ step }) {
  const router = useHistory();
  const {
    lvDyspnea,
    lvCough,
    isOtherSymptoms,
    otherSymptomsRemark,
    useOxygenationToolsBySitting,
    oxygenContentBySitting,
    useOxygenationToolsByWorking,
    oxygenContentByWorking,
    errorOxygenBySitting,
    errorOxygenByWorking,
    isFVCChecked,
    isFVCValid,
    litreFVC,
    percentFVC,
    isMedicineChanged,
    medicineChangedReason,
  } = useSymptomTrackerContext();

  const handleNextStep = () => {
    if (step < 7) {
      router.replace(`/symptom/tracker/${step ? parseInt(step) + 1 : 1}`);
    }
  };

  const handleGotoSummary = () => {
    if (step === 7) {
      router.replace('/symptom/summary');
    }
  };

  const handleBackStep = () => {
    router.replace(`/symptom/tracker/${step ? parseInt(step) - 1 : 1}`);
  };

  let activeNextStep = false;
  if (step === 1) {
    activeNextStep = !isNaN(lvDyspnea);
  } else if (step === 2) {
    activeNextStep = !isNaN(lvCough);
  } else if (step === 3) {
    activeNextStep =
      (isOtherSymptoms && !isEmpty(otherSymptomsRemark)) ||
      isOtherSymptoms === false;
  } else if (step === 4) {
    activeNextStep =
      !errorOxygenBySitting?.oxygenValue &&
      !errorOxygenBySitting?.oxygenContent &&
      ((useOxygenationToolsBySitting && !isEmpty(oxygenContentBySitting)) ||
        useOxygenationToolsBySitting === false);
  } else if (step === 5) {
    activeNextStep =
      !errorOxygenByWorking?.oxygenValue &&
      !errorOxygenByWorking?.oxygenContent &&
      ((useOxygenationToolsByWorking && !isEmpty(oxygenContentByWorking)) ||
        useOxygenationToolsByWorking === false);
  } else if (step === 6) {
    activeNextStep = (isFVCChecked &&
       (isFVCValid.litreFVC && !isNaN(litreFVC) && isFVCValid.percentFVC && !isNaN(percentFVC)) || 
       isFVCChecked === false);
  } else if (step === 7) {
    activeNextStep = (isMedicineChanged && !isEmpty(medicineChangedReason)) || isMedicineChanged === false;
  }

  return (
    <Container>
      {step === 1 && <DyspneaTrackerContainer />}
      {step === 2 && <CoughTrackerContainer />}
      {step === 3 && <OtherSymptomsContainer />}
      {step === 4 && <OxygenValueAtFingertipsContainer activity="Sitting" />}
      {step === 5 && <OxygenValueAtFingertipsContainer activity="Working" />}
      {step === 6 && <FVCTrackerContainer />}
      {step === 7 && <MedicineTrackerContainer />}
      <BoxFooter>
        {step > 1 && (
          <ButtonBackStep onClick={() => handleBackStep()}>
            <IconBack src="/icons/back.svg" alt="back step" />
            <ButtonLabel>ก่อนหน้า</ButtonLabel>
          </ButtonBackStep>
        )}
        <TextFieldStep>
          <ActiveStep>{step < 10 ? `0${step}` : step}</ActiveStep> / 07
        </TextFieldStep>
        {step < 7 ? (
          <ButtonNextStep
            isAction={activeNextStep}
            onClick={() => activeNextStep && handleNextStep()}
          >
            <ButtonLabel>ถัดไป</ButtonLabel>
            <IconNext src="/icons/next.svg" alt="next step" />
          </ButtonNextStep>
        ) : (
          <ButtonNextStep
            isGotoSummary
            isAction={activeNextStep}
            onClick={() => activeNextStep && handleGotoSummary()}
          >
            <ButtonLabel>สรุปผล</ButtonLabel>
            <IconNext src="/icons/white-next.svg" alt="next step" />
          </ButtonNextStep>
        )}
      </BoxFooter>
    </Container>
  );
}

const Container = styled.div``;
const BoxFooter = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0;
  left: 0;
  justify-content: center;
  background-color: #f8fbfc;
  border-top: 1px solid #e7f6f8;
`;
const TextFieldStep = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #a1d8df;
  margin-top: 16px;
`;
const ActiveStep = styled.span`
  color: #2b2b2b;
`;
const ButtonNextStep = styled.button`
  height: 40px;
  display: flex;
  background-color: transparent;
  border: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  right: 16px;
  top: 7px;
  padding: 0;
  color: #39a1ac;

  ${props => !props?.isAction && `opacity: 0.5;`}

  ${props =>
    props?.isGotoSummary &&
    `
    width: 92px;
    border-radius: 33px;
    background-color: #06A3B4;
    color: #FFFFFF;
  `}
`;
const ButtonLabel = styled.span`
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.25px;
`;
const IconNext = styled.img`
  width: 32px;
`;
const ButtonBackStep = styled.button`
  height: 40px;
  display: flex;
  background-color: transparent;
  border: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 16px;
  top: 7px;
  padding: 0;
  color: #39a1ac;
`;
const IconBack = styled.img`
  width: 32px;
`;
