import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PersonalInformationContainer from '../../components/Consent/components/PersonalInformationContainer';

const Container = styled.div`
  padding: 32px 24px;
`;
const TitleDataConsent = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: flex-start;
  letter-spacing: 0.25px;
  color: #06a3b4;
  margin-bottom: 16px;
`;
const IconBackArrow = styled.img`
  margin-right: 8px;
`;

export default function ProfilePersonalInformation() {
  const router = useHistory();
  const handleBack = () => {
    router.goBack();
  };
  return (
    <Container>
      <TitleDataConsent>
        <IconBackArrow
          onClick={() => handleBack()}
          src="/icons/arrow-left-b.svg"
        />
        แบบฟอร์มการให้ความยินยอม เกี่ยวกับข้อมูลส่วนบุคคล
      </TitleDataConsent>
      <PersonalInformationContainer />
    </Container>
  );
}
