import React, { useState } from 'react';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import localeTH from 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { checkLevelSymptom } from '../../utils/checkLevelSymptom';
import { useSymptomTrackerContext } from '../../contexts';
import ModalConfirmCancelRecord from './components/ModalConfirmCancelRecord';
import Cookies from 'js-cookie';
import ModalCompletedRecord from './components/ModalCompletedRecord';

dayjs.extend(buddhistEra);

const Container = styled.div`
  display: block;
  padding: 0 16px;
`;
const BoxTitle = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TitleSummary = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: #06a3b4;
  letter-spacing: 0.25px;
`;
const BoxBody = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 120px;
`;
const TextFieldDate = styled.span`
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #5f646f;
`;
const Line = styled.div`
  width: 100%;
  height: 0.67px;
  margin-top: 8px;
  background-color: #cccccc;
`;
const BoxSummaryContainer = styled.div`
  display: block;
  padding: 16px 2px 0;
`;
const RowSummary = styled.div`
  display: block;
  margin-bottom: 20px;

  :last-child {
    margin-bottom: 0;
  }
`;
const BoxTopic = styled.div`
  display: flex;
  align-items: center;
`;
const TextField = styled.span`
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #06a3b4;
`;
const IconTopic = styled.img`
  width: 22px;
  margin-right: 4px;
`;
const BoxValue = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 5px;
  overflow-wrap: break-word;

  span {
    font-size: 16px;
    color: #2b2b2b;

    :first-child {
      ${props => props?.isWarning && `color: #F65B5B;`}
    }
  }
`;
const BoxFooter = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 128px;
  bottom: 0;
  left: 0;
  background-color: #f8fbfc;
  border-top: 1px solid #e7f6f8;
  align-items: center;
  flex-direction: column;
  padding: 6px;
`;
const ButtonFooterCancel = styled.button`
  width: 240px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  color: #06a3b4;
  background-color: transparent;
  border-radius: 24px;
  border: 2px solid #39a1ac;
  margin-bottom: 16px;
`;
const ButtonFooterSave = styled.button`
  width: 240px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  color: #06a3b4;
  background-color: #06a3b4;
  border-radius: 24px;
  color: #ffffff;
  border: none;
`;

export default function SummaryRecordsContainer() {
  const router = useHistory();
  const {
    lvDyspnea,
    lvCough,
    otherSymptomsRemark,
    oxygenValueBySitting,
    oxygenContentBySitting,
    oxygenValueByWorking,
    oxygenContentByWorking,
    symptomCompleted,
    symptomTrackerAction,
    litreFVC,
    percentFVC,
    isMedicineChanged,
    medicineChangedReason,
  } = useSymptomTrackerContext();
  const [closeRecord, isCloseRecord] = useState(false);

  const handleIsShowPopup = () => {
    isCloseRecord(!closeRecord);
  };

  const clearAllCookiesSymptomRecord = () => {
    Cookies.remove('levelDyspnea');
    Cookies.remove('levelCough');
    Cookies.remove('oxygenValueBySitting');
    Cookies.remove('oxygenContentBySitting');
    Cookies.remove('oxygenValueByWorking');
    Cookies.remove('oxygenContentByWorking');
    Cookies.remove('otherSymptomsRemark');
    Cookies.remove('otherSymptoms');
    Cookies.remove('useOxygenationToolsBySitting');
    Cookies.remove('useOxygenationToolsByWorking');
    Cookies.remove('fvcChecked');
    Cookies.remove('percentFVC');
    Cookies.remove('litreFVC');
    Cookies.remove('medicineChanged');
    Cookies.remove('medicineChangedReason');
  };

  const handleGotoDashboard = () => {
    clearAllCookiesSymptomRecord();
    router.replace('/profile/dashboard');
  };

  const handleConfirmCloseRecord = async () => {
    clearAllCookiesSymptomRecord();
    const liff = (await import('@line/liff')).default;
    liff.closeWindow();
  };

  let dateRecord = dayjs().day(6);
  if (dayjs().format('ddd') === 'Sat') {
    dateRecord = dayjs().day(6);
  } else if (dayjs().format('ddd') === 'Sun') {
    dateRecord = dayjs().day(-1);
  } else if (dayjs().format('ddd') === 'Mon') {
    dateRecord = dayjs().day(-1);
  }

  return (
    <Container>
      <BoxTitle>
        <TitleSummary>สรุป บันทึกติดตามอาการ</TitleSummary>
      </BoxTitle>
      <BoxBody>
        <TextFieldDate>{`วันที่ ${
          !isEmpty(dateRecord)
            ? dateRecord.locale(localeTH).format('DD MMMM BBBB')
            : '-'
        }`}</TextFieldDate>
        <Line />
        <BoxSummaryContainer>
          <RowSummary>
            <BoxTopic>
              <IconTopic src="/icons/icon-dyspnea.svg" alt="icon dyspnea" />
              <TextField>อาการหอบเหนื่อย</TextField>
            </BoxTopic>
            <BoxValue>
              <span>{`${lvDyspnea} คะแนน (${checkLevelSymptom(
                parseFloat(lvDyspnea),
              )})`}</span>
            </BoxValue>
          </RowSummary>
          <RowSummary>
            <BoxTopic>
              <IconTopic src="/icons/icon-cough.svg" alt="icon cough" />
              <TextField>อาการไอ</TextField>
            </BoxTopic>
            <BoxValue>
              <span>{`${lvCough} คะแนน`}</span>
            </BoxValue>
          </RowSummary>
          <RowSummary>
            <BoxTopic>
              <IconTopic
                src="/icons/icon-other-symptom.svg"
                alt="icon other symptom"
              />
              <TextField>อาการอื่นๆ</TextField>
            </BoxTopic>
            <BoxValue>
              <span>{`${otherSymptomsRemark || ''}`}</span>
            </BoxValue>
          </RowSummary>
          <RowSummary>
            <BoxTopic>
              <IconTopic src="/icons/icon-sitting.svg" alt="icon sitting" />
              <TextField>ค่าออกซิเจนปลายนิ้ว : ขณะนั่งพัก</TextField>
            </BoxTopic>
            <BoxValue isWarning={oxygenValueBySitting <= 88}>
              <span>
                {oxygenValueBySitting ? `${oxygenValueBySitting || '-'}%` : '-'}
              </span>
              <span>{`ออกซิเจน : ${
                oxygenContentBySitting
                  ? `ใช้ ${oxygenContentBySitting || '-'}  ลิตร/นาที`
                  : 'ไม่ใช้'
              }`}</span>
            </BoxValue>
          </RowSummary>
          <RowSummary>
            <BoxTopic>
              <IconTopic src="/icons/icon-walking.svg" alt="icon walking" />
              <TextField>ค่าออกซิเจนปลายนิ้ว : หลังกิจกรรม</TextField>
            </BoxTopic>
            <BoxValue isWarning={oxygenValueByWorking <= 88}>
              <span>
                {oxygenValueByWorking ? `${oxygenValueByWorking}%` : '-'}
              </span>
              <span>{`ออกซิเจน : ${
                oxygenContentByWorking
                  ? `ใช้ ${oxygenContentByWorking || '-'} ลิตร/นาที`
                  : 'ไม่ใช้'
              }`}</span>
            </BoxValue>
          </RowSummary>
          <RowSummary>
            <BoxTopic>
              <IconTopic src="/icons/icon-fvc.svg" alt="icon FVC" />
              <TextField>ค่า FVC</TextField>
            </BoxTopic>
            <BoxValue>
              <span>{ (litreFVC && percentFVC)
                        ? `BEST : ${litreFVC} ลิตร : %PRED : ${percentFVC}%`
                        : 'ไม่ได้วัด'
                    }
              </span>
            </BoxValue>
          </RowSummary>
          <RowSummary>
            <BoxTopic>
              <IconTopic src="/icons/icon-solid-pill.svg" alt="icon solid pill" />
              <TextField>ยาที่ใช้รักษา</TextField>
            </BoxTopic>
            <BoxValue>
              {isMedicineChanged? 
                <span style={{ color: "#F65B5B" }} > เปลี่ยน </span>
                :
                <span> ไม่เปลี่ยน </span>
              }
              <span>{`${medicineChangedReason || ''}`}</span>
            </BoxValue>
          </RowSummary>
        </BoxSummaryContainer>
      </BoxBody>
      <BoxFooter>
        <ButtonFooterCancel onClick={() => handleIsShowPopup()}>
          ยกเลิก
        </ButtonFooterCancel>
        <ButtonFooterSave
          onClick={() => symptomTrackerAction.handleSaveSymptomTracker()}
        >
          บันทึกผล
        </ButtonFooterSave>
      </BoxFooter>

      <ModalConfirmCancelRecord
        isOpen={closeRecord}
        handleClosePopup={handleIsShowPopup}
        handleClick={handleConfirmCloseRecord}
      />

      <ModalCompletedRecord
        isOpen={symptomCompleted}
        handleClick={handleGotoDashboard}
        handleClosePopup={handleConfirmCloseRecord}
      />
    </Container>
  );
}
