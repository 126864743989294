import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import head from 'lodash/head';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localeTH from 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import {
  useProfileContext,
  useDashboardContext,
  useAppointmentContext,
} from '../../contexts';
import CoughTrackerChart from './components/CoughTrackerChart';
import DyspneaTrackerChart from './components/DyspneaTrackerChart';
import OxygenValueChart from './components/OxygenValueChart';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(buddhistEra);

export default function DashboardContainer() {
  const { customer } = useProfileContext();
  const { dashboardAction } = useDashboardContext();
  const { comingAppointments, AppointmentAction } = useAppointmentContext();
  const [nextAppointment, setNextAppointment] = useState({});
  const [isActiveTab, setIsActiveTab] = useState(1);

  useEffect(() => {
    if (comingAppointments?.length <= 0) {
      AppointmentAction.fetchAppointment('coming', 0);
    }
  }, []);

  useEffect(() => {
    if (comingAppointments?.length > 0) {
      setNextAppointment(head(comingAppointments));
    }
  }, [comingAppointments]);

  const handleClickTab = tab => {
    setIsActiveTab(tab);
  };

  return (
    <Container>
      <BoxMedicalInformation>
        <TextFieldName>
          {`${customer?.firstname || ''} ${customer?.lastname || ''}`}
        </TextFieldName>
        <TextFieldIld>{customer?.ildId}</TextFieldIld>
        <Line />
        <TextFieldAppointment>นัดแพทย์ที่กำลังมาถึง</TextFieldAppointment>
        <BoxTopic>
          <span>วันที่</span>
          <span>เวลา</span>
        </BoxTopic>
        <BoxValue>
          <span>
            {nextAppointment?.appointmentDate
              ? dayjs(nextAppointment?.appointmentDate)
                  .tz('Asia/Bangkok')
                  .locale(localeTH)
                  .format('dd, DD MMM BBBB')
                  .replace('.', '')
              : '-'}
          </span>
          <span>
            {nextAppointment?.appointmentDate
              ? dayjs(nextAppointment?.appointmentDate)
                  .tz('Asia/Bangkok')
                  .locale(localeTH)
                  .format('HH:mm')
              : '-'}
          </span>
        </BoxValue>
      </BoxMedicalInformation>
      <BoxHealthGraph>
        <BoxHeaderHealthGraph>
          <span>กราฟสุขภาพ</span>
          <ButtonExportPDF onClick={() => dashboardAction.exportPDF()}>
            <IconExportPDF src="/icons/icon-export.svg" />
            <span>ดาวน์โหลด</span>
          </ButtonExportPDF>
        </BoxHeaderHealthGraph>
        <BoxRemark>
          <span>โปรดแคปหน้าจอและกดดาวน์โหลดเพื่อส่งรายงานให้แพทย์</span>
        </BoxRemark>
        <BoxTabGraph>
          <TabGraph
            isActive={isActiveTab === 1}
            onClick={() => handleClickTab(1)}
          >
            หอบเหนื่อย
          </TabGraph>
          <TabGraph
            isActive={isActiveTab === 2}
            onClick={() => handleClickTab(2)}
          >
            อาการไอ
          </TabGraph>
          <TabGraph
            isActive={isActiveTab === 3}
            onClick={() => handleClickTab(3)}
          >
            ออกซิเจน
          </TabGraph>
        </BoxTabGraph>
        <BoxGraphContainer>
          {isActiveTab === 1 && <DyspneaTrackerChart />}
          {isActiveTab === 2 && <CoughTrackerChart />}
          {isActiveTab === 3 && <OxygenValueChart />}
        </BoxGraphContainer>
      </BoxHealthGraph>
    </Container>
  );
}

const Container = styled.div`
  display: block;
`;
const BoxMedicalInformation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 2px;
`;
const TextFieldName = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #2b2b2b;
`;
const TextFieldIld = styled.span`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #9196a1;
`;
const Line = styled.div`
  width: 100%;
  height: 0.67px;
  margin-top: 8px;
  background-color: #e3e5e8;
`;
const TextFieldAppointment = styled.span`
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #2b2b2b;
  margin-top: 12px;
`;
const BoxTopic = styled.div`
  display: flex;
  margin-top: 8px;

  span {
    flex: 1;
    font-size: 16px;
    letter-spacing: 0.25px;
    color: #9196a1;
  }
`;
const BoxValue = styled.div`
  display: flex;
  margin-top: 4px;

  span {
    flex: 1;
    font-size: 16px;
    letter-spacing: 0.25px;
    color: #2b2b2b;
  }
`;
const BoxHealthGraph = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BoxHeaderHealthGraph = styled.div`
  width: 100%;
  height: 72px;
  background: #ffffff;
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.25px;
    color: #2b2b2b;
  }
`;
const ButtonExportPDF = styled.button`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #9196a1;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  span {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.25px;
  }
`;
const IconExportPDF = styled.img`
  margin-right: 10px;
`;
const BoxTabGraph = styled.div`
  width: 100%;
  height: 31px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e3e5e8;
  background: #ffffff;
`;
const TabGraph = styled.button`
  flex: 1;
  background: #ffffff;
  border: none;
  border-bottom: ${props =>
    props?.isActive ? '4px solid #06a3b4' : '4px solid #ffffff'};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${props => (props?.isActive ? '#06A3B4' : '#9196a1')};
`;
const BoxGraphContainer = styled.div`
  display: block;
  width: 100%;
`;
const BoxRemark = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  span {
    color: #9196a1;
    text-align: center;
    letter-spacing: 0.25px;
    font-size: 13px;
    padding: 0px 5px;
    background-color: #f7f7f8;
    border-radius: 2px;
    margin: 0 0px 24px;
  }
`;