import React, { createContext, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);

export const SymptomTrackerContext = createContext();

export function useSymptomTrackerContext() {
  return useContext(SymptomTrackerContext);
}

export function SymptomTrackerProvider({ children }) {
  const [symptomCompleted, isSymptomCompleted] = useState(false);
  const [lvDyspnea, setLvDyspnea] = useState(NaN);
  const [lvCough, setLvCough] = useState(NaN);
  const [isOtherSymptoms, setIsOtherSymptoms] = useState('');
  const [otherSymptomsRemark, setOtherSymptomsRemark] = useState('');
  const [oxygenValueBySitting, setOxygenValueBySitting] = useState('');
  const [oxygenContentBySitting, setOxygenContentBySitting] = useState('');
  const [useOxygenationToolsBySitting, setUseOxygenationToolsBySitting] =
    useState('');
  const [oxygenValueByWorking, setOxygenValueByWorking] = useState('');
  const [oxygenContentByWorking, setOxygenContentByWorking] = useState('');
  const [useOxygenationToolsByWorking, setUseOxygenationToolsByWorking] =
    useState('');
  const [errorOxygenBySitting, setErrorOxygenBySitting] = useState({
    oxygenValue: false,
    oxygenContent: false,
  });
  const [errorOxygenByWorking, setErrorOxygenByWorking] = useState({
    oxygenValue: false,
    oxygenContent: false,
  });

  const [isFVCChecked, setIsFVCChecked] = useState('');
  const [isFVCValid, setIsFVCValid] = useState({percentFVC: true, litreFVC: true});
  const [litreFVC, setLitreFVC] = useState(NaN);
  const [percentFVC, setPercentFVC] = useState(NaN);

  const [isMedicineChanged, setIsMedicineChanged] = useState('');
  const [medicineChangedReason, setMedicineChangedReason] = useState(''); 

  useEffect(() => {
    let errOxygenValueSitting = {};

    if (!isEmpty(Cookies?.get('oxygenValueBySitting'))) {
      if (
        Cookies?.get('oxygenValueBySitting') < 75 ||
        Cookies?.get('oxygenValueBySitting') > 100
      ) {
        errOxygenValueSitting = {
          ...errOxygenValueSitting,
          oxygenValue: true,
        };
      }
    }

    if (Cookies?.get('oxygenContentBySitting') > 99) {
      errOxygenValueSitting = {
        ...errOxygenValueSitting,
        oxygenContent: true,
      };
    }

    setErrorOxygenBySitting(errOxygenValueSitting);

    let errOxygenValueWorking = {};
    if (!isEmpty(Cookies?.get('oxygenValueByWorking'))) {
      if (
        Cookies?.get('oxygenValueByWorking') < 75 ||
        Cookies?.get('oxygenValueByWorking') > 100
      ) {
        errOxygenValueWorking = {
          ...errOxygenValueWorking,
          oxygenValue: true,
        };
      }
    }
    if (Cookies?.get('oxygenContentByWorking') > 99) {
      errOxygenValueWorking = {
        ...errOxygenValueWorking,
        oxygenContent: true,
      };
    }
    setErrorOxygenByWorking(errOxygenValueWorking);

    setLvDyspnea(parseFloat(Cookies?.get('levelDyspnea')));
    setLvCough(parseFloat(Cookies?.get('levelCough')));
    setOxygenValueBySitting(Cookies?.get('oxygenValueBySitting'));
    setOxygenContentBySitting(Cookies?.get('oxygenContentBySitting'));
    setOxygenValueByWorking(Cookies.get('oxygenValueByWorking'));
    setOxygenContentByWorking(Cookies.get('oxygenContentByWorking'));
    setOtherSymptomsRemark(Cookies.get('otherSymptomsRemark'));
    setLitreFVC(Cookies.get('litreFVC'));
    setPercentFVC(Cookies.get('percentFVC'));
    setMedicineChangedReason(Cookies.get('medicineChangedReason'));

    if (!isEmpty(Cookies?.get('fvcChecked'))) {
      setIsFVCChecked(Cookies?.get('fvcChecked') === 'true');
    }

    if (!isEmpty(Cookies?.get('medicineChanged'))) {
      setIsMedicineChanged(Cookies?.get('medicineChanged') === 'true');
    }

    if (!isEmpty(Cookies?.get('otherSymptoms'))) {
      setIsOtherSymptoms(Cookies?.get('otherSymptoms') === 'true');
    }

    if (!isEmpty(Cookies?.get('useOxygenationToolsBySitting'))) {
      setUseOxygenationToolsBySitting(
        Cookies?.get('useOxygenationToolsBySitting') === 'true',
      );
    }

    if (!isEmpty(Cookies?.get('useOxygenationToolsByWorking'))) {
      setUseOxygenationToolsByWorking(
        Cookies?.get('useOxygenationToolsByWorking') === 'true',
      );
    }

    let validateFVC = {
      litreFVC: (0 <= Cookies?.get('litreFVC') && Cookies?.get('litreFVC') <= 9.99),
      percentFVC: (0 <= Cookies?.get('percentFVC') && Cookies?.get('percentFVC') <= 100)
    };
    setIsFVCValid(validateFVC);  
  }, []);

  const handleDyspneaIncreaseScore = (levelDyspnea, action) => {
    if (isNaN(levelDyspnea) && action === 'increase') {
      levelDyspnea = 0;
    } else if (levelDyspnea < 10 && action === 'increase') {
      if (levelDyspnea === 0) {
        levelDyspnea = 0.5;
      } else if (levelDyspnea === 0.5) {
        levelDyspnea = 1;
      } else {
        levelDyspnea = levelDyspnea + 1;
      }
    } else if (levelDyspnea > 0 && action === 'decrease') {
      if (levelDyspnea === 1) {
        levelDyspnea = 0.5;
      } else if (levelDyspnea === 0.5) {
        levelDyspnea = 0;
      } else {
        levelDyspnea = levelDyspnea - 1;
      }
    }

    if (isNaN(levelDyspnea) || (levelDyspnea >= 0 && levelDyspnea <= 10)) {
      setLvDyspnea(levelDyspnea);
      Cookies.set('levelDyspnea', levelDyspnea);
    }
  };

  const handleClickOtherSymptom = val => {
    setIsOtherSymptoms(val);
    Cookies.set('otherSymptoms', val);

    if (val === false) {
      Cookies.remove('otherSymptomsRemark');
      setOtherSymptomsRemark('');
    }
  };

  const handleClickMedicineChange = val => {
    setIsMedicineChanged(val);
    Cookies.set('medicineChanged', val);

    if (val === false) {
      Cookies.remove('medicineChangedReason');
      setMedicineChangedReason('');
    }
  };

  const handleClickFVCChecked = val => {
    setIsFVCChecked(val)
    Cookies.set('fvcChecked', val);

    if (val === false) {
      Cookies.remove('litreFVC');
      Cookies.remove('percentFVC');
      setLitreFVC('');
      setPercentFVC('');
    }
  };  

  const handleCoughIncreaseScore = (levelCough, action) => {
    if (isNaN(levelCough) && action === 'increase') {
      levelCough = 0;
    } else if (levelCough < 10 && action === 'increase') {
      levelCough = levelCough + 1;
    } else if (levelCough > 0 && action === 'decrease') {
      levelCough = levelCough - 1;
    }

    if (isNaN(levelCough) || (levelCough >= 0 && levelCough <= 10)) {
      setLvCough(levelCough);
      Cookies.set('levelCough', levelCough);
    }
  };

  const handleClickUseOxygenationTools = (val, activity) => {
    Cookies.set(`useOxygenationToolsBy${activity}`, val);

    if (activity === 'Sitting') {
      setUseOxygenationToolsBySitting(val);

      if (val === false) {
        Cookies.remove('oxygenContentBySitting');
        setOxygenContentBySitting('');
      }
    }

    if (activity === 'Working') {
      setUseOxygenationToolsByWorking(val);

      if (val === false) {
        Cookies.remove(`oxygenContentByWorking`);
        setOxygenContentByWorking('');
      }
    }
  };

  const handleSaveSymptomTracker = async () => {
    let dateRecord = '';
    if (dayjs().format('ddd') === 'Sat') {
      dateRecord = dayjs().day(6).format('YYYY-MM-DD');
    } else if (dayjs().format('ddd') === 'Sun') {
      dateRecord = dayjs().day(-1).format('YYYY-MM-DD');
    } else if (dayjs().format('ddd') === 'Mon') {
      dateRecord = dayjs().day(-1).format('YYYY-MM-DD');
    }

    const userId = Cookies.get('userId');
    const lineId = Cookies.get('lineId');
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-user-id': userId,
        'x-line-id': lineId,
      },
      body: JSON.stringify({
        userId: userId,
        dateRecord: dateRecord,
        levelDyspnea: Cookies.get('levelDyspnea'),
        levelCough: Cookies.get('levelCough'),
        oxygenValueBySitting: !isEmpty(Cookies.get('oxygenValueBySitting'))
          ? Cookies.get('oxygenValueBySitting')
          : '0',
        oxygenContentBySitting: Cookies.get('oxygenContentBySitting') || 'NULL',
        oxygenValueByWorking: !isEmpty(Cookies.get('oxygenValueByWorking'))
          ? Cookies.get('oxygenValueByWorking')
          : '0',
        oxygenContentByWorking: Cookies.get('oxygenContentByWorking') || 'NULL',
        otherSymptomsRemark: Cookies.get('otherSymptomsRemark') || 'NULL',
        otherSymptoms: Cookies.get('otherSymptoms') === 'true',
        useOxygenBySitting:
          Cookies.get('useOxygenationToolsBySitting') === 'true',
        useOxygenByWorking:
          Cookies.get('useOxygenationToolsByWorking') === 'true',
        litreFVC: Cookies.get('litreFVC'),
        percentFVC: Cookies.get('percentFVC'),
        medicineChangedReason: Cookies.get('medicineChangedReason'),
      }),
    };

    try {
      const fetchResponse = await fetch(
        `${process.env.REACT_APP_HOST}/symptom-trackers`,
        settings,
      );
      if (fetchResponse?.status === 200) {
        const data = await fetchResponse.json();

        Cookies.remove('introduction');
        Cookies.remove('levelDyspnea');
        Cookies.remove('levelCough');
        Cookies.remove('oxygenValueBySitting');
        Cookies.remove('oxygenContentBySitting');
        Cookies.remove('oxygenValueByWorking');
        Cookies.remove('oxygenContentByWorking');
        Cookies.remove('otherSymptomsRemark');
        Cookies.remove('otherSymptoms');
        Cookies.remove('useOxygenationToolsBySitting');
        Cookies.remove('useOxygenationToolsByWorking');
        Cookies.remove('litreFVC');
        Cookies.remove('percentFVC');
        Cookies.remove('fvcChecked');
        Cookies.remove('medicineChanged');
        Cookies.remove('medicineChangedReason');

        isSymptomCompleted(true);
        return data;
      }
    } catch (e) {
      console.log({ e: e?.message });
      return e;
    }
  };

  const values = {
    lvDyspnea,
    lvCough,
    isOtherSymptoms,
    otherSymptomsRemark,
    oxygenValueBySitting,
    oxygenContentBySitting,
    useOxygenationToolsBySitting,
    oxygenValueByWorking,
    oxygenContentByWorking,
    useOxygenationToolsByWorking,
    symptomCompleted,
    errorOxygenBySitting,
    errorOxygenByWorking,
    isFVCChecked,
    isFVCValid,
    litreFVC,
    percentFVC,
    isMedicineChanged,
    medicineChangedReason,
    symptomTrackerAction: {
      handleDyspneaIncreaseScore,
      handleCoughIncreaseScore,
      handleClickOtherSymptom,
      setOtherSymptomsRemark,
      handleClickUseOxygenationTools,
      setOxygenValueBySitting,
      setOxygenContentBySitting,
      setOxygenValueByWorking,
      setOxygenContentByWorking,
      setErrorOxygenBySitting,
      setErrorOxygenByWorking,
      handleSaveSymptomTracker,
      setIsFVCValid,
      setLitreFVC,
      setPercentFVC,
      handleClickFVCChecked,
      setMedicineChangedReason,
      handleClickMedicineChange,
    },
  };

  return (
    <SymptomTrackerContext.Provider value={values}>
      {children}
    </SymptomTrackerContext.Provider>
  );
}

export default SymptomTrackerProvider;
