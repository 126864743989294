import React, { createContext, useContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localeTH from 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import map from 'lodash/map';
import Cookies from 'js-cookie';
import { excelReportTable } from '../utils/excelReport';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(buddhistEra);

export const CMSContext = createContext();

export function useCMSContext() {
  return useContext(CMSContext);
}

export function CMSProvider({ children }) {
  const [CMSAccount, setCMSAccount] = useState({});
  const [CMSExport, setCMSExport] = useState([]);
  const [CMSReports, setCMSReports] = useState({});

  useEffect(async () => {
    if (!isEmpty(Cookies.get('accountId'))) {
      fetchCMSAccount();
    }
  }, []);

  const fetchCMSAccount = async () => {
    const accountId = Cookies.get('accountId');
    const settings = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-id': accountId,
      },
    };

    try {
      const fetchResponse = await fetch(
        `${process.env.REACT_APP_HOST}/cms`,
        settings,
      );

      if (fetchResponse?.status === 200) {
        const data = await fetchResponse.json();

        setCMSAccount(data);
        return data;
      }
    } catch (e) {
      console.log({ e: e?.message });
      return e;
    }
  };

  const handleFilterReport = async data => {
    const accountId = Cookies.get('accountId');
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-id': accountId,
      },
      body: JSON.stringify({
        province:
          data?.province && data?.province !== 'ทั้งหมด' ? data?.province : '',
        hospital:
          data?.hospital && data?.hospital !== 'ทั้งหมด' ? data?.hospital : '',
        ild: data?.ild && data?.ild !== 'ทั้งหมด' ? data?.ild : '',
        minAge: data?.minAge || '',
        maxAge: data?.maxAge || '',
        male: data?.male || false,
        female: data?.female || false,
        all: data?.all || false,
      }),
    };

    try {
      const fetchResponse = await fetch(
        `${process.env.REACT_APP_HOST}/cms/report`,
        settings,
      );
      if (fetchResponse?.status === 200) {
        let response = await fetchResponse.json();
        response = orderBy(
          response,
          ['firstname', 'lastname', 'date_record'],
          ['asc', 'asc', 'desc'],
        );

        const count = uniqBy(response, 'user_id');

        const reportList = {
          province: data?.province || 'ทั้งหมด',
          hospital: data?.hospital || 'ทั้งหมด',
          ild: data?.ild || 'ทั้งหมด',
          count: count.length,
          date: dayjs()
            .tz('Asia/Bangkok')
            .locale(localeTH)
            .format('DD/MM/BBBB'),
        };

        setCMSExport({
          filter: {
            province: data?.province || '',
            hospital: data?.hospital || '',
            ild: data?.ild || '',
            minAge: data?.minAge || '',
            maxAge: data?.maxAge || '',
            male: data?.male || false,
            female: data?.female || false,
            all: data?.all || false,
          },
          export: response,
        });
        setCMSReports(reportList);

        return reportList;
      }
    } catch (e) {
      console.log({ e: e?.message });
      return e;
    }
  };

  const handleExportExcel = async data => {
    let userId = '';
    let isGray = '';

    let exportListData = orderBy(
      data?.export,
      ['firstname', 'lastname', 'date_record'],
      ['asc', 'asc', 'asc'],
    );

    const headerTable = excelReportTable(Cookies.get('role'));
    const exportData = map(exportListData, val => {
      if (userId === '' || userId !== val?.user_id) {
        if (isGray === '' || isGray === true) {
          isGray = false;
        } else {
          isGray = true;
        }
      }

      userId = val.user_id;

      return {
        id: val.user_id,
        date_record: val.date_record,
        date: dayjs(val?.date_record).tz('Asia/Bangkok').format('D-MMM-YY'),
        name: val?.firstname,
        surname: val?.lastname,
        hospital_no: val?.hospital_no,
        age:
          parseInt(dayjs().tz('Asia/Bangkok').format('BBBB')) -
          parseInt(val?.date_of_birth),
        gender: val?.gender,
        weight: val?.weight,
        province: val?.province,
        hospital: val?.hospital_name,
        disease: val?.ild_id,
        tired: val?.level_dyspnea !== 'NULL' ? val?.level_dyspnea : '-',
        isGray: isGray,
        cough: val?.level_cough !== 'NULL' ? val?.level_cough : '-',
        o2_status_sitdown:
          val?.oxygen_value_by_sitting !== 'NULL'
            ? val?.oxygen_value_by_sitting
            : '-',
        o2_used_sit_down:
          val?.oxygen_content_by_sitting !== 'NULL'
            ? val?.oxygen_content_by_sitting
            : '-',
        o2_status_standup:
          val?.oxygen_value_by_working !== 'NULL'
            ? val?.oxygen_value_by_working
            : '-',
        o2_used_standup:
          val?.oxygen_content_by_working !== 'NULL'
            ? val?.oxygen_content_by_working
            : '-',
        other:
          val?.other_symptoms_remark !== 'NULL'
            ? val?.other_symptoms_remark
            : '-',
        fvc_summarize: `${val?.litre_fvc ?? '-'} (${val?.percent_fvc ?? '-'})`,
        medicine: val?.medicine_changed_reason ?? '-',
        highlights: {
          o2_status_sitdown: val?.flag_warning_by_sitting,
          o2_used_sit_down: val?.flag_warning_by_sitting,
          o2_status_standup: val?.flag_warning_by_working,
          o2_used_standup: val?.flag_warning_by_working,
        },
      };
    });

    exportListData = orderBy(
      exportData,
      ['name', 'surname', 'date_record'],
      ['asc', 'asc', 'desc'],
    );

    if (Cookies.get('role') !== 'SuperAdmin') {
      delete exportData.name;
      delete exportData.lastname;
      delete exportData.personal_id;
    }

    const dataExport = {
      name: 'report',
      sheets: [
        {
          name: 'Report',
          titles: [
            { key: 'สรุปข้อมูลอาการผู้ป่วยโรคพังผืดในปอด', value: '' },
            { key: '', value: '' },
            {
              key: 'คัดกรองข้อมูลโดย',
              value: `จังหวัด${data?.filter?.province || 'ทั้งหมด'}`,
            },
            {
              key: '',
              value: `โรงพยาบาล${data?.filter?.hospital || 'ทั้งหมด'}`,
            },
            { key: '', value: `โรค${data?.filter?.ild || 'ทั้งหมด'}` },
            {
              key: '',
              value: `อายุ ${
                data?.filter?.minAge !== '' && data?.filter?.maxAge !== ''
                  ? `${data?.filter?.minAge} - ${data?.filter?.maxAge} ปี`
                  : data?.filter?.minAge !== '' && data?.filter?.maxAge === ''
                  ? `ตั้งแต่ ${data?.filter?.minAge} ปีขึ้นไป`
                  : data?.filter?.minAge === '' && data?.filter?.maxAge !== ''
                  ? `ไม่เกิน ${data?.filter?.maxAge} ปี`
                  : 'ทั้งหมด'
              }`,
            },
            {
              key: '',
              value: `เพศ ${
                data?.filter?.male && data?.filter?.female
                  ? `ชาย และ หญิง`
                  : data?.filter?.male && data?.filter?.female !== true
                  ? `ชาย`
                  : data?.filter?.male !== true && data?.filter?.female
                  ? `หญิง`
                  : 'ทั้งหมด'
              }`,
            },
          ],
          columns: headerTable,
          date: `${dayjs().tz('Asia/Bangkok').format('DD/MM/YYYY')}`,
          rows: exportListData,
        },
      ],
    };

    const accountId = Cookies.get('accountId');
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-id': accountId,
      },
      body: JSON.stringify(dataExport),
    };

    try {
      const fetchResponse = await fetch(
        `${process.env.REACT_APP_HOST}/cms/export`,
        settings,
      );
      if (fetchResponse?.status === 200) {
        let response = await fetchResponse.json();

        window.open(
          `${process.env.REACT_APP_HOST}/${response?.urlExport}`,
          '_blank',
        );
      }
    } catch (e) {
      console.log({ e: e?.message });
      return e;
    }
  };

  const handleChangePassword = async data => {
    const accountId = Cookies.get('accountId');
    const settings = {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-id': accountId,
      },
      body: JSON.stringify({
        password: data?.password || '',
      }),
    };

    try {
      const fetchResponse = await fetch(
        `${process.env.REACT_APP_HOST}/cms`,
        settings,
      );
      if (fetchResponse?.status === 200) {
        const data = await fetchResponse.json();
        return {
          status: fetchResponse?.status,
          data,
        };
      } else {
        return {
          status: fetchResponse?.status,
        };
      }
    } catch (e) {
      console.error({ e: e?.message });
      return e;
    }
  };

  const handleLogin = async data => {
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: data?.username || '',
        password: data?.password || '',
      }),
    };

    try {
      const fetchResponse = await fetch(
        `${process.env.REACT_APP_HOST}/cms/login`,
        settings,
      );
      if (fetchResponse?.status === 200) {
        const data = await fetchResponse.json();

        return data;
      }
    } catch (e) {
      console.log({ e: e?.message });
      return e;
    }
  };
  const values = {
    CMSAccount,
    CMSExport,
    CMSReports,
    CMSAction: {
      handleLogin,
      handleChangePassword,
      handleFilterReport,
      handleExportExcel,
    },
  };

  return <CMSContext.Provider value={values}>{children}</CMSContext.Provider>;
}

export default CMSProvider;
