export function excelReportTable(role) {
  let header = [];

  if (role === 'SuperAdmin') {
    header = [
      { name: 'ID', width: 10, key: 'id' },
      { name: 'Date', width: 12, key: 'date' },
      { name: 'Name', width: 13, key: 'name' },
      { name: 'Surname', width: 15, key: 'surname' },
      { name: 'H.N.', width: 15, key: 'hospital_no' },
      { name: 'Age', width: 5, key: 'age' },
      { name: 'Gender', width: 10, key: 'gender' },
      { name: 'Weight', width: 10, key: 'weight' },
      { name: 'Province', width: 15, key: 'province' },
      { name: 'Hospital', width: 15, key: 'hospital' },
      { name: 'Disease', width: 15, key: 'disease' },
      { name: 'Dyspnea', width: 10, key: 'tired' },
      { name: 'Cough', width: 10, key: 'cough' },
      {
        name: 'SpO2 at resting (%)',
        width: 22,
        key: 'o2_status_sitdown',
      },
      {
        name: 'Oxygen flow rate (LPM)',
        width: 22,
        key: 'o2_used_sit_down',
      },
      {
        name: 'SpO2 after activities (%)',
        width: 22,
        key: 'o2_status_standup',
      },
      {
        name: 'Oxygen flow rate (LPM)',
        width: 22,
        key: 'o2_used_standup',
      },
      { name: 'Other symptoms', width: 25, key: 'other' },
      { name: 'ค่า FVC (BEST (ลิตร) (%PRED (%))', width: 25, key: 'fvc_summarize' },
      { name: 'ยาที่ใช้รักษา', width: 25, key: 'medicine' },
    ];
  } else {
    header = [
      { name: 'ID', width: 10, key: 'id' },
      { name: 'Date', width: 12, key: 'date' },
      { name: 'Age', width: 5, key: 'age' },
      { name: 'Gender', width: 10, key: 'gender' },
      { name: 'Weight', width: 10, key: 'weight' },
      { name: 'Province', width: 15, key: 'province' },
      { name: 'Hospital', width: 15, key: 'hospital' },
      { name: 'Disease', width: 15, key: 'disease' },
      { name: 'Dyspnea', width: 10, key: 'tired' },
      { name: 'Cough', width: 10, key: 'cough' },
      {
        name: 'SpO2 at resting (%)',
        width: 22,
        key: 'o2_status_sitdown',
      },
      {
        name: 'Oxygen flow rate (LPM)',
        width: 22,
        key: 'o2_used_sit_down',
      },
      {
        name: 'SpO2 after activities (%)',
        width: 22,
        key: 'o2_status_standup',
      },
      {
        name: 'Oxygen flow rate (LPM)',
        width: 22,
        key: 'o2_used_standup',
      },
      { name: 'Other symptoms', width: 25, key: 'other' },
      { name: 'ค่า FVC (BEST (ลิตร) (%PRED (%))', width: 25, key: 'fvc_summarize' },
      { name: 'ยาที่ใช้รักษา', width: 25, key: 'medicine' },
    ];
  }

  return header;
}
