export function validateThaiOnly(event, maxLength) {
  const keyCode = event.which ? event.which : event.keyCode;
  const ret =
    (keyCode >= 65 && keyCode <= 90) ||
    (keyCode >= 97 && keyCode <= 122) ||
    (keyCode >= 48 && keyCode <= 57) ||
    keyCode === 46;

  if (ret || event?.target?.value?.length >= maxLength) {
    event.preventDefault();
  }
}

const handleValidateStringOnly = (event, maxLength) => {
  const keyCode = event.which ? event.which : event.keyCode;
  const ret = (keyCode >= 48 && keyCode <= 57) || keyCode === 46;

  if (ret || event?.target?.value?.length >= maxLength) {
    event.preventDefault();
  }
};

export function validateTextField({ event, type, isThaiOnly, maxLength }) {
  if (type === 'text' && isThaiOnly) {
    validateThaiOnly(event, maxLength);
  } else if (type === 'text') {
    handleValidateStringOnly(event, maxLength);
  } else if (type === 'number') {
    handleValidateNumberOnly(event, maxLength);
  }
}

export function handleValidateNumberOnly(event, maxLength) {
  const keyCode = event.which ? event.which : event.keyCode;
  const ret = (keyCode >= 48 && keyCode <= 57) || keyCode === 46;
  if (!ret || event?.target?.value?.length >= maxLength) {
    event.preventDefault();
  }
}
