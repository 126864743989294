import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import Input from '../Form/Input';
import { useCMSContext } from '../../contexts';

export default function LoginContainer() {
  const router = useHistory();
  const { CMSAction } = useCMSContext();
  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const [errorMessages, isErrorMessages] = useState({});

  const username = watch('username') || '';
  const password = watch('password') || '';

  useEffect(() => {
    if (username === '' || password === '') {
      isErrorMessages({});
    }
  }, [username, password]);

  const handleSubmitToLogin = async data => {
    const response = await CMSAction.handleLogin(data);
    let errorsList = {};
    if (response?.status === 'success') {
      isErrorMessages({});
      Cookies.set('accountId', response?.data?.cmsId);
      Cookies.set('role', response?.data?.role);
      router.push('/cms');
    } else {
      if (response?.message === 'username invalid') {
        errorsList = {
          ...errorsList,
          username: 'ชื่อผู้ใช้งานไม่ถูกต้อง',
        };
      } else if (response?.message === 'password invalid') {
        errorsList = {
          ...errorsList,
          password: 'รหัสผ่านไม่ถูกต้อง',
        };
      }

      isErrorMessages(errorsList);
    }
  };

  return (
    <BoxContainer>
      <TextFieldTitle>
        รายงานสรุปบันทึกติดตามอาการตนเองของผู้ป่วยโรคพังผืดในปอด
      </TextFieldTitle>
      <TextFieldDescription>
        โครงการ Line Official Account O2Lung (ปอดโปร่ง)
      </TextFieldDescription>
      <BoxLoginContainer>
        <FormWrapper onSubmit={handleSubmit(handleSubmitToLogin)}>
          <TextFieldLoginTitle>ลงชื่อเข้าใช้</TextFieldLoginTitle>
          <Input
            classInputContainer={`input-username ${
              !isEmpty(errorMessages?.username) ? 'isError' : ''
            }`}
            name="username"
            label="ชื่อผู้ใช้"
            register={register}
            setValue={setValue}
            defaultValue={username || ''}
            required
            maxLength={70}
            errorMessage={errorMessages?.username || ''}
          />
          <Input
            classInputContainer={`input-password ${
              !isEmpty(errorMessages?.password) ? 'isError' : ''
            }`}
            type="password"
            name="password"
            label="รหัสผ่าน"
            register={register}
            setValue={setValue}
            defaultValue={password || ''}
            required
            maxLength={70}
            errorMessage={errorMessages?.password || ''}
          />
          <ButtonLogin disabled={username === '' || password === ''}>
            ลงชื่อเข้าใช้
          </ButtonLogin>
        </FormWrapper>
      </BoxLoginContainer>
    </BoxContainer>
  );
}

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TextFieldTitle = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #06a3b4;
`;
const TextFieldDescription = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #39a1ac;
  margin-bottom: 32px;
`;
const BoxLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  width: 394px;
  height: 338px;
  background: #ffffff;
  box-shadow: 0px 4px 20px -1px rgba(113, 131, 178, 0.1);
  border-radius: 20px;
`;
const TextFieldLoginTitle = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: #06a3b4;
  margin-bottom: 30px;
`;
const FormWrapper = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;

  .input-username {
    margin-bottom: 36px;

    &.isError {
      margin-bottom: 12px;
    }
  }

  .input-password {
    margin-bottom: 34px;

    &.isError {
      margin-bottom: 10px;
    }
  }
`;
const ButtonLogin = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 314px;
  height: 38px;
  background: #06a3b4;
  opacity: ${props => (props?.disabled ? 0.5 : 1)};
  border-radius: 24px;
  border: none;

  // NOTE: Text button
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
`;
