import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localeTH from 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(buddhistEra);

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 20,
  },

  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '260px',
    maxWidth: '375px',
    backgroundColor: '#ffffff',
    borderRadius: '16px',
    padding: '0',
  },
};

export default function ModalAppointmentInfo({
  isOpen,
  tab,
  data,
  closeModal,
  handleClick,
  handleClosePopup,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <Container>
        <IconCancel
          src="/icons/icon-cancel.svg"
          alt="icon close"
          onClick={() => handleClosePopup()}
        />
        <BoxDescription>
          <RowDescription>
            <TextFieldTitle>วันที่</TextFieldTitle>
            <TextFieldValue>
              {dayjs(data?.appointmentDate)
                .tz('Asia/Bangkok')
                .locale(localeTH)
                .format('dd, DD MMM BBBB')
                .replace('.', '')}
            </TextFieldValue>
          </RowDescription>
          <RowDescription>
            <TextFieldTitle>เวลา</TextFieldTitle>
            <TextFieldValue>
              {dayjs(data?.appointmentDate)
                .tz('Asia/Bangkok')
                .locale(localeTH)
                .format('HH:mm')}
            </TextFieldValue>
          </RowDescription>
          <RowDescription>
            <TextFieldTitle>หมายเหตุ</TextFieldTitle>
            <TextFieldValue>{data?.appointmentRemark || '-'}</TextFieldValue>
          </RowDescription>
        </BoxDescription>
        {tab === 1 && (
          <ButtonDelete onClick={() => handleClick(data?.appointmentId)}>
            <IconDelete src="/icons/icon-trash.svg" alt="icon delete" />
            <span>ลบนัดหมาย</span>
          </ButtonDelete>
        )}
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #ffffff;
  position: relative;
  border: none;
  box-sizing: border-box;
  padding: 16px;
`;
const BoxDescription = styled.div`
  margin-top: 16px;
  min-width: 295px;

  @media only screen and (min-height: 320px) {
    min-width: 250px;
  }
`;
const RowDescription = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 8px;
`;
const TextFieldTitle = styled.span`
  flex: 70px 0;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #06a3b4;
  margin-right: 4px;
`;
const TextFieldValue = styled.span`
  flex: 1;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #2b2b2b;
  /* min-height: 223px; */
`;
const IconCancel = styled.img`
  width: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
`;
const ButtonDelete = styled.button`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  margin-top: 6px;
  position: absolute;
  bottom: 16px;

  span {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #f65b5b;
  }
`;
const IconDelete = styled.img`
  width: 24px;
  margin-right: 10px;
`;
