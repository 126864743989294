import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import NotFound from '../NotFound';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const ImageKnowledgeContent = styled.img`
  width: 100%;
`;
const TitleKnowledge = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #06a3b4;
  padding: 28px 24px;
`;

function Knowledge() {
  const { params } = useParams();
  let knowledgeTitle = '';
  let knowledgeContent = '';

  if (params === 'about-ild') {
    knowledgeTitle = 'โรคพังผืดในปอด';
    knowledgeContent = '/images/knowledge/about-ild.png';
  } else if (params === 'treatment-ild') {
    knowledgeTitle = 'การรักษาโรคพังผืดในปอด';
    knowledgeContent = '/images/knowledge/treatment-ild.png';
  } else if (params === 'managing-diarrhea-from-medications') {
    knowledgeTitle = 'การจัดการอาการท้องเสียจากยา';
    knowledgeContent = '/images/knowledge/managing-diarrhea-from-medications.png';
  } else if (params === 'management-of-nausea-and-vomiting-from-medication') {
    knowledgeTitle = 'การจัดการอาการคลื่นไส้อาเจียนจากยา';
    knowledgeContent = '/images/knowledge/management-of-nausea-and-vomiting-from-medication.png';
  } else if (params === 'side-effects-management') {
    knowledgeTitle = 'การจัดการอาการข้างเคียง เมื่อระดับเอนไซม์ตับสูงจากยา';
    knowledgeContent = '/images/knowledge/side-effects-management.png';
  } else {
    return <NotFound />;
  }
  return (
    <Container>
      <TitleKnowledge>{knowledgeTitle}</TitleKnowledge>
      <ImageKnowledgeContent src={knowledgeContent} />
    </Container>
  );
}

export default Knowledge;
