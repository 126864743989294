import React, { createContext, useContext, useEffect, useState } from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import concat from 'lodash/concat';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';

export const MedicalInfoContext = createContext();

export function useMedicalInfoContext() {
  return useContext(MedicalInfoContext);
}

export function MedicalInfoProvider({ children }) {
  const [hospitals, setHospitals] = useState([]);
  const [hospitalNameOptions, setHospitalNameOptions] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [ildOptions, setIldOptions] = useState([]);

  useEffect(() => {
    async function fetchDiseases() {
      let diseases = await (
        await fetch(`${process.env.REACT_APP_HOST}/diseases`)
      ).json();
      if (diseases) {
        diseases = orderBy(diseases, 'diseaseSort', 'asc');
        diseases = diseases?.map(value => {
          return value?.diseaseName;
        });

        setIldOptions(diseases);
      }
    }
    async function fetchHospital() {
      let hospital = await (
        await fetch(`${process.env.REACT_APP_HOST}/hospitals`)
      ).json();

      if (hospital) {
        hospital = orderBy(hospital, 'hospitalName', 'asc');
        let province = map(hospital, data => {
          return data?.province;
        });
        province = sortBy(uniqWith(province, isEqual));

        setHospitals(hospital);
        setProvinceList(province);
      }
    }

    fetchDiseases();
    fetchHospital();
  }, []);

  async function fetchHospitalOptions({ suggestionProvince }) {
    let hospitalOptions = await filter(hospitals, data => {
      return data?.province === suggestionProvince;
    });

    hospitalOptions = map(hospitalOptions, val => {
      return val?.hospitalName;
    });

    const hospitalName = [];
    const hospitalOther = [];

    for (let i = 0; i < hospitalOptions?.length; i++) {
      const data = hospitalOptions[i];
      if (data === 'อื่นๆ') {
        hospitalOther.push(data);
      } else {
        hospitalName.push(data);
      }
    }
    hospitalOptions = concat(hospitalName, hospitalOther);
    setHospitalNameOptions(hospitalOptions);
  }

  const values = {
    hospitals,
    hospitalNameOptions,
    provinceList,
    ildOptions,
    MedicalInfoAction: {
      fetchHospitalOptions,
    },
  };

  return (
    <MedicalInfoContext.Provider value={values}>
      {children}
    </MedicalInfoContext.Provider>
  );
}

export default MedicalInfoProvider;
