import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppointmentContext } from '../../../contexts';
import NoListAppointmentForthComing from '../components/NoListAppointmentForthComing';
import AppointmentItem from './AppointmentItem';
import Loader from '../../Animation/Loader';
import ModalAppointmentInfo from './ModalAppointmentInfo';
import ModalAppointmentConfirmDelete from './ModalAppointmentConfirmDelete';

export default function ComingAppointment({ tab }) {
  const {
    isLoading,
    comingAppointments,
    currentComingPage,
    AppointmentAction,
  } = useAppointmentContext();
  const router = useHistory();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(currentComingPage || 0);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [deleteAppointmentId, setDeleteAppointmentId] = useState('');
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [appointmentInfo, setAppointmentInfo] = useState({});

  useEffect(() => {
    if (comingAppointments?.length <= 0) {
      AppointmentAction.fetchAppointment('coming', page);
    }
  }, []);

  const fetchMoreData = () => {
    setTimeout(() => {
      if (comingAppointments.length >= page) {
        AppointmentAction.fetchAppointment('coming', page);

        if (!isLoading) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
        return;
      }
    }, 1000);
  };

  useEffect(() => {
    if (comingAppointments?.length > page) {
      setPage(page + 10);
    }
  }, [comingAppointments]);

  useEffect(() => {
    AppointmentAction.setCurrentComingPage(page);
  }, [page]);

  const handleClickOpenViewInfo = data => {
    setAppointmentInfo(data);
    setIsOpenInfo(true);
  };

  const handleConfirmDelete = appointmentId => {
    setIsConfirmDelete(true);
    setDeleteAppointmentId(appointmentId);
  };

  const handleDeleteAppointment = async appointmentId => {
    const res = await AppointmentAction.deleteAppointment(appointmentId);

    if (res?.status === 'success') {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <Container>
      {comingAppointments?.length > 0 ? (
        <>
          <AppointmentList id="scrollableDiv">
            <InfiniteScroll
              dataLength={comingAppointments.length}
              next={page => fetchMoreData({ page })}
              hasMore={hasMore}
              loader={<Loader />}
              scrollableTarget="scrollableDiv"
            >
              {comingAppointments.map((val, key) => (
                <AppointmentItem
                  key={`appointment-${key}`}
                  data={val}
                  handleClickView={handleClickOpenViewInfo}
                />
              ))}
            </InfiniteScroll>
          </AppointmentList>
          <BoxFooter>
            <ButtonToCreate onClick={() => router.push('/appointment/create')}>
              <IconCreate src="/icons/icons-plus-white.svg" />
              <span>เพิ่มตารางนัดหมาย</span>
            </ButtonToCreate>
          </BoxFooter>
        </>
      ) : comingAppointments?.length === 0 && isLoading ? (
        <Loader />
      ) : (
        <NoListAppointmentForthComing tab={tab} />
      )}
      <ModalAppointmentInfo
        isOpen={isOpenInfo}
        tab={tab}
        data={appointmentInfo}
        handleClick={handleConfirmDelete}
        handleClosePopup={() => setIsOpenInfo(false)}
      />
      <ModalAppointmentConfirmDelete
        isOpen={isConfirmDelete}
        deleteAppointmentId={deleteAppointmentId}
        handleClick={handleDeleteAppointment}
        handleClosePopup={setIsConfirmDelete}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-top: 24px;
`;
const AppointmentList = styled.div`
  overflow: scroll;

  @media only screen and (min-height: 568px) {
    max-height: 320px;
  }
  @media only screen and (min-height: 640px) {
    max-height: 391px;
  }
  @media only screen and (min-height: 667px) {
    max-height: 415px;
  }
  @media only screen and (min-height: 713px) {
    max-height: 486px;
  }
  @media only screen and (min-height: 731px) {
    max-height: 480px;
  }
  @media only screen and (min-height: 736px) {
    max-height: 486px;
  }
  @media only screen and (min-height: 812px) {
    max-height: 560px;
  }
  @media only screen and (min-height: 823px) {
    max-height: 575px;
  }
  @media only screen and (min-height: 1024px) {
    max-height: 770px;
  }
  @media only screen and (min-height: 1366px) {
    max-height: 1115px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .infinite-scroll-component {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const BoxFooter = styled.div`
  display: flex;
  width: 100%;
  height: 88px;
  justify-content: center;
  position: absolute;
  bottom: 88px;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0px 4px 20px -1px rgba(113, 131, 178, 0.1);
`;
const ButtonToCreate = styled.button`
  width: 197px;
  height: 40px;
  border: none;
  background: #06a3b4;
  border-radius: 24px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.25px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IconCreate = styled.img`
  width: 24px;
  margin-right: 10px;
`;
