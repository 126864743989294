import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localeTH from 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import styled from 'styled-components';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(buddhistEra);

export default function AppointmentItem({ data, handleClickView }) {
  return (
    <Item onClick={() => handleClickView(data)}>
      <IconInfo src="/icons/icon-information.svg" />
      <BoxDatetime>
        <RowDatetime>
          <TextFieldTitle>วันที่</TextFieldTitle>
          <TextFieldValue>
            {dayjs(data?.appointmentDate)
              .tz('Asia/Bangkok')
              .locale(localeTH)
              .format('dd, DD MMM BBBB')
              .replace('.', '')}
          </TextFieldValue>
        </RowDatetime>
        <RowDatetime>
          <TextFieldTitle>เวลา</TextFieldTitle>
          <TextFieldValue>
            {dayjs(data?.appointmentDate)
              .tz('Asia/Bangkok')
              .locale(localeTH)
              .format('HH:mm')}
          </TextFieldValue>
        </RowDatetime>
      </BoxDatetime>
      <BoxRemark>
        <TextFieldTitle>หมายเหตุ</TextFieldTitle>
        <TextFieldValue>{data?.appointmentRemark || '-'}</TextFieldValue>
      </BoxRemark>
    </Item>
  );
}

const Item = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: #f8fbfc;
  border: 1px solid #e7f6f8;
  position: relative;
  padding: 16px;
  margin-bottom: 8px;
`;
const IconInfo = styled.img`
  width: 24px;
  position: absolute;
  top: 12px;
  right: 12px;
`;
const BoxDatetime = styled.div`
  display: flex;
`;
const RowDatetime = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const TextFieldTitle = styled.span`
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #06a3b4;
  margin-bottom: 4px;
`;
const TextFieldValue = styled.span`
  font-size: 16px;
  letter-spacing: 0.25px;
  color: #2b2b2b;
  max-height: 72px;
  overflow: hidden;
`;
const BoxRemark = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;
