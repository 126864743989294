import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { CookiesProvider } from 'react-cookie';
import DashboardContainer from '../../components/Dashboard/DashboardContainer';
import {
  ProfileProvider,
  DashboardProvider,
  AppointmentProvider,
} from '../../contexts';

const Container = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 31px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  height: 88px;
  padding: 28px 16px;
`;
const BoxTitle = styled.div`
  display: flex;
  align-items: center;
`;
const ImageArrowBack = styled.img`
  width: 32px;
  margin-right: 8px;
`;
const Title = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #06a3b4;
`;

class dashboard extends PureComponent {
  render() {
    return (
      <Container>
        <Header>
          <BoxTitle onClick={() => this.props.history.push('/profile')}>
            <ImageArrowBack src="/icons/arrow-left-b.svg" />
            <Title>สรุปบันทึกอาการของฉัน</Title>
          </BoxTitle>
        </Header>
        <ProfileProvider>
          <CookiesProvider>
            <AppointmentProvider>
              <DashboardProvider>
                <DashboardContainer />
              </DashboardProvider>
            </AppointmentProvider>
          </CookiesProvider>
        </ProfileProvider>
      </Container>
    );
  }
}

export default withRouter(dashboard);
